@import 'css/variables/global.scss';


.form .form-style {

  &.async-input {

    // override other inputs' red border shading on error, because this field can show warnings AND errors
    &.warn {
      border: $border--blue-light;

      &:hover {
        border: $border--blue-light !important;
      }
    }
  }
}
