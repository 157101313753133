@import 'css/base/color.scss';
@import 'css/base/spacing.scss';
@import 'css/base/table.scss';
@import 'css/base/transition.scss';
@import 'css/base/typography.scss';
@import 'css/mixins/flexbox.scss';
@import 'css/mixins/includeMedia.scss';


$toolbar-height: 58px;

// applied when hovering over an action button or a menu option action
@mixin actionCountHoverStyles() {
  .action-count {
    background-color: $blue--x-light;
    color: $blue--bold;
  }
}

.table-toolbar--wrapper {
  animation: toolbarIn 60ms;

  @include media("<tablet") {
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    z-index: 1;
  }
}

.table-toolbar {
  @include flexbox();
  @include justify-content(space-between);

  padding: $spacing--12;
  border-left: $table-border--outer;
  border-right: $table-border--outer;

  // only child (storybook)
  &:first-child:last-child {
    border: $table-border--outer;

    @include media("<tablet") {
      border: none;
    }
  }

  .table-toolbar--content {
    @include flexbox();

    &:last-child {
      // right-aligns children in the last content block
      @include justify-content(flex-end);
    }

    &:first-child {
      margin-left: $spacing--12 !important;
    }

    .action-count {
      background-color: $grey--xx-light;
      font-size: $font--10;
      margin-left: $spacing--4;
      transition: all $transition-timing--m;
    }

    .table-toolbar--primary-action {
      margin-right: $spacing--12;
    }

    .btn-v2 {
      white-space: nowrap;

      &.table-toolbar--primary-action {
        padding: $spacing--6 $spacing--12;
      }

      &:hover, &:focus {
        @include actionCountHoverStyles();
      }
    }

    .dd-menu {

      .dd-menu-items {
        background-color: $white;
      }

      .table-toolbar--dd-option {

        a {
          @include flexbox();
          line-height: 18px;
        }

        .link--title {
          @include flex-col-center();
          transition: all $transition-timing--m;
        }

        &:hover, &:focus, a:focus {
          @include actionCountHoverStyles();
        }
      }
    }

    .restricted-permission--tooltip {
      .btn-v2 {
        height: $spacing--32;
      }
    }

    .selection-manager {
      background-color: $blue--x-light;
      border-radius: 100px;
      border: none;
      color: $blue--bold;
      margin-top: $spacing--2;
      transition-duration: $transition-timing--m;

      span, svg {
        transition-duration: $transition-timing--m;
      }

      &:focus, &:hover {
        background-color: $blue--dark;
        color: $white;

        svg {
          fill: $white !important;
        }
      }

      & + .dd-menu-items {
        min-width: 100%;
        position: absolute;

        a {
          white-space: nowrap;
        }

        svg {
          vertical-align: text-bottom;
        }
      }
    }
  }

  @include media("<tablet") {
    @include align-items(center);
    @include flex-direction(column);
    @include justify-content(center);

    border: none;
    box-shadow: 0 4px 16px rgba(60, 101, 241, 0.15);
    padding: $spacing--16;

    .table-toolbar--content {
      width: 100%;

      &:last-child {
        @include justify-content(center);
      }

      .selection-manager-wrapper {
        width: 100%;
      }

      .btn-v2 {
        @include justify-content(center);

        width: 100%;
      }

      .dd-menu {
        margin-left: 0;
        width: 100%;
      }

      &:last-child {

        .dd-menu {
          margin-top: $spacing--12;
        }
      }
    }
  }
}

@keyframes toolbarIn {
  0% {
    height: 0;
  }
  100% {
    height: $toolbar-height;
  }
}
