@import 'css/base/color.scss';
@import 'css/base/margin.scss';
@import 'css/base/padding.scss';
@import 'css/base/spacing.scss';
@import 'css/mixins/flexbox.scss';
@import 'css/mixins/includeMedia.scss';


$branding-border-width: $spacing--32;
$external--content-tablet-large-max-width: 1030px;
$external--content-tablet-max-width: 768px - $branding-border-width;
$stepper-fader--width: 80px;

.external-flow-wrapper {
  @include flexbox();
  @include flex-direction(column);

  // We want the wrapper to be at least 100% of the available viewport height, even if
  // the content itself does not need as much space.
  min-height: 100vh;

  // Border color comes from brand property and is set directly on the
  // element. Here, we just set border style & width which are constant.
  border-left-style: solid;
  border-left-width: $branding-border-width;

  @include media($mobileOnly) {
    border-left-width: $spacing--16;
  }

  &__progress-bar {
    border-bottom: 1px solid $grey--x-light;

    .stepper--side {
      // We are setting position of back arrow to absolute here so we can
      // position .stepper--main container in the same way as we do the content
      // and align them identically
      position: absolute;
    }

    .stepper--main {
      // See https://www.figma.com/file/rmBffM7pjvZeji8yl5od6z/External%3A-Components?node-id=529%3A8531 for
      // external wrapper layout definitions.
      width: 100%;

      margin: auto;
      // We need to subtract 4px to accommodate for button padding inside of the
      // .step element to optically align the first step with the start of the content
      padding: 0 $padding--larger - $spacing--4;

      @include media(">=tablet") {
        max-width: $external--content-tablet-max-width;
      }

      @include media(">=tablet_large") {
        max-width: $external--content-tablet-large-max-width;
      }

      @include media($mobileOnly) {
       padding-left: $padding--xm;
       // We need extra space on the right side to escape the fader overlay
       padding-right: $stepper-fader--width;
      }
    }
  }

  &__content {
    // We want the content to occupy all of the available screen space and since the parent
    // element has display: flex; the easiest way to achieve this is to set property flex to
    // auto.
    @include flex(auto);

    // We want the content to be positioned to the center. Child components dictate the sizing of each
    // column, here we just want to ensure that the wrapper positions it's content to the center
    @include align-self(center);

    // The wrapper will support two types of vertical alignment: auto and centered. We want to use
    // flexbox for centering items vertically. We need items to stack vertically and we ensure that
    // with providing flex-direction: column; property.
    @include flexbox();
    @include flex-direction(column);

    // See https://www.figma.com/file/rmBffM7pjvZeji8yl5od6z/External%3A-Components?node-id=529%3A8531 for
    // external wrapper layout definitions.
    // Expand content as wide a possible
    width: 100%;
    padding: 0 $padding--m-large;

    @include media(">=tablet") {
      max-width: $external--content-tablet-max-width;
    }

    @include media(">=tablet_large") {
      max-width: $external--content-tablet-large-max-width;
    }

    @include media($mobileOnly) {
      padding: 0;
    }

    &--centered {
      // Since we are using flex-direction: column, in order to align items on the
      // vertical axis, we need to use property justify-content (and not the align-items)
      // like we would in "normal" row direction.
      @include justify-content(center);

      @include media($mobileOnly) {
        @include justify-content(flex-start);
      }
    }
  }
}
