@import 'css/base/color.scss';
@import 'css/base/typography.scss';

.password-hint-container {
  display: flex;
  flex-direction: column;

  .individual-hint,
  .password-icon-span-container {
    display: flex;
    flex-direction: row;
    gap: 0.25rem;
    margin-top: 0.5rem;

    .hint-icon-container,
    .password-strength-icon {
      width: 1rem;
      text-align: center;
    }

    &:first-of-type {
      padding-top: 0;
    }
  }

  .password-strength {
    &.weak {
      &.highlighted {
        color: $red--bold;
      }

      &.muted {
        color: $grey--x-dark;
      }
    }

    &.strong, &.good {
      color: $green--bold;
    }
  }

  .hint-requirement {
    &.valid {
      color: $green--bold;
    }

    &.invalid {
      &.highlighted {
        color: $red--bold;
      }

      &.muted {
        color: $grey--x-dark;
      }
    }
  }
}
