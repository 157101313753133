@import 'css/base/color.scss';
@import 'css/base/padding.scss';
@import 'css/base/typography.scss';
@import 'css/mixins/boxShadow.scss';
@import 'css/mixins/flexbox.scss';
@import 'css/mixins/includeMedia.scss';
@import 'css/variables/buttons.scss';
@import 'css/variables/global.scss';


$border-radius: 4px;

$medium-height: 40px;
$large-height: 56px;

.btn-v2 {
  /* GENERAL STYLES */
  border-radius: $border-radius;
  box-sizing: border-box;
  color: $white;
  cursor: pointer;
  font-weight: $bold;
  font-family: $base-font-family;
  overflow: hidden;
  position: relative;
  transition: all 0.6s;
  @include flexbox();
  @include align-items(center);

  svg, span {
    transition: all 0.6s;
  }

  /* INTENT */
  &.btn--danger {
    background-color: $red--bold;

    &:hover,
    &:focus,
    &.focused {
      background-color: $red--light;
      color: $red--bold;

      svg {
        fill: $red--bold !important;
      }
    }
  }

  &.btn--strong {
    background-color: $blue--dark;

    &:hover,
    &:focus,
    &.focused {
      background-color: $blue--bold;
    }

    .fill-bar {
      background-color: $blue--bold;
    }
  }

  &.btn--external {
    background-color: $black;

    &:hover,
    &:focus,
    &.focused {
      background-color: $grey--xx-dark;
    }

    .fill-bar {
      background-color: black;
      opacity: 0.4;
    }
  }

  &.btn--ghost {
    background-color: $transparent;
    border: none;
    color: $grey--x-dark;

    &:hover,
    &:focus,
    &.focused {
      color: $black;

      svg {
        fill: $black !important;
      }
    }
  }

  &.btn--neutral {
    background-color: $white;
    border: 1px solid var(--color-grey20);
    color: $grey--xx-dark;

    &:hover,
    &:focus,
    &.focused {
      background-color: $white;
      border-color: $blue--bold;
      color: $blue--bold;

      svg {
        fill: $blue--bold !important;
      }
    }

    .fill-bar {
      background-color: var(--color-grey20);
    }

    .lds-spinner div:after {
      background: $blue--bold;
    }
  }

  &.btn--primary {
    background-color: $blue--bold;

    &:hover,
    &:focus,
    &.focused {
      background-color: $blue--dark;
    }
  }

  &.btn--light {
    background-color: $blue--bold;
    color: $white;

    &:hover,
    &:focus {
      background-color: $white;
      color: $blue--bold;
      svg {
        fill: $blue--bold !important;
      }
    }
    .lds-spinner div:after {
      background: $blue--bold;
    }
  }

  &.btn--success {
    background-color: $aqua--bold;

    &:hover,
    &:focus,
    &.focused {
      background-color: $blue--dark;
    }
  }

  &.btn--danger, &.btn--primary, &.btn--success {
    .fill-bar {
      background-color: $blue--dark;
    }
  }

  /* APPEARANCE */
  &.btn--borderless {
    border: none;
    background-color: transparent;
    color: $grey--xx-dark;

    &:hover,
    &:focus,
    &.focused {
      background-color: $blue--x-light;
      color: $blue--bold !important;

      svg, span {
        color: $blue--bold !important;
        fill: $blue--bold !important;
      }
    }

    &.inverted {
      background-color: $blue--x-light;
      border-radius: $spacing--8;
      color: $blue--bold;

      &:hover,
      &:focus,
      &.focused {
        background-color: $blue--bold;
        color: $white;

        svg {
          fill: $white !important;
        }
      }
    }
  }

  /* HOVER STYLES */
  &.hover--danger {
    &:hover,
    &:focus,
    &.focused {
      border-color: $red--bold;
      color: $red--bold !important;

      svg, span {
        color: $red--bold !important;
        fill: $red--bold !important;
      }
    }
  }

  &.hover--primary {
    &:hover,
    &:focus,
    &.focused {
      border-color: $blue--bold;
      color: $blue--bold;

      svg, span {
        color: $blue--bold !important;
        fill: $blue--bold !important;
      }
    }
  }

  &.hover--dark {
    &:hover,
    &:focus,
    &.focused {
      &.btn--borderless {
        background-color: unset;
      }

      color: darken($blue--bold, 20%);

      svg, span {
        color: darken($blue--bold, 20%) !important;
        fill: darken($blue--bold, 20%) !important;
      }
    }
  }

  /* SIZE */
  &.btn--small {
    line-height: $line-height--sm;
    font-size: $font-xs;
    font-weight: $bold;
    padding: $padding--xm $padding--m;

    &.btn--icon {
      padding: $padding--xm;
    }
  }

  &.btn--medium {
    line-height: $line-height--m;
    font-weight: $bold;
    font-size: $font-sm;
    padding: 0 $padding--m-large;
    height: $medium-height;

    &.btn--icon {
      padding: $padding--m;
    }
  }

  &.btn--large {
    width: 100%;
    line-height: $line-height--l;
    font-weight: $bold;
    font-size: $font-sm;
    padding: 0 $padding--large;
    height: $large-height;

    &.btn--icon {
      padding: $padding--m-large;
    }
  }

  &.btn--auto {
    width: auto;
  }

  /* OVERLAY FOR LOADING STATE */
  .btn--overlay {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: inherit;

    transition: all 0.01s;
    transition-delay: 0.3s;
    opacity: 0;

    &.is-loading {
      opacity: 1;
      transition-delay: 0s;
      border: none;
    }
  }

  /* LOADING */
  &.btn--loading {
    pointer-events: none;
    transition: border 0.1s;

    &.btn--neutral {
      border: 1px solid $blue--bold;
      color: $blue--bold;
    }

    &.btn--borderless {
      background-color: var(--color-grey20);
    }
  }

  // DISABLED
  &:disabled {
    &:not(.btn--loading) {
      background-color: var(--color-grey20);
      color: $grey--x-dark;
      cursor: default;
      opacity: 0.5;
      pointer-events: none;
      transition: none;

      svg {
        fill: $grey--x-dark !important;
        transition: none;
      }

      &.btn--ghost {
        background-color: $transparent;
      }

      &.btn--external {
        background-color: $grey--x-light;
      }

      &.btn--danger {
        svg {
          fill: $grey--x-dark !important;
        }
      }

      &.btn--neutral {
        background-color: $white;
      }

      &.btn--borderless {
        background-color: transparent;
      }
    }
  }
}

.link-button {
  background-color: transparent;
  border: none;
  color: $blue--bold;
  cursor: pointer;
  text-decoration: underline;
  display: inline;
  margin: 0;
  padding: 0;
}
