@import 'css/base/color.scss';
@import 'css/base/spacing.scss';
@import 'css/mixins/flexbox.scss';
@import 'css/mixins/includeMedia.scss';
@import 'css/mixins/zIndex.scss';


.dropdown-menu-btn {
  // override default values
  position: relative;
  display: inline-block !important;

  &.dd-menu-right {
    .dd-menu-items {
      position: absolute;
      right: 0;
      left: -50%;

      @include media("<=tablet") {
        left: 0;
      }
    }

    .btn--icon {
      + .dd-menu-items {
        left: -150px;
      }
    }
  }

  .dd-menu-items {
    // override default values
    margin-top: $spacing--4 !important;
    width: auto !important;
    @include zIndex("MODAL", "DROPDOWN");

    > ul {
      .dropdown-menu-option {
        // override default values
        border-bottom: none;

        > a {
          @include flexbox();

          padding: $spacing--6 $spacing--12;

          p {
            color: $grey--xx-dark;
          }

          &.disabled {
            pointer-events: none;

            > p {
              color: $grey--x-dark;
              opacity: 0.5;
            }
          }

          &:focus:not(.disabled) {
            > p {
              color: $blue--bold;
            }

            > svg {
              fill: $blue--bold !important;
            }
          }
        }

        &:first-child {

          a {
            padding-top: $spacing--12;
          }
        }

        &:last-child {

          a {
            padding-bottom: $spacing--12;
          }
        }

        &:hover,
        &:focus {
          // override default values
          background: none !important;

          > a:not(.disabled) {
            > p {
              color: $blue--bold;
            }

            > svg {
              fill: $blue--bold !important;
            }
          }
        }
      }
    }
  }
}


