@import '../../global/css/variables/global.scss';
@import '../../global/css/base/color.scss';
@import '../../global/css/base/margin.scss';
@import '../../global/css/base/padding.scss';
@import '../../global/css/base/typography.scss';
@import '../../global/css/mixins/includeMedia.scss';


/* Global box variables*/
$box--border-radius: 10px;
$box--width: 520px;
$box--padding: 24px 16px;
$box--footer-padding: 16px;

/* Box footer variables */
$box-footer--height: 48px;

/* Box step variables */
$box-step--height: 4px;
$box-step--width: 94%;

.box--container {
  margin: 0;
  max-width: $box--width;
  // adding padding-bottom to give the page room to scroll when the progress bar is present and fixed to the bottom
  padding-bottom: $external-progress-bar--height;
  width: 100%;

  @include media(">=phone_large") {
    margin: 0 $margin--large;
  }
}

.box--header {
  margin: $margin--large;
  color: $white;
  font-family: $marketing-font-family;
  font-size: 28px;
  font-weight: $regular;
  line-height: 32px;

  @include media(">=phone_large") {
    margin: $margin--large 0;
  }

  @include media(">=tablet") {
    line-height: normal;
    margin: 0 0 $margin--large 0;
  }
}

.box {
  background: $blue--x-light;
  border-radius: $box--border-radius;
  box-shadow: 0 4px 4px 0 rgba(77,92,113,0.16);
}

.box--body {
  padding: $box--padding;
}

.box--footer {
  height: $box-footer--height;
  display: table;
  width: 100%;
  padding: $box--footer-padding;

  border-top: $border--blue-light;
  background-color: var(--color-grey20);
  border-bottom-left-radius: $box--border-radius;
  border-bottom-right-radius: $box--border-radius;
}

.box--step {
  height: $box-step--height;
  width: $box-step--width;
  margin: auto;
  border-radius: 0 0 3px 3px;
  background-color: $blue--bold;
}
