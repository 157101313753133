@import '../base/margin.scss';
@import '../base/padding.scss';
@import '../variables/global.scss';


// global margin
.remove-margin {
  margin: 0 !important;
}

.margin-auto {
  margin: auto;
}

.margin--xs {
  margin: $margin--xs;
}

.margin--sm {
  margin: $margin--sm;
}

.margin--xm {
  margin: $margin--xm;
}

.margin--m {
  margin: $margin--m;
}

.margin--m-large {
  margin: $margin--m-large;
}

.margin--large {
  margin: $margin--large;
}

.margin--larger {
  margin: $margin--larger;
}

.margin--x-large {
  margin: $margin--x-large;
}

// margin-top
.remove-margin-top {
  margin-top: 0 !important;
}

.margin-top--xs {
  margin-top: $margin--xs;
}

.margin-top--sm-alt {
  margin-top: $margin--sm;
}

.margin-top--sm {
  margin-top: $margin--sm;
}

.margin-top--xm {
  margin-top: $margin--xm;
}

.margin-top--m {
  margin-top: $margin--m;
}

.margin-top--m-large {
  margin-top: $margin--m-large;
}

.margin-top--large {
  margin-top: $margin--large;
}

.margin-top--larger {
  margin-top: $margin--larger;
}

.margin-top--x-large {
  margin-top: $margin--x-large;
}

.margin-top--form-margin {
  margin-top: $form-margin;
}

.margin-bottom--form-margin {
  margin-bottom: $form-margin;
}

// margin-bottom
.remove-margin-bottom {
  margin-bottom: 0 !important;
}

.margin-bottom--xs {
  margin-bottom: $margin--xs;
}

.margin-bottom--sm {
  margin-bottom: $margin--sm;
}

.margin-bottom--sm--important {
  margin-bottom: $margin--sm !important;
}

.margin-bottom--xm {
  margin-bottom: $margin--xm;
}

.margin-bottom--xm--important {
  margin-bottom: $margin--xm !important;
}

.margin-bottom--m {
  margin-bottom: $margin--m;

  &--important {
    margin-bottom: $margin--m !important;
  }
}

.margin-bottom--m-large {
  margin-bottom: $margin--m-large;
}

.margin-bottom--large {
  margin-bottom: $margin--large;
}

.margin-bottom--large-aligner {
  margin-bottom: $margin--large-aligner;
}

.margin-bottom--larger {
  margin-bottom: $margin--larger;
}

.margin-bottom--x-large {
  margin-bottom: $margin--x-large;
}

.margin-bottom--xxx-large--important {
  margin-bottom: $margin--xxx-large !important;
}

.margin-bottom--form-margin {
  margin-bottom: $form-margin;
}

// margin-left
.remove-margin-left {
  margin-left: 0 !important;
}

.margin-left--auto {
  margin-left: auto;
}

.margin-left--xs {
  margin-left: $margin--xs;
}

.margin-left--sm-alt {
  margin-left: $margin--sm;
}

.margin-left--sm {
  margin-left: $margin--sm;
}

.margin-left--6 {
  margin-left: $spacing--6;
}

.margin-left--8 {
  margin-left: $spacing--8;
}

.margin-left--xm {
  margin-left: $margin--xm;
}

.margin-left--xm--important {
  margin-left: $margin--xm !important;
}

.margin-left--m {
  margin-left: $margin--m;
}

.margin-left--m-large {
  margin-left: $margin--m-large;
}

.margin-left--large {
  margin-left: $margin--large;
}

.margin-left--x-large {
  margin-left: $margin--x-large;
}

.margin-left--xx-large {
  margin-left: $margin--xx-large;
}

// margin-right
.remove-margin-right {
  margin-right: 0 !important;
}

.margin-right--auto {
  margin-right: auto;
}

.margin-right--xs {
  margin-right: $margin--xs;
}

.margin-right--sm-alt {
  margin-right: $margin--sm;
}

.margin-right--sm {
  margin-right: $margin--sm;
}

.margin-right--6 {
  margin-right: $spacing--6;
}

.margin-right--xm {
  margin-right: $margin--xm;
}

.margin-right--8 {
  margin-right: $spacing--8;
}

.margin-right--m {
  margin-right: $margin--m;
}

.margin-right--m-large {
  margin-right: $margin--m-large;
}

.margin-right--large {
  margin-right: $margin--large;
}

.margin-right--larger {
  margin-right: $margin--larger;
}

.margin-right--x-large {
  margin-right: $margin--x-large;
}

// margin horizontal
.margin-horizontal--xm {
  margin-left: $margin--xm;
  margin-right: $margin--xm;
}

.margin-horizontal--x-large {
  margin-left: $margin--x-large;
  margin-right: $margin--x-large;
}

.margin-horizontal--settings {
  margin-left: $settings-section-padding;
  margin-right: $settings-section-padding;
}
