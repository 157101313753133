@import 'css/base/padding.scss';
@import 'css/mixins/flexbox.scss';
@import 'css/mixins/zIndex.scss';
@import 'css/variables/sizes.scss';

.sidepanel--with-overlay {
  &__main-view {
    &--container {
      @include flex-col-center();
      @include align-items(center);

      height: 100vh;
      padding-right: $side-panel-width-medium;
    }

    &--content {
      // Limit the max height of the content to the container
      max-height: 100%;
      width: 100%;
      overflow-y: auto;
    }
  }
}
