@import 'css/base/color.scss';
@import 'css/base/typography.scss';
@import 'css/base/spacing.scss';
@import 'css/mixins/flexbox.scss';
@import 'css/mixins/includeMedia.scss';

.tlp--page-size {
  @include align-items(center);
  gap: $spacing--8;

  .dropdown-container {
    width: $spacing--72;
  }
}
