@import 'css/base/color.scss';
@import 'css/base/typography.scss';

/**
 Expects color map as a parameter and adds
 rule for nth-child inside the div for each
 key (index) in the color map.
 */
@mixin loading-dots-colors ($ledger-colors) {
  & > div {
    @each $step, $color in $ledger-colors {
      $index: index(($ledger-colors), ($step $color));

      &:nth-child(#{$index}) {
        background-color: $color;
      }
    }
  }
}

.loading {
  display: table;
  width: 100%;
  height: 140px;

  border-radius: 10px;
  background-color: var(--color-grey05);

  &.has-shadow {
    background-color: darken($grey--05, 2%);
    box-shadow: 0px 2px 2px var(--color-grey20);
  }

  .loading--body {
    display: table-cell;
    text-align: center;
    vertical-align: middle;

    p {
      display: block;

      font-size: $font-sm;
      font-weight: $semibold;
    }
  }
}

.loading-ledger {
  &--NetSuite {
    @include loading-dots-colors($netsuite--loading);
  }

  &--QBO {
    @include loading-dots-colors($qbo--loading);
  }

  &--Xero {
    @include loading-dots-colors($xero--loading);
  }
}

// Single sign on
.loading-sso {
  &--google-oauth2 {
    @include loading-dots-colors($google-oauth2--loading);
  }
}

.branded--loading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.sk-three-bounce {
  > div {
    margin: 0;
    width: 6px;
    height: 6px;
    background-color: currentColor;
    border-radius: 100%;
    -webkit-animation: sk-bouncedelay sk-opacity-delay 1.8s infinite ease-in-out;
    animation: sk-bouncedelay sk-opacity-delay 1.8s infinite ease-in-out;
    /* Prevent first frame from flickering when animation starts */
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;

    &:first-child {
      -webkit-animation-delay: -0.32s;
      animation-delay: -0.32s;
      margin-right: 6px;
    }
    &:nth-child(2) {
      -webkit-animation-delay: -0.16s;
      animation-delay: -0.16s;
      margin-right: 6px;
    }
  }

  &.large > div {
    width: 12px;
    height: 12px;
  }

  &.grey-20 > div {
    background-color: var(--color-grey20);
  }
}

@keyframes sk-bouncedelay {
  0%, 80%, 100% {
    transform: scale(0);
  }

  30% {
    transform: scale(1);
  }
}

@keyframes sk-opacity-delay {
  0%   { opacity: 0; }
  100% { opacity: 0.8; }
}
