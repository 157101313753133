@import '../base/block.scss';
@import '../base/color.scss';
@import '../base/margin.scss';
@import '../base/padding.scss';
@import '../base/typography.scss';


.chip {
  display: inline-block;
  margin-left: $margin--sm;
  padding: $padding--sm $padding--xm;

  color: $dark-jordan;
  font-size: $font-xs;
  font-weight: $bold;

  background-color: var(--color-grey20);
  border-radius: 3px;
  border: 1px solid darken($grey--20, 2%);

  &:only-child {
    margin-left: 0;
  }

  &.note {
    color: darken($dark-yellow, 16%);

    background: lighten($light-yellow, 8%);
    border: 1px solid desaturate($light-yellow, 4%);
  }

  &.warning {
    color: desaturate($dark-orange, 12%);

    border: 1px solid lighten($light-orange, 14%);
    background: lighten($light-orange, 21%);
  }

  &.negative {
    color: desaturate($red--bold, 4%);

    border: 1px solid $red--light;
    background: $red--light;
  }

  &.positive {
    color: desaturate($main-green, 4%);

    border: 1px solid lighten($light-green, 12%);
    background: lighten($light-green, 30%);
  }

  // applied to tag place within button
  &.inline-button {
    padding: 0 $padding--m;
    margin-left: 0;
  }

  // applied to tag place within button
  &.has-primary-background {
    background-color: lighten($blue--bold, 4%);

    span {
      color: $white;
    }
  }

  span {
    margin: 0;
    line-height: 28px;
  }

  & > span[class^="icon-ic-"] {
    font-size: $font-sm;
    margin-right: $margin--sm;
  }

  &.balance-block--chip {
    border-radius: $block-border-radius--sm;

    span {
      line-height: $paragraph;
      font-weight: $semibold;
    }

    &.note {
      color: $grey--x-dark;
    }
  }
}
