@import 'css/base/color.scss';
@import 'css/base/block.scss';
@import 'css/base/padding.scss';
@import 'css/base/typography.scss';
@import 'css/mixins/flexbox.scss';
@import 'css/mixins/boxShadow.scss';


$backgroundColorsMapForIntent: (
  danger: $red--light,
  info: $blue--x-light,
  schedule: $purple--light,
  success: $green--light,
  warning: $ylw--light,
  neutral: $grey--xx-light,
);

$closeColorsMapForIntent: (
  danger: $red--bold,
  info: $blue--bold,
  schedule: $purple--bold,
  success: $green--bold,
  warning: $yellow--medium,
  neutral: $grey--dark,
);

.notification {
  @include flexbox();
  @include flex-flow(row nowrap);
  @include align-items(center);
  @include justify-content(center);

  position: relative;
  padding: $padding--m-large;
  width: 100%;

  // Icon
  @each $intent, $color in $closeColorsMapForIntent {
    &.#{$intent} {
      svg {
        fill: $color !important;
      }
    }
  }

  // Body
  .notification--body {
    @include flex-col-center();
    @include align-items(center);
    padding-right: $padding--m-large;
  }

  .notification--close-button {
    @include flex-col-center();
    @include align-items(center);

    position: absolute;
    right: $padding--m-large;
    border-radius: 50%;
    background-color: $white;
    padding: $padding--xm;
    cursor: pointer;

    &:hover {
      @each $intent, $color in $closeColorsMapForIntent {
        &.#{$intent} {
          background-color: $color;
        }

        svg {
          fill: $white !important;
        }
      }
    }

    @each $intent, $color in $closeColorsMapForIntent {
      &.#{$intent} {
        color: $color;
      }
    }
  }

  // notification colors
  @each $intent, $backgroundColor in $backgroundColorsMapForIntent {
    &.#{$intent} {
      background-color: $backgroundColor;
    }
  }

  &.remove-background-color {
    background-color: $white;
  }
}
