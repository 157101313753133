#namespace-entry-container {
  display: flex;
  flex-direction: row;
}

.vanity-container {
  display: flex;
  flex-grow: 1;
  min-height: 100%;
  align-items: center;
  margin-left: 1rem;

  &.vanity {
    margin: 0 auto;
  }
}
