@import 'css/base/block.scss';
@import 'css/base/color.scss';
@import 'css/base/margin.scss';
@import 'css/base/padding.scss';
@import 'css/base/typography.scss';
@import 'css/mixins/circleItem.scss';
@import 'css/mixins/flexbox.scss';
@import 'css/mixins/includeMedia.scss';


$balance-block-no-footer-border-radius: 11px;

.content-block {
  background-color: $blue--xx-light;
  border: $content-block-border;
  border-radius: $block-border-radius--xm;

  .content-block--header {
    padding: $content-block-padding--m $content-block-padding--m $content-block-padding--xm;
    background-color: $white;
    border-bottom: $content-block-border;
    border-radius: $block-border-radius--xm $block-border-radius--xm 0 0;

    .step-header {
      width: 100%;

      .content--left {
        @include flexbox();
        width: auto;
        margin-right: $margin--m;
        line-height: $content-block-header-instruct-circle-size;
      }

      .step-header--instruction {
        @include flexbox();
        @include justify-content(space-between);

        & > div.content--left {
          @include flexbox();
          @include justify-content(flex-start);
          @include align-self(center);
        }

        & > div.content--right {
          @include flexbox();
          @include justify-content(flex-end);
          @include align-self(center);
        }
      }

      .instruction--container {

        &.is-step-header {
          @include flexbox();
          @include justify-content(flex-start);

          .instruction--circle {
            @include align-self(center);
            @include circle($blue--dark, $content-block-header-instruct-circle-size, $white);
            margin: 0;
          }

          .instruction--text {
            font-size: $font-sm;
            line-height: $content-block-header-instruct-circle-size;
          }
        }
      }

      &.step-has-content-above {
        margin-top: $margin--x-large;
      }
    }
  }

  .content-block--body {
    padding: $content-block-padding--m;
  }

  .content-block--footer {
    padding: $content-block-padding--xm;
    background-color: $white;
    border-top: $content-block-border;
    border-radius: 0 0 $block-border-radius--xm $block-border-radius--xm;
  }

  &.content-block_inverted {
    background-color: $white;

    .content-block--header {
      background-color: $blue--xx-light;
    }

    .content-block--footer {
      background-color: $blue--xx-light;
    }
  }

  // This is to avoid shift click trying to select the text content between checkboxes for shift-select
  &.create-item--block .checkbox-column {
    user-select: none;
  }

  &.create-item--block,
  &.item-form-section {
    margin-top: var(--spacing-5);
    
    .content-block--body {

      & > div:last-child {

        .form-control:not(.document-form):last-child {
          margin-bottom: 0;
        }
      }

      & > .form-control:not(.document-form):last-child {
        margin-bottom: 0;
      }

      .form-control.document-form {
        width: 60%;
      }
    }
  }

  &.create-partnership--block {
    margin-top: $margin--x-large;
  }

  &.balance-block {

    .content-block--body {
      padding: $padding--x-large $padding--larger $padding--m-large;
    }

    .content-block--footer {
      border-top: $content-block-border-light;

      button {
        font-size: $font-xs;
      }
    }

    &:not(.currency-amount) {
      color: $grey--x-dark;
      margin-top: $margin--xm;
      text-align: center;
      width: 100%;
    }

    p {
      margin-bottom: $margin--sm;
      margin-top: $margin--xm;
      text-align: center;
      width: 100%;

      &:last-of-type {
        margin-top: $margin--large;
      }
    }

    .balance-block--chip-wrapper {
      margin: $margin--xm auto $margin--xs;
    }

    .balance-block--chip {

      span {
        white-space: nowrap;
      }

      .balance-block--chip--text--label {
        margin-right: $margin--xs;
      }
    }

    .balance-block--loading {
      @include align-items(center);
      @include flex-direction(column);
      @include flexbox();
      margin: $margin--m auto $margin--xm;
      padding: $padding--xs 0;
    }

    &.balance-block_has-pending {

      .content-block--body {
        padding-bottom: $padding--m;
      }
    }

    &.balance-block_no-footer {
      border-radius: $balance-block-no-footer-border-radius;
    }
  }
}

.content-block--shade {
  position: relative;
  opacity: 1;
  transition: opacity 0.2s ease-in-out, filter 0.2s ease-in-out;

  .content-block--shade--overlay {
    position: absolute;
    height: 0;
    width: 0;
    top: 0;
    left: 0;
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
  }

  &.shady {
    opacity: 0.85;
    filter: grayscale(30%);

    * {
      user-select: none;
      pointer-events: none;
    }

    .content-block--shade--overlay {
      background-color: $blue--xx-light;
      opacity: 0.4;
      height: 100%;
      width: 100%;
      transition: none;
    }
  }
}
