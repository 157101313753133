@import 'css/base/color.scss';
@import 'css/base/margin.scss';
@import 'css/base/spacing.scss';
@import 'css/base/typography.scss';
@import 'css/mixins/alignment.scss';
@import 'css/mixins/flexbox.scss';
@import 'css/mixins/includeMedia.scss';
@import 'css/variables/sizes.scss';

.splashscreen {
  @include flexbox();
  @include flex-direction(column);
}

.splashscreen--content {
  @include flexbox();
  @include flex-flow(column);
  @include justify-content(center);

  @include centerer('both');

  max-width: $container--narrow;
  text-align: center;
  background-color: transparent;
}

.splashscreen--image {
  .sk-three-bounce {
    margin-top: $margin--larger;
  }
  img {
    margin: auto;
  }
}

.splashscreen--progress-header {
  margin-top: $margin--larger;
}

.splashscreen--progress-text {
  margin-top: $margin--xm;
}

.quickswitch-splashscreen {
  .modal-body {
      @include media ($mobileOnly) {
        height:100vh !important;
      }
  }
}