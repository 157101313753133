@import 'css/base/color.scss';
@import 'css/base/color.scss';
@import 'css/base/margin.scss';
@import 'css/base/typography.scss';
@import 'css/mixins/mediaQuery.scss';
@import 'css/variables/global.scss';


.feature-heading {
  pointer-events: none;

  // position text center
  &.center {
    .feature-heading--name,
    .feature-heading--subtitle,
    .feature-heading--title {
      text-align: center;
    }

    .feature-heading--subtitle {
      margin-top: $margin--xs;
    }
  }

  .feature-heading--name {
    margin-bottom: $margin--xm;

    color: $aqua--bold;
    font-size: $font-xs;
    font-weight: $bold;
    letter-spacing: 0.22em;
    text-transform: uppercase;
  }

  .feature-heading--title {
    color: $blue--dark;
    opacity: 0.8;
    font-family: $marketing-font-family;
    font-size: 26px;
    line-height: 30px;
    letter-spacing: -0.5px;

    margin-bottom: $margin--m;

    @include media('>tablet') {
      margin-bottom: $margin--sm;
    }
  }

  .feature-heading--subtitle {
    margin-top: $margin--sm;
    margin-bottom: 0;

    color: $grey--x-dark;
    font-weight: $semibold;
    line-height: $paragraph;
  }
}