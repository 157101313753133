@import 'css/base/block.scss';
@import 'css/base/color.scss';
@import 'css/base/margin.scss';
@import 'css/base/padding.scss';
@import 'css/base/typography.scss';
@import 'css/mixins/flexbox.scss';


.payment-method-hint-base {
  // As per design guidelines (https://github.com/routablehq/frontend/pull/2483#issuecomment-777149623), this component
  // should utilize a font size of 14px instead of 13px like we use elsewhere.
  .font-size--regular {
    font-size: $font-sm !important;
  }

  &__box {
    margin-top: $margin--xm;

    border: 1px solid $grey--x-light;
    border-radius: $block-border-radius--xm;

    &--content {
      padding: $padding--m-large;
    }

    &--footer {
      @include flexbox();
      @include align-items(center);

      border-bottom-left-radius: $block-border-radius--xm;
      border-bottom-right-radius: $block-border-radius--xm;

      padding: $padding--m $padding--m-large;

      &.active {
        background-color: $green--light;
        color: $green--bold;
      }

      &.pending {
        background-color: $yellow--light;
        color: $yellow--medium;
      }

      svg {
        margin-right: $margin--xm;
      }
    }
  }
}
