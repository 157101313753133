@import 'css/base/block.scss';
@import 'css/base/color.scss';
@import 'css/base/spacing.scss';
@import 'css/mixins/flexbox.scss';
@import 'css/variables/global.scss';


$fcb--border-default: 1px solid var(--color-grey20);
$fcb--border-active: 1px solid $blue--bold;

.form-control-boundary {
  @include inline-flex-col-center();

  background-color: $white;
  border-radius: 4px;
  border: $fcb--border-default;
  height: $form-height-xl;
  padding: $spacing--8 $spacing--12;
  transition: border 0.3s;
  width: 100%;

  &.horizontal {
    margin-right: $spacing--16;
    width: 100%;

    &:last-child {
      margin-right: 0;
    }
  }

  &.disabled {
    background-color: $blue--x-light;
    border: $fcb--border-default;
    pointer-events: none;
  }

  &.active,
  &:focus,
  &:hover {

    &:not(.disabled) {
      border: $fcb--border-active;
    }
  }

  &.clickable:not(.disabled) {
    cursor: pointer;
  }
}
