@import 'css/mixins/flexbox.scss';


.display--inline-block {
  display: inline-block;

  &--important {
    display: inline-block !important;
  }
}

.display--inline-flex {
  display: inline-flex;
}

.display--inline {
  display: inline;

  &--important {
    display: inline !important;
  }
}

.display--table {
  display: table;
}

.display--table-cell {
  display: table-cell;
}

.display--table-row {
  display: table-row;
}

.display--block {
  display: block;
}

.display--flex {
  @include flexbox();
}

.display--flex-align-center {
  align-items: center;
}

.display--none {
  display: none;
}

/* Position */
.position--relative {
  position: relative;
}

.position--absolute {
  position: absolute;
}

.visibility--hidden {
  visibility: hidden;
}
