@import '../../global/css/base/color.scss';
@import '../../global/css/base/typography.scss';
@import '../../global/css/base/margin.scss';

.section {
  width: 100% !important;
}

.search-companies--field {
  height: 100%;

  input {
    height: 100%;
    min-height: 56px;
    width: 100%;
    line-height: 1.3em;

    color: $grey--x-dark;
    font-size: $font-xs;
    font-weight: $semibold;

    background-color: transparent;
    outline: 0;
    border: hidden;
    resize: none;
    -webkit-appearance: none;
    -moz-appearance: none;
  }
}
