@import 'css/base/margin.scss';
@import 'css/base/spacing.scss';


.data-card-detail {
  &__label {
    // prevent label text from wrapping and shrinking
    min-width: max-content;
  }

  &__value {
    display: inline-flex;
    align-items: center;
    gap: $spacing--8;
    margin-top: $margin--xs;
  }
}