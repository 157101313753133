@import 'css/base/block.scss';
@import 'css/base/color.scss';
@import 'css/base/margin.scss';
@import 'css/base/padding.scss';
@import 'css/base/typography.scss';
@import 'css/mixins/includeMedia.scss';


.maintenance-mode,
.old-version-mode {

  .branded--contents {
    margin: $margin--large-aligner auto 0;
    max-width: 500px;

    @include media("<tablet_small") {
      max-width: calc(100% - #{$padding--large});
    }
  }

  .feature-heading--title {
    color: $white;
  }

  .maintenance--body {
    .maintenance-mode--construction-image {
      width: 276px;
      height: 186px;
      background-image: url('#{$s3-path}/images/maintenance/maintenance-mode.svg');
      margin: 0 auto;
    }

    .old-version-mode--construction-image {
      width: 368px;
      height: 192px;
      background-image: url('#{$s3-path}/images/old-version/oldVersion-mode.svg');
      margin: 0 auto;
    }
  }

  .generic-card {
    padding: 0 $padding--large $padding--large;

    .maintenance--body {
      .maintenance--upper-body {
        background-color: $blue--x-light;
        border-radius: $block-border-radius--xm $block-border-radius--xm 0 0;
        margin: 0 (-$margin--large);
        padding-top: $padding--m;
      }

      p {
        margin-top: 18px;
      }
    }
  }
}
