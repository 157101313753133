@import 'css/base/color.scss';
@import 'css/base/margin.scss';
@import 'css/base/padding.scss';
@import 'css/base/typography.scss';
@import 'css/mixins/flexbox.scss';
@import 'css/mixins/includeMedia.scss';


.action-nav-search {
  padding-left: $padding--large;

  @include media("<=phone_large") {
    padding-left: $padding--m;
  }
}

.search-form {
  padding-left: $padding--large;
  border-left: 1px solid var(--color-grey20);

  .search-container {
    @include flexbox();
    @include flex-flow(row);
    @include align-items(center);
    border-radius: 4px;
    height: 32px;
    width: 250px;

    .search-icon {
      padding-left: $padding--xm;
      color: $dark-jordan;
      font-size: $font-xs;
      font-weight: $semibold;
    }

    .search-input {
      background: none;
      font-size: $font-regular;
      padding-left: $padding--xm;
      width: 200px;

      @include media("<=phone_large") {
        width: 100%;
      }
    }
  }

  @include media("<=phone_large") {
    padding-left: $padding--m;
  }
}
