@import './spacing.scss';


$icon-size--small: 11px;

$input-container-height: 58px;

$tag-height: 32px;
$tag-rect-height: 20px;
$tag-rect-height-xs: 18px;

$bar-button-size: 24px;

$company-select-height: 58px;

$external-header--height-mobile: 56px;
$external-header--height: 76px;

$select-base-indicator-size: 14px;

$dynamic-field-gap: $spacing--8;
$dynamic-field-base: 44px;
$dynamic-cell-field-base: 48px;


//noinspection CssInvalidPseudoSelector,CssUnknownProperty
:export {
  dynamicCellFieldBase: $dynamic-cell-field-base;
  dynamicFieldBase: $dynamic-field-base;
  dynamicFieldGap: $dynamic-field-gap;
}
