@import 'css/base/block.scss';
@import 'css/base/color.scss';
@import 'css/base/typography.scss';
@import 'css/base/margin.scss';
@import 'css/base/padding.scss';
@import 'css/base/spacing.scss';
@import 'css/mixins/flexbox.scss';
@import 'css/mixins/includeMedia.scss';
@import 'css/variables/global.scss';


$radio--border-default: 1px solid var(--color-grey20);
$radio--border-active: 1px solid $blue--bold;
$radio--border-radius: 4px;
$radio--height: $form-radio-size;
// needs to be this exact value to be rendered as 18px
$radio--width: 18px;


.radio--container {
  border-radius: $radio--border-radius;
  border: $radio--border-default;
  background-color: $white;
  display: inline-block;
  width: 100%;

  & > div {
    &.radio--header {
      border-radius: $radio--border-radius;
      height: $form-height-xl;
      padding: $padding--xm $padding--m;

      &.has-content {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }
    }

    &.radio--content {
      padding: $padding--m-large;

      background-color: $blue--x-light;
      border-top: $radio--border-default;
      border-bottom-left-radius: $radio--border-radius;
      border-bottom-right-radius: $radio--border-radius;
    }
  }

  &.horizontal {
    width: 100%;
    margin-right: $margin--m-large;

    &:last-child {
      margin-right: 0;
    }

    @include media('<=tablet') {
      &:nth-child(even),
      &:nth-child(odd){
        margin-left: 0;
        margin-right: 0;
      }

      &:not(:last-child) {
        margin-bottom: $margin--xm;
      }
    }
  }

  &.active {
    border: $radio--border-active;
  }

  &:hover {
    border: $radio--border-active;
  }

  &.disabled {
    pointer-events: none;
    border: $radio--border-default;
    background-color: $blue--x-light;
  }

  .radio--title {
    width: calc(100% - #{$radio--width});
    flex-direction: row;
    @include justify-content(space-between);
  }

  .radio--tooltip {
    pointer-events: all;
    @include flexbox();
    @include align-items(center);
  }
}

.radio--label {
  cursor: pointer;
  height: 100%;
  position: relative;
  width: 100%;
  @include flexbox();

  &.no-subtext {
    @include align-items(center);

    .radio--indicator {
      margin-top: 0;
    }
  }

  .radio--subtext {
    line-height: $line-height--m;
  }

  .radio--indicator {
    background-color: $blue--xx-light;
    border: $radio--border-default;
    border-radius: 50%;
    margin-top: $margin--xs;
    height: $radio--height;
    width: $radio--width;
    flex-shrink: 0;
  }
}



input[type='radio']:not(.form-radio) {
  margin-right: $margin--m-large;
  position: absolute;
  opacity: 0;
  z-index: -1;

  &:checked {
    ~ .radio--indicator {
      display: flex;
      justify-content: center;
      align-items: center;
      
      &::after {
        content: "";
        width: calc(#{$radio--width} / 3);
        height: calc(#{$radio--width} / 3);
        background-color: $white;
        display: block;
        border-radius: 50%;
      }

      &.primary {
        color: $white;
        background-color: $blue--bold;
        border: $radio--border-active;
      }

      &.success {
        color: $white;
        background-color: $main-green;
        border: 1px solid $main-green;
      }

      &.completed {
        color: $white;
        background-color: $aqua--bold;
        border: 1px solid $aqua--bold;
      }

      &.disabled {
        color: $white;
        background-color: $main-jordan;
        border: 1px solid $main-jordan;
      }
    }
  }
}
