@import 'css/mixins/colors.scss';
@import 'css/base/typography.scss';


:root {
  // colors
  --blue--x-light: #{$blue--x-light};
  --blue--light: #{$blue--light};
  --blue--primary: #{$blue--primary};
  --blue--bold: #{$blue--bold};
  --blue-ultramarine: #4945FF;

  --grey: #66788A;
  --grey--x-light: #{$grey--x-light};
  --grey--dark: #{$grey--dark};
  --grey--x-dark: #{$grey--x-dark};
  --grey--xx-dark: #{$grey--xx-dark};

  --neutral-black: #2E2F33;
  --darker-black: #201A1A;
  --light-blue-background: #F6F9FF;
  --eerie-black: #1F1C1A;

  --white: #{$white};
  --white-antiflash: #F0F0F0;
  --white-antiflash--bold: #ECF0FA;
  --white-azureish: #E1E5F4;

  --red--05: #{$red--05};
  --fire-opal: #EB6345;

  // font weights
  --font-weight--regular: #{$regular};
  --font-weight--semibold: #{$semibold};

  // font sizes
  --font--12: #{$font--12};
  --font--50: #{$font--50};

  // container width
  --container-width--small: 31.25rem;
  --container-width--medium: 37.5rem;
  --container-width--large: 43.75rem;
}
