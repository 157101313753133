@import 'css/base/block.scss';
@import 'css/base/color.scss';
@import 'css/base/spacing.scss';
@import 'css/base/typography.scss';
@import 'css/mixins/flexbox.scss';
@import 'css/mixins/includeMedia.scss';
@import 'css/variables/global.scss';

.interaction-list {
  width: 100%;

  &.has-items .interaction-bar {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .interaction-list-items {
    border: $border--blue-light;
    border-top-width: 0;
    border-radius: 0 0 $block-border-radius--xm $block-border-radius--xm;

    .interaction-list-item {
      background-color: $blue--xx-light;
      padding: $spacing--12;
      border-bottom: $border--blue-light;

      &:last-child {
        border-bottom: none;
        border-radius: 0 0 $block-border-radius--xm $block-border-radius--xm;
      }
    }
  }
}
