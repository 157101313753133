@import "css/base/color.scss";
@import "css/base/margin.scss";
@import "css/base/padding.scss";
@import "css/base/typography.scss";
@import "css/mixins/flexbox.scss";
@import "css/mixins/includeMedia.scss";
@import "css/variables/global.scss";

.action-nav {
  @include flexbox();
  @include flex-flow(row nowrap);
  @include align-items(center);
  @include justify-content(flex-start);

  width: 100%;
  height: $nav--height;
  background-color: $white;
  box-sizing: border-box;
  border-bottom: $action-nav--border;
  padding: 0 $margin--large-aligner;

  &.no-border {
    border-color: transparent;
  }

  &--no-border {
    border-bottom: none;
    box-shadow: none;
  }

  &.hide-mobile-menu {
    @include media("<=phone_large") {
      padding-left: $mobile-side-gutter--small;
    }
  }

  .tab-wrapper {
    margin-right: 0;
  }

  .action-nav--main-content {
    @include flexbox();
    @include flex-flow(row nowrap);
    @include justify-content(flex-start);
    @include align-items(center);
    @include flex(1);
    @include order(0);
    @include align-self(flex-start);

    height: 100%;

    .action-nav-tabs--list {
      @include flexbox();
      @include flex-flow(row nowrap);
      @include align-items(center);
      margin-right: auto;

      .tab {
        display: inline-block;
        text-decoration: none;
        font-size: $font-sm;
        font-weight: $regular;
        color: $black;
        text-rendering: optimizeLegibility;
        opacity: 0.4;
        padding: $padding--large 0px;
        margin-right: $margin--large;
        line-height: $line-height--sm;
        border-bottom: 1px solid transparent;

        &:hover,
        &:focus {
          text-decoration: none !important;
          cursor: pointer;
          color: $blue--primary;
          opacity: 1;
        }

        &.selected {
          position: relative;
          opacity: 1;

          &:hover,
          &:focus {
            color: $grey--x-dark;
          }

          &:after {
            content: "";
            display: block;
            position: absolute;
            bottom: 0;
            background-color: $black;
            height: 2px;
            width: 100%;
          }
        }
      }

      .action-nav--title {
        pointer-events: none;

        // Ensuring the child <p> truncates
        max-width: calc(100vw - 600px);

        @include media("<=tablet_large") {
          max-width: calc(100vw - 300px);
        }

        @include media("<=phone_large") {
          max-width: calc(100vw - 235px);
        }
      }
    }

    .action-nav--action {
      @include flexbox();
      @include justify-content(center);
      @include align-items(center);

      font-weight: $semibold;
      font-size: $font-regular;
      color: $dark-jordan;

      &.primary {
        color: $primary;
      }

      &:not(:last-child) {
        margin-right: $margin--large;
      }

      @include media("<=phone_large") {
        display: none;
      }
    }
  }

  @include media("<=phone_large") {
    padding-right: $padding--m;
  }

  &.allows-wrapping {
    height: unset;

    .action-nav--main-content .action-nav-tabs--list {
      @include align-items(flex-start);
      @include flex-wrap(wrap);

      & > li {
        height: $nav--inline-height;

        .tab {
          @include flex-col-center();
          height: $nav--inline-height;
        }
      }
    }
  }
}

// Specificity needed to avoid using !important
a.btn.btn--sm.action-nav-back-button {
  margin-right: $margin--large;
  padding: 0 $padding--xm;

  @include media("<=phone_large") {
    margin-right: $margin--m;
  }
}
