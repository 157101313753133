@import 'css/variables/global';
@import 'css/base/color';
@import 'css/base/margin';
@import 'css/base/padding';
@import 'css/base/typography';
@import 'css/mixins/flexbox';
@import 'css/mixins/includeMedia';


/* Global box variables*/
$box-v2--border-radius: 8px;
$box-v2--width: 620px;
$box-v2--login-width: 428px;
$box-v2--padding: 24px;
$box-v2--footer-padding: 16px;

/* Box footer variables */
$box-footer--height: 48px;

/* Box step variables */
$box-step--height: 4px;
$box-step--width: 94%;

$logo--height: 18px;
$logo--width: 112px;


.box-v2--container {
  background: $white;
  border: 1px solid var(--color-grey20);
  border-radius: $box-v2--border-radius;

  margin: 0;
  max-width: $box-v2--width;

  padding: $box-v2--padding;

  width: 100%;

  &--login {
    max-width: $box-v2--login-width;
  }

  &--reset-password {
    max-width: $box-v2--login-width;

    .box-v2--body {
      margin-top: 0;
    }
  }

  .box-v2--header {
    @include flexbox();
    @include align-items(center);
    @include justify-content(center);
    @include flex-direction(column);

    &__logo {
      height: $logo--height;
      width: $logo--width;
    }
  }

  .box-v2--body {
    margin-top: $spacing--24;

    .form-field--label {
      line-height: $line-height--17;
    }
  }

  .box-v2--footer {
    height: $box-footer--height;
    display: table;
    width: 100%;
    padding: $box-v2--footer-padding;

    border-top: $border--blue-light;
    background-color: var(--color-grey20);
    border-bottom-left-radius: $box-v2--border-radius;
    border-bottom-right-radius: $box-v2--border-radius;
  }
}
