@import 'css/base/color.scss';
@import 'css/base/typography.scss';
@import 'css/base/spacing.scss';

$bullet--size: 5px;

.bullet-list {
  padding-top: $spacing--6;
  &--bullet {
    background: $grey--xx-dark;
    width: $bullet--size;
    height: $bullet--size;
    border-radius: 50%;
    margin-right: $spacing--6;
    margin-top: $spacing--10;
  }

  &--text {
    font-style: normal;
    font-weight: normal;
    font-size: $font-sm;
    line-height: $line-height--xl;
    color: $grey--xx-dark;
    width: 100%; // prevents squished bullets when text is long

    a {
      color: $blue--bold;
    }
  }
}
