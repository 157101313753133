@import 'css/base/margin.scss';
@import 'css/base/typography.scss';

.email-password-form {
  .username-field-label {
    margin-top: $margin--m;
    line-height: $line-height--17;
  }

  .form-control {
      margin-bottom: $margin--m-large !important;
  }
}
