@import 'css/base/color.scss';

span.icon {
  display: inline-block;
}

.ledger-icon {
  display: inline-block;
  max-width: 100%;
}

.hover--blue-bold:hover {
  fill: $blue--bold !important;
}
