@import 'css/base/color';


.accept-pending-payments-checkbox {
  input[type="checkbox"] {
    &:checked ~ .checkbox--indicator.success {
      background-color: $black;
      border-color: $black;
    }
  }
}