@import 'css/base/color.scss';
@import 'css/base/margin.scss';
@import 'css/base/padding.scss';
@import 'css/base/sizes.scss';
@import 'css/base/spacing.scss';
@import 'css/base/typography.scss';
@import 'css/mixins/flexbox.scss';


$input-horizontal-padding: $padding--m-large;
$label-line-height: 15px;

form, textarea, input, label, .character-count, .form-error-container {
  font-family: $base-font-family;
  font-weight: $regular;
}

// INPUT
.input-container,
.select-container {
  position: relative;
  background: $white;
  border-radius: 4px;
  border: 1px solid var(--color-grey20);
  box-sizing: border-box;
  padding: $padding--xm $input-horizontal-padding;
  height: $input-container-height;

  @include flexbox();
  @include justify-content(space-evenly);
  flex-direction: column;

  transition: border, background, color 0.3s;

  // Input specific styles (input, select, textarea)
  input, select, textarea {
    color: var(--neutral-black);
    width: 100%;
    font-size: $font-sm;
    line-height: $spacing--24;
    // Firefox tweak. focus-visible causes outline: auto. This is to hide the actual input outline in favor of the outline on the container
    &:focus-visible {
      outline: 0px solid transparent;
    }
  }
  select {
    // this gets overwritten when we select a value
    color: var(--neutral-black);
    -moz-appearance: none;
    -webkit-appearance: none;
    // Overwrite container's padding without using position:absolute;
    // Fixes options' container look
    margin-left: -$input-horizontal-padding;
    padding: 0 $input-horizontal-padding;
    width: calc(100% + #{2 * $input-horizontal-padding});
  }
  textarea {
    resize: none;
  }

  // VALUE
  .special-char--validator {
    position: absolute;
    color: $transparent;
    font-size: $font-sm;
    line-height: $spacing--24;
    // this should have z-index: 2, because it should be on top of the input value
    z-index: 2;
    // this is needed so that special char validator div doesn't block click events
    // on input field [DEV-260]
    pointer-events: none;

    .invalid-char {
      color: $red--bold;
      background-color: $red--light;
    }
  }

  // LABEL
  label {
    color: var(--color-grey70);
    font-size: $font-xs;
    font-weight: $semibold;
    line-height: $label-line-height;
  }
  label ~ select {
    background-position: right $input-horizontal-padding top $spacing--8*-1;
    color: var(--neutral-black);
  }

  // PLACEHOLDER
  input, textarea {
    &::placeholder {
      display: none;
      opacity: 0;
    }
  }
  .placeholder {
    color: $grey--x-dark;
    position: absolute;
    pointer-events: none;
    margin: auto;
    font-size: $font-sm;
    line-height: $spacing--24;
    left: $input-horizontal-padding;
    right: $input-horizontal-padding;
  }
  .placeholder > span,
  label > span {
    font-weight: $bold;
    color: $blue--bold;
  }

  input ~ div.placeholder {
    bottom: 15px;
  }
  label ~ div.placeholder {
    bottom: 7px;
  }
  label ~ div.special-char--validator {
    bottom: 8.45px;
  }
  textarea ~ div.placeholder {
    top: 10px;
  }

  // SELECT CHEVRON
  .chevron {
    position: absolute;
    top: calc(50% - 8px);
    right: $spacing--16;
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
    font-size: $font-m;
    font-weight: $semibold;
    color: $grey--x-dark;
  }

  // ADDON
  &.has-addon {
    padding-right: #{3 * $input-horizontal-padding};

    &--2 {
      padding-right: #{6 * $input-horizontal-padding};
    }

    input {
      text-overflow: ellipsis;
    }
  }
  .addon-container {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    height: 100%;
    padding: $padding--xm $padding--m-large;

    @include flexbox();
    @include justify-content(center);
    @include align-items(center);

    svg.tooltip-icon:hover {
      fill: $blue--bold !important;
    }
  }

  // FOCUSED
  &:focus-within {
    border: 1px solid $blue--bold;

    label {
      color: $blue--bold;
    }
    svg:not(.not-modified) {
      fill: $blue--bold !important;
    }
  }

  .inline-label {
    display: flex;
    align-items: center;
    height: 100%;
    margin: 0 auto;
    padding: 19px 18px;
    font-size: $font-sm;
    font-weight: $semibold;
    background: var(--color-grey20);
    color: var(--color-grey70);

    &.workspace-label {
      background: $white;
      color: var(--color-grey70);
      line-height: $line-height--24;
      font-weight: $regular;
    }

    &.disabled {
      background: var(--color-grey10);
    }

    &.inline-right {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      border-top-right-radius: 7px;
      border-bottom-right-radius: 7px;
      border-left: 1px solid #D3DCF4;
    }

    &.white-background {
      background: $white;
    }
  }

  // ERROR
  &.error {
    border-color:  $red--bold;
  }

  // DISABLED
  &.disabled {
    border: 1px solid var(--color-grey20);
    background: var(--color-grey10);

    select, textarea {
      color: var(--color-grey70);
    }

    label, input, .placeholder, .placeholder span {
      color: var(--color-grey70);
    }
  }

  // SIZES
  &.h-sm {
    height: $form-height-sm;
    padding-top: 2px;
    padding-bottom: 2px;

    label {
      display: none;
    }
    .placeholder {
      bottom: 2px;
      font-size: $font-xxs;
    }
    input {
      font-size: $font-xxs;
    }
  }

  &.h-m {
    height: $form-height-m;
    padding-top: 6px;
    padding-bottom: 6px;

    label {
      display: none;
    }
    .placeholder {
      bottom: 4px;
      font-size: $font-xs;
    }
    input {
      font-size: $font-xs;
    }
  }

  &.h-lg {
    height: $form-height-l;
  }

  &.h-xl {
    height: $form-height-xl;
  }

  &.h-xxl {
    height: $form-height-xxl;
  }

  &.w-xs {
    width: $form-width-xs;

    &.right {
      margin-left: $form-margin;
    }
  }
}

// ERRORS
.form-error-container {
  background-color: $red--light;
  border-radius: 14px;
  display: inline-block;
  margin-top: $spacing--8;
  padding: 6px $padding--xm;

  p {
    color:  $red--bold;
    font-size: $font-xs;
    font-weight: $bold;
    margin-bottom: $margin--xm;
    
    &::first-letter {
      text-transform: uppercase;
    }
  }
}

// CHARACTER COUNT
.character-count {
  color: $grey--x-dark;
  font-size: $font-xs;
  line-height: $spacing--16;
  margin-top: 10px;

  .highlight-difference {
    color:  $red--bold;
  }
}


/*
 * OVERWRITE
 */
// SEARCH FILTER INPUT
.matching-filter--search {
  label {
    display: none;
  }
}

// LEDGER FORM FIELD
.input-container.ledger-form-field {
  border: none;
  padding: 0;
}
