@import 'css/base/color.scss';
@import 'css/base/spacing.scss';
@import 'css/base/typography.scss';
@import 'css/mixins/flexbox.scss';


$number-chip-size: 18px;
$number-chip-font-size: 9px;

.number-chip {
  @include flexbox();
  @include align-items(center);
  @include justify-content(center);

  background-color: $white;
  border-radius: 100px;
  cursor: pointer;

  font-size: $number-chip-font-size;
  font-weight: $bold;
  height: $number-chip-size;
  line-height: $number-chip-size;
  padding-left: $spacing--6;
  padding-right: $spacing--6;
}
