@import 'css/base/color.scss';
@import 'css/base/margin.scss';
@import 'css/base/typography.scss';
@import 'css/mixins/includeMedia.scss';
@import 'css/variables/width.scss';


$external-prompt--width: 500px;

.external-prompt {
  .external-prompt--form-wrapper {
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 16px;

    background-color: darken($blue--x-light, 1%);
    border: 1px solid var(--color-grey20);
    border-radius: 10px;
    box-shadow: 0 1px 2px var(--color-grey20);

    & > p {
      margin-bottom: 20px;
      line-height: 18px;

      color: darken($dark-jordan, 8%);
      font-size: 13px;
    }
  }

  .external-prompt--instructions {
    margin-top: $margin--large;

    &.is-above-button {
      margin-top: $margin--large;
      margin-bottom: $margin--x-large;
    }
  }

  p {
    &.external-prompt--p {
      margin-top: $margin--large;
      pointer-events: none;

      color: darken($dark-jordan, 10%);
      font-size: $font-regular;
      font-weight: $semibold;

      &.has-dark-wrapper {
        margin-top: $margin--large;
        padding: 10px 14px;

        background-color: darken($blue--x-light, 3%);
        border-radius: 6px;
      }

      &.has-confirm-wrapper {
        margin-top: $margin--large;
        padding: 10px 14px;

        color: $white;
        font-weight: $bold;
        background-color: $aqua--bold;
        border-radius: 6px;
      }
    }
  }

  @include media(">=tablet_large") {
    display: table-cell;
    vertical-align: middle;
    width: 52%;
    min-width: $external-prompt--width;
    max-width: $content-width--small;
    padding-right: 8%;
  }

  &.external-prompt--wide {
    @include media(">=tablet_large") {
      min-width: 650px;
    }
  }
}
