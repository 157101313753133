@import 'css/base/color.scss';
@import 'css/base/margin.scss';
@import 'css/mixins/bulletList.scss';


.alert-error-list {
  @include bullet-list();

  > li:only-child {
    padding: 0;

    &:before {
      content: '';
      margin: 0;
    }
  }
}

.alert-error-text {
  color: $grey--xx-dark;
  font-size: $font-sm;
}

.request-id-text {
  color: $dark-silver;
  font-size: $font-xxs;
  font-weight: $semibold;
}

.alert-error-image {
  width: 150px;
  height: 96px;
}
