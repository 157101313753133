@import 'css/base/margin.scss';
@import 'css/base/padding.scss';
@import 'css/mixins/includeMedia.scss';


.settings-wrapper {

  .settings-page {

    .settings-section--balance-history,
    .balance-settings--faq-section {
      padding-top: 0;
    }

    .balance-settings--faq-section {
      padding-bottom: $padding--m-large;
    }

    .settings-section--balance-checklist {
      margin-top: $margin--large;

      .balance-checklist--icon {
        margin-right: $margin--xm;
        vertical-align: middle;
      }

      .grid {
        height: 100%;

        .hint {
          margin-top: $margin--m;

          @include media(">=tablet") {
            margin-top: 0;
          }
        }
      }

      @include media(">=tablet") {
        margin-top: 0;
        padding-left: $padding--large;

        & > .check-list {
          margin-bottom: 0;
        }
      }
    }
  }
}

.balance-block--wrapper {
  max-width: 440px;
}
