@import 'css/base/color.scss';
@import 'css/base/margin.scss';
@import 'css/base/typography.scss';
@import 'css/mixins/browserQueries.scss';
@import 'css/mixins/flexbox.scss';
@import 'css/mixins/includeMedia.scss';
@import 'css/variables/global.scss';


$external--mobile-max-width: 600px;

$external--margin: 10px;
$external--section-margin: 4 * $external--margin;
$external--padding: 16px;
$external--border-radius: 10px;
$external-footer--height-mobile: 220px;

.external-body--container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
}

.external-body {
  padding: 3 * $external--padding $margin--m;
  display: block;

  // Fix button layout issue with hint 
  .btn-v2.verify-deposits-button {
    font-size: $font-regular;
    white-space: nowrap;
  }

  h1 {
    line-height: 42px;
    font-size: 32px;
  }

  h3 {
    margin-bottom: 1.4* $external--margin;
    font-weight: $bold;
  }

  .has-item {
    display: table;
    max-width: $external--mobile-max-width;

    .item--right {
      margin-top: 2 * $margin--large;

      &.no-item-id {
        display: none;
      }

      @include media(">=tablet_large") {
        margin-top: 0;
        display: table-cell;
        vertical-align: middle;
        // padding-left: auto;

        &.no-item-id {
          display: table-cell;
        }
      }

      @include media(">=tablet_large") {
        max-width: $external-page--max-width;
        height: 100%;
      }
    }

    @include media(">=tablet_large") {
      max-width: $external-page--max-width;
    }
  }

  .no-item {
    max-width: $external--mobile-max-width;
  }

  @include media(">=tablet") {
    padding: 3 * $external--padding $external--padding;
  }

  .company-rep {
    display: table;
    vertical-align: middle;
    margin-bottom: $external--margin;
    pointer-events: none;

    .company-rep--avatar {
      display: table-cell;
      width: 32px + $external--margin;
    }

    .company-rep--info {
      display: table-cell;
      vertical-align: middle;

      p {
        margin-bottom: 0;

        font-size: $font-xs;
        font-weight: $semibold;
      }
    }
  }

  .hiw {
    margin-bottom: $external--section-margin;
    padding: 1.5 * $external--padding;

    background: $white;
    border: 1px solid var(--color-grey20);
    border-radius: $external--border-radius;
    box-shadow: -1px 2px 0 0 $blue--x-light;

    .hiw--step {
      display: table;
      height: 38px;
      margin-bottom: 2 * $external--margin;

      &:last-child {
        margin-bottom: 0;
      }

      .step--status {
        display: table-cell;
        vertical-align: top;
        width: 3 * $external--margin;

        // align status with the top of the step description
        position: relative;
        top: 2px;

        .trailing-dots {
          position: relative;
          bottom: 4px;
        }

        .trailing-dot {
          width: 12px;
          height: 8px;
          text-align: center;

          color: var(--color-grey20);

          span {
            font-size: $font-xs;
          }

          &:last-child {
            height: 0;
          }
        }

        .status {
          height: 12px;
          width: 12px;

          &.circle {
            border-radius: 20px;
          }

          &.incomplete {
            background-color: $white;
            border: 2px solid $main-green;
          }

          &.complete {
            background-color: $main-green;
            border: none;
          }

          &.square {
            border-radius: 4px;
            background-color: $main-silver;
          }
        }
      }


      .step--description {
        display: table-cell;
        vertical-align: top;

        p {
          &.dark {
            margin-bottom: 2px;

            font-weight: $bold;
          }

          &.dark-grey {
            margin-bottom: 0;
            line-height: 18px;

            font-size: 13px;

            @include media(">=tablet_small") {
              line-height: initial;
            }
          }
        }
      }
    }
  }

  .confirmation {
    color: $aqua--bold;
    margin-bottom: $external--margin;

    span {
      vertical-align: middle;
      margin-right: 8px;

      font-size: 24px;
    }

    h3 {
      display: inline-block;
      vertical-align: middle;
      margin-bottom: 0;
    }

    &.error {
      color: $red--bold;
    }
  }

  .document {
    & > div.document-form {
      margin-bottom: 0;
    }
  }

  .add-section-margin {
    margin-bottom: $external--section-margin;
  }

  .questions {
    margin-bottom: 0;
    padding: 1.5 * $external--padding;

    background: $white;
    border: 1px solid var(--color-grey20);
    border-radius: $external--border-radius;
    box-shadow: -1px 2px 0 0 $blue--x-light;

    .question {
      margin-bottom: 2 * $external--margin;

      &:last-child {
        margin-bottom: 0;
      }

      p {
        &.dark {
          font-weight: $bold;
          margin-bottom: 4px;
        }

        &.dark-grey {
          margin-bottom: 0;
          line-height: 18px;

          font-size: 13px;

          @include media(">=tablet_small") {
            line-height: initial;
          }
        }
      }
    }

    @include media(">=tablet_small") {
      margin-bottom: $external--section-margin;
    }
  }
}

// General overrides for external pages
.external-container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: $blue--x-light;
}

@include internetExplorer() {
  .external-container {
    /* ie can't handle this layout without a hard height */
    height: 100vh;
    /* also it's not usable if the screen is shorter than the inner form elements */
    min-height: 700px;
  }

  .external-body {
    .has-item {
      /* somehow, ie totally screws up this aspect ratio;
      the container gets really tall and too skinny, unless we... */
      max-width: none;
    }
  }

  @media (min-height:700px) {
    .external-container {
      /* if the screen is at least 700px tall, the form is fully on-screen and we can use 100vh again */
      min-height: 100vh;
    }
  }
}

footer.external-footer {
  height: $external-footer--height;
  padding: $external--padding - 4 $external--padding;
}
