@import '../../../../global/css/base/color.scss';
@import '../../../../global/css/base/margin.scss';
@import '../../../../global/css/base/typography.scss';
@import '../../../../global/css/mixins/flexbox.scss';
@import '../../../../global/css/mixins/includeMedia.scss';
@import '../../../../global/css/variables/global.scss';


$external-receipt--margin: 10px;

.external-receipt--wrapper {
  @include flexbox();
  @include flex-flow(row nowrap);
  @include align-items(center);
  @include justify-content(center);

  height: 100%;
}

.external-receipt--container {
  padding: 10px;
  background-color: $white;
  border-radius: 24px;
  border: 1px solid var(--color-grey20);
  box-shadow: 0 3px 6px 0 var(--color-grey20);

  &.display-in-modal {
    margin-left: 0;
    // Hide receipt on mobile
    display: none;

    @include media(">=tablet_large") {
      display: block;
    }
  }

  &.hide-receipt {
    background-color: inherit;
    border: none;
    box-shadow: none;
  }

  &:not(:only-child) {
    margin-bottom: $margin--m;
  }

  .external-receipt--attachment {
    display: block;
    margin-top: 3 * $external-receipt--margin;

    .external-receipt--attachment-actions {
      display: table;
      margin-bottom: $external-receipt--margin;
      font-size: $font-xs;
      width: 100%;

      .content--left {
        display: table-cell;
        width: 60%;
        text-align: left;

        span {
          margin-left: 4px;
          vertical-align: middle;
          line-height: $line-height--l;
          color: $dark-jordan;
          font-weight: $semibold;
        }
      }

      .content--right {
        display: table-cell;
        width: 40%;
        text-align: right;

        a {
          margin-left: $external-receipt--margin;

          span {
            vertical-align: middle;
            line-height: $line-height--l;

            &.icon-left {
              margin-right: 6px;

              &.large {
                font-size: 16px;
              }
            }
          }
        }
      }
    }

    .external-receipt--attachment-preview {
      display: block;
      max-height: 300px; // take this out
      padding: 10px;
      background-color: $white;
      border: $border--inset-content;
      border-radius: 10px;
    }
  }

  @include media(">=tablet_large") {
    width: 442px;
    overflow-y: auto;
  }
}

.external-onboarding-image {
  background-color: $white;
  border: 1px solid var(--color-grey20);
  border-radius: 16px;
  padding: 90px 40px;
  width: 420px;
}
