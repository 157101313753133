$transition-timing--sm: 0.1s;
$transition-timing--m: 0.2s;

$transition-rotate-90-timing: $transition-timing--m;
$transition-color-timing: 0.12s;
$transition-accordion-timing: 0.4s;
$transition-accordion-timing-short: $transition-timing--m;

$transition-border-btm-color: border-bottom-color $transition-timing--m;
$transition-margin-btm: margin-bottom $transition-timing--m;

$easing-color-cub-bez: cubic-bezier(0.4, 0, 0.2, 1);
