$spacing--0: 0px;
$spacing--1: 1px;
$spacing--2: 2px;
$spacing--3: 3px;
$spacing--4: 4px;
$spacing--5: 5px;
$spacing--6: 6px;
$spacing--8: 8px;
$spacing--10: 10px;
$spacing--12: 12px;
$spacing--14: 14px;
$spacing--16: 16px;
$spacing--18: 18px;
$spacing--20: 20px;
$spacing--22: 22px;
$spacing--24: 24px;
$spacing--25: 25px;
$spacing--26: 26px;
$spacing--32: 32px;
$spacing--36: 36px;
$spacing--40: 40px;
$spacing--60: 60px;
$spacing--72: 72px;
$spacing--85: 85px;
$spacing--120: 120px;


//noinspection CssInvalidPseudoSelector,CssUnknownProperty
:export {
  space1: $spacing--1;
  space12: $spacing--12;
  space36: $spacing--36;
  space72: $spacing--72;
}
