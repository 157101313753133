@import '../mixins/flexbox.scss';
@import '../mixins/includeMedia.scss';


.flex-col {
  @include flexbox();
  @include flex-direction(column);
}

.flex-col-center {
  @include flex-col-center();
}

.inline-flex-col-center {
  @include inline-flex-col-center();
}

.flex-row {
  @include flexbox();
  @include flex-direction(row);

  &.flex-direction--row-reverse {
    @include flex-direction(row-reverse);
  }
}

.stack-on-mobile {
  @include flex-direction(column);

  @include media(">=tablet") {
    @include flex-direction(row);
  }
}

.flex-parent-truncate {
  @include flex-parent-truncate();
}

.flex-wrap--nowrap {
  @include flex-wrap(nowrap);
}

.flex-wrap--wrap {
  @include flex-wrap(wrap);
}

.flex-shrink--0 {
  @include flex-shrink(0);
}

// Flex size
.flex--1 {
  flex: 1;
}

.flex--2 {
  flex: 2;
}

.flex--3 {
  flex: 3;
}

.flex--4 {
  flex: 4;
}

.flex--5 {
  flex: 5;
}

.white-space-pre-wrap {
  @include white-space-pre-wrap();
}
