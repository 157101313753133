@import 'css/base/margin.scss';
@import 'css/base/padding.scss';
@import 'css/base/typography.scss';
@import 'css/mixins/flexbox.scss';
@import 'css/variables/width.scss';


$image-width: 282px;
$image-height: 144px;

.csv-modal--body {
  @include flexbox();
  @include flex-direction(column);
  @include align-items(center);

  .csv-modal--image {
    display: block;
    width: $image-width;
    height: $image-height;
    margin: 0 auto;
  }
}
