@import '../../global/css/base/color.scss';
@import '../../global/css/base/padding.scss';
@import '../../global/css/base/typography.scss';


.highlight {
  display: flex;
  align-items: center;
  padding: $padding--xm;
  background-color: lighten($light-yellow, 10%);
  border: 1px solid $dark-yellow;
  border-radius: 50px;

  color: $grey--x-dark;
  font-size: $font-xs;
  font-weight: $bold;
}

