@import '../mixins/size.scss';
@import '../variables/global.scss';
@import '../variables/width.scss';


// =========
// width
// =========

.content-width--small {
  width: $content-width--small;
}

.content-width--full {
  width: $content-width--full;
}

.width--unset {
  width: unset;

  &--important {
    width: unset !important;
  }
}

.width--100-percent {
  width: 100%;
}

.width--95-percent {
  width: 95%;
}

.width--90-percent {
  width: 90%;
}

.width--75-percent {
  width: 75%;
}

.width--50-percent {
  width: 50%;
}

.width--25-percent {
  width: 25%;
}

.width--max-content {
  width: max-content;
}

.width--form-control-standard {
  width: $form-control-max-width-standard;
}

.max-width--full {
  max-width: 100%;
}

.min-width--full {
  min-width: 100%;
}

.min-width--auto {
  min-width: auto;
}

// =========
// height
// =========
.height--100-percent {
  height: 100%;
}


// =========
// height + width
// =========

.full-screen {
  @include fullScreen();
}
