@import '../../global/css/base/block.scss';
@import '../../global/css/base/color.scss';
@import '../../global/css/base/padding.scss';
@import '../../global/css/base/margin.scss';
@import '../../global/css/mixins/flexbox.scss';
@import '../../global/css/mixins/zIndex.scss';


.item-block {
  background-color: $blue--xx-light;
  border-radius: $block-border-radius--sm;
  border: 1px solid var(--color-grey20);
  box-shadow: 0 2px 4px $blue--x-light;
  margin-bottom: $margin--large;

  .item-block--header {
    width: 100%;
    background-color: $white;
    border-bottom: 1px solid var(--color-grey20);
    border-radius: $block-border-radius--sm $block-border-radius--sm 0 0;
    padding: $padding--m;
  }

  .item-block--body {
    @include flex-direction(column);
    padding: $padding--m;
  }

  .item-block--detail-block {
    @include flexbox();
    @include align-items(center);
    margin-bottom: $margin--m;

    .align-icon-long-text {
      @include align-self(flex-start);
      margin-top: 4px;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.tooltip--item-block {
  @include zIndex("SLIDEOUT", "TOOLTIP");
}
