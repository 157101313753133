@import 'css/mixins/borders.scss';

@import './Select.scss';


.select-alt {

  &.Select {
    height: unset;
    min-height: $select--height;

    .Select__control {
      height: unset;
      min-height: $control--height;
    }
  }

  .Select__value-container--is-multi {
    padding-top: $spacing--20;
    gap: $spacing--8;    

    .Select__multi-value {
      @include flexbox();
      @include align-items(center);
      // This removes the background color on tags making them look like
      // disabled (https://warrenpay.atlassian.net/browse/DEV-980)
      background-color: transparent;
      cursor: default;
      line-height: $line-height--value;
      margin-right: $spacing--8;
      z-index: 1;

      &--is-disabled {
        margin-right: $spacing--4;

        .Select__multi-value__remove {
          display: none;
        }

        .base-text {
          color: $grey--dark !important;
          font-weight: $regular !important;
        }

        // We want values in the disabled multi select to be comma separated. Since React Select does not offer
        // an easy way to add to the element, we have one of two options:
        // a) map over the values in the component and add comma if input is disabled
        // b) add comma with CSS pseudo element
        //
        // Adding the comma with CSS is, although not the most semantically correct, an easier way to achieve this. We
        // use :nth-last-child(2) selector here since the last child of this container is the actual input field, to
        // which we most certainly do not want to add comma, and the second to last child is the last of selected values,
        // which again, must not have a trailing comma added
        &:not(:nth-last-child(2)) {
          .base-text::after {
            content: ','
          }
        }
      }
    }
  }
}

.grouped-selects {
  width: 100%;

  .select-wrapper:not(:first-child) {
    margin-top: $spacing--12;
  }
}

.grouped-selects--error,
.select-alt--error {

  .Select {
    @include formControlErrorBorders();
  }
}
