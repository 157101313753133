@import 'css/base/spacing.scss';
@import 'css/base/table.scss';
@import 'css/mixins/borders.scss';
@import 'css/mixins/flexbox.scss';
@import 'css/mixins/includeMedia.scss';


$border--radius: 8px;
$border--default: $table-border--outer;


.table-pagination {
  @include flexbox();
  @include align-items(center);
  @include flex-direction(column);
  @include justify-content(center);

  background-color: $blue--xx-light;
  border: $border--default;
  border-bottom-left-radius: $border--radius;
  border-bottom-right-radius: $border--radius;
  flex-shrink: 0;
  flex-wrap: wrap;
  row-gap: $spacing--16;
  padding: $spacing--12;
  min-height: $table-pagination--height;
  width: 100%;

  hr {
    width: 100%;
    height: 0;
    border-bottom: 1px solid var(--color-grey20);
    @include media(">=tablet") {
      display: none;
    }
  }
  @include media(">=phone_large") {
    @include flex-direction(row-reverse);
  }
  @include media(">=tablet") {
    @include justify-content(space-between);
  }

  .tlp--page-size {
    @include justify-content(center);
  }

  .tlp--page-nav {
    @include justify-content(flex-end);
  }

  .tlp--quick-jumper {
    @include media(">=tablet") {
      @include justify-content(flex-end);
      flex-grow: 1;
    }
  }


  .tlp--control-left,
  .tlp--control-right {
    @include flexbox();
    @include align-items(center);
    @include flex-wrap(wrap);
    @include justify-content(center);
    gap: $spacing--16 $spacing--24;
    width: 100%;

    @include media(">=phone_large") {
      @include justify-content(space-between);
    }

    @include media(">=tablet") {
      @include justify-content(center);
      width: auto;
    }
  }

}
