@import "css/base/spacing.scss";
@import "css/base/typography.scss";
@import "css/mixins/flexbox.scss";


.external-step-hint {
  @include flexbox();
  @include align-items(center);

  .base-text {
    letter-spacing: 0;
  }

  svg {
    margin-right: $spacing--8;
  }
}
