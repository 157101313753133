@import '../../global/css/variables/global.scss';


.rtb-icon {
  display: inline-block;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  height: 14px;
  width: 14px;

  &.chip-icon {
    vertical-align: text-bottom;
  }

  &.hero-icon {
    height: 200px;
    width: 200px;
  }

  &.error {
    background-image: url('#{$s3-path}/images/errors/error-generic.svg');
  }

  &.integration-logo--xero {
    background-image: url('#{$s3-path}/images/_global/integrations/xero.svg');
  }

  &.integration-logo--qbo {
    background-image: url('#{$s3-path}/images/_global/integrations/qbo.svg');
  }
}

/* styles that only apply to the interactive icon */
button.rtb-icon {
  border: 0;
  outline: none;
  box-shadow: none;
  background-color: transparent;
  cursor: pointer;

  &:active, &:focus {
    border: 0;
    outline: none;
    box-shadow: none;
  }
}
