@import 'css/mixins/flexbox.scss';


.balance-error {
  @include flex-col-center();
  height: 100%;
}

.balance-error--amount-block {
  @include align-self(center);
  width: 320px;
}
