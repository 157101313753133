@import 'css/base/color.scss';
@import 'css/mixins/flexbox.scss';
@import 'css/mixins/includeMedia.scss';
@import 'css/base/margin.scss';
@import 'css/base/padding.scss';
@import 'css/base/typography.scss';
@import 'css/variables/global.scss';


/* Branded footer variables */
$brand-footer--height: 30px;


.brandedV2--container {
  background-color: $blue--x-light;
  height: 100%;
  min-height: 100vh;

  .branded--contents {
    height: unset !important;
  }

  .brandedV2--logo-container {
    @include flexbox();
    @include align-items(center);
    @include justify-content(center);
    padding-top: 30px;
  }
}

.brandedV2--footer-container{
  @include flexbox();
  @include align-items(center);
  @include justify-content(center);
}

.brandedV2--footer {
  bottom: $brand-footer--height;

  height: $external-progress-bar--height;

  &__nav-container {
    @include flexbox();
    width: 450px;
    height: $brand-footer--height;
  }

  &__nav-links {
    @include flexbox();
    @include align-items(center);
    @include justify-content(space-evenly);
    @include flex-grow(1);

    a:link,
    a:visited,
    a:hover,
    a:active,
    li {
      color: $grey--x-dark;
      font-size: $font-xs;

      @include media("<phone_large") {
        max-width: min-content;
      }
    }
  }
}

.dashboard-notification-bar--brand-page {
  .notification--body {
    .base-text {
      font-size: $font-sm !important;
      font-weight: $bold !important;
    }
  }
}
