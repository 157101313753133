@import 'css/base/color.scss';
@import 'css/base/margin.scss';
@import 'css/base/typography.scss';
@import 'css/mixins/flexbox.scss';


a.tax-help-docs-button {
  @include flexbox();
  @include align-items(center);
  text-decoration: none;

  &:hover {
    text-decoration: none;
    background-color: $blue--xx-light;
  }

  > .heading-title {
    color: $blue--primary;
    font-size: $font-sm;
    font-weight: $bold;
  }
}

.tax-help-docs--arrow-container {
  @include flex-grow(2);
  margin-right: $margin--m-large;
  text-align: end;
  // needed for to move the top down to align with the button text
  position: relative;
  top: $margin--xs;
}
