@import 'css/base/color.scss';
@import 'css/base/typography.scss';


.submodule-container {
  display: flex;
  flex-direction: column;
  background-color: white;
  max-width: 50rem;
  height: 100%;

  .form-container {
    display: flex;
    flex-direction: column;
    padding: 2.875rem 6.25rem;
    flex-grow: 1;
    height: 100vh;

    form {
      flex-grow: 1;
    }

    h2 {
      font-size: 2.5rem;
      line-height: 1;
    }

    .section-header,
    .tos,
    #button-control {
      margin-top: 2.188rem;
    }

    .section-header {
      margin-bottom: 0.25rem;
    }
  }
}


