@import 'css/base/typography.scss';


.base-text {
  font-family: $base-font-family !important;
  letter-spacing: 0;
  line-height: $paragraph;

  a:not(.primary) {
    color: inherit;
  }
}
