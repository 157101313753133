@import './spacing.scss';


$padding--xs: $spacing--2;
$padding--sm: $spacing--4;
$padding--xm: $spacing--8;
$padding--m: $spacing--12;
$padding--m-large: $spacing--16;
$padding--large: $spacing--24;
$padding--larger: $spacing--32;
$padding--x-large: $spacing--40;
$padding--xx-large: $spacing--60;
$padding--xxx-large: $spacing--120;

$padding--form-control-m: 10px;
$padding--form-control-l: 14px;

$padding--checkbox: 30px;

$settings-section-padding: 24px;
$settings-section-padding-xl: $settings-section-padding * 2;

$offset-scroll-segment-padding: 18px;

