@import 'css/base/block.scss';
@import 'css/variables/global.scss';


@mixin borderNone($side) {
  border-#{$side}: none;
}

@mixin borderRadii($size, $sides...) {
  @each $side in $sides {
    border-#{$side}-radius: $size;
  }
}

@mixin formControlBorders() {
  border: $border--blue-light;
  border-radius: $block-border-radius--xm;
}

@mixin formControlHoverBorders() {
  border: 1px solid darken($grey--20, 04%);
}

@mixin formControlErrorBorders() {
  border: 1px solid $red--bold;
}
