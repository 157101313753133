@import '../../global/css/variables/global.scss';
@import '../../global/css/base/margin.scss';
@import '../../global/css/base/typography.scss';
@import '../../global/css/mixins/mediaQuery.scss';


.brand--nav {
  height: $nav--height;
  max-width: $branded-page--max-width;
  width: 100%;
  margin: 0 auto;
  padding-left: $margin--large;
  padding-right: $margin--large;

  @include media($SCREEN:(_SMALL)) {
    display: table;
  }

  & > div {
    &.nav--content-left {
      display: table-cell;
      width: 20%;
      vertical-align: middle;
    }

    &.nav--content-right {
      display: table-cell;
      width: 80%;
      vertical-align: middle;

      & > ul.nav--links {
        float: right;
        line-height: $nav--height - 2px;

        & > li {
          display: inline-block;
          line-height: $font-sm;
          vertical-align: middle;

          span.nav--links-description {
            display: none;

            @include media($SCREEN:(_SMALL)) {
              display: inline;
            }
          }

          span.icon-ic-chevron-sharp {
            font-size: 6px;
          }

          &:not(:last-child) {
            margin-right: $margin--large;
          }

          .dd-menu-items {
            margin-top: 30px !important;
          }
        }
      }
    }
  }
}
