@import "css/base/margin.scss";
@import "css/mixins/includeMedia.scss";


.partner-company-type--wrapper {
  &.stacked {
    margin-bottom: $margin--large;
    flex-direction: column;

    .partner-company-type--business-radio {
      margin-bottom: $margin--m;
    }
  }

  &.split {
    margin-bottom: $margin--larger;

    .partner-company-type--individual-radio {
      @include media(">=tablet") {
        margin-left: 1.3%;
      }
    }

    .partner-company-type--business-radio {
      @include media("<=tablet") {
        margin-bottom: $margin--m;
      }
    }
  }
}
