@import 'css/base/color.scss';


.message-support-btn {
  &:hover {
    span {
      color: $blue--primary !important;
    }
  }
}
