@import 'css/base/color.scss';
@import 'css/base/margin.scss';
@import 'css/mixins/flexbox.scss';


.external-receipt-price-row {
  &__left {
    & > .base-text {
      margin-right: $margin--xm;
    }

    & > div > span {
      @include flexbox();
      @include align-items(center);
    }

    &__icon {
      &:hover {
        cursor: pointer;
        fill: $grey--x-dark !important;
      }
    }
  }
}
