@import 'css/base/color.scss';
@import 'css/base/margin.scss';
@import 'css/base/typography.scss';

.dropzone {
  &__container {
    height: inherit;
    position: relative;
    overflow: hidden;
  }

  // change the react drop zone style
  &__available {
    position: relative;
    overflow: hidden;
    display: block;
    border: 1px dashed var(--color-blue40);
    width: calc(100% - 10px);
    border-radius: 6px;
    height: calc(100% - 34px);
    left: 10px;
    top: 24px;

    background-color: rgba(var(--color-blue00), 0);

    transition: background-color 0.3s;

    &.dropzone__active {
      border: 1px solid var(--color-blue40);
      background-color: var(--color-grey20);
    }
  }

  &__dashed_overlay {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    transition: opacity 0.3s;

    &--invisible {
      opacity: 0;
      pointer-events: none;
    }

    &--visible,
    &--dragging-active {
      opacity: 1;
      cursor: pointer;
    }

    &--dragging-active {
      padding: 30px 15px;
      background-color: var(--color-grey05);

      & > .dropzone-overlay-view {
        border: 1px solid var(--color-blue40);
        background-color: var(--color-grey20);
        border-radius: 6px;
      }
    }
  }

  &__rejected_files {
    padding: 10px;
    background-color: $red--light;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;

    &_text {
      color: $grey--x-dark;
      font-weight: $semibold;
      font-size: $font-regular;
    }
  }
}

.inbox-table-dropzone {
  .dropzone__dashed_overlay--dragging-active {
    opacity: 0.7;
    padding: 12px 10px;
    z-index: 999;

    & > .dropzone-overlay-view {
      background-color: var(--color-blue00);
    }
  }
}

.drop-only-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;

  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  // helps with readability when there is other text in the background
  & > span,
  & > svg {
    background-color: var(--color-blue00);
  }

  & > span {
    padding: 0 2px;
  }
}
