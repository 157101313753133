@import '../base/color.scss';

/* Server paths */
$s3-path: "https://s3-us-west-2.amazonaws.com/routable";

/* Form variables */
$form-height-sm: 30px;
$form-height-m: 36px;
$form-top-label-height: 42px;
$form-height-l: 46px;
$form-height-xl: 58px;
$form-height-xxl: 116px;

$form-radio-size: 18px;
$form-radio-size-with-margin: 22px;

$form-options-control-height-xl: 50px;

$form-width-full: 100%;
$form-width-m: 50%;
$form-width-sm: 24.5%;
$form-width-xs: 20%;
$form-width-third: 32.9%;
$form-width-two-third: 65.8%;
$form-width-three-quarters: 72.3%;
$form-control-max-width-standard: 70%;

$ledger-invoice-control-left-width: 60%;

$form-padding: 12px;
$form-margin: 14px;

/* Global variables */
$toolbar-height: 58px;
$hero-background: linear-gradient(180deg, rgba(69,86,243,0.95) 0%, rgba(119,155,251,0.75) 100%) repeat scroll 0 0;
$nav--height: 62px;
$nav--inline-height: 48px;
$footer--height: 56px;
$marketing-footer--height: 280px;
$info-card-section-height: 55px;

/* Branded variables */
$branded-page--heading-family: 'Inter', serif;
$branded-page--inquiry-form-width: 400px;
$branded-page--max-width: 1024px;
$branded-page--padding: 24px;
$branded-page--padding-l: 2 * $branded-page--padding;
$branded-page--padding-xl: 3 * $branded-page--padding;
$branded-page--width: calc(#{$branded-page--max-width} - (2 * #{$branded-page--padding}));

/* Dashboard variables */
$action-nav--border: 1px solid var(--color-grey20);
$sidebar--width: 232px;
$sidebar--background-color: $blue--bold;
$sidebar--border: 1px solid rgba(255, 255, 255, 0.1);
$action-nav-esc: 50px;
$action-footer-height: 69px;
$company-nav--height: 50px;
$company-view--height: 113px;
$company-view--height__tablet: 117px;
$header-height: 38px;
$pagination-bar--height: 56px;
$scrollbar--height: 6px;
$scrollbar--width: 6px;
$table-pagination--height: 57px;
$thread-header--height: 70px;
$thread-info--width: 380px;

/* Content sizes */
$full-screen-modal-content-max: 1080px;

/* External variables */
$external-progress-bar--height: 62px;
$external-receipt--width: 442px;
$external-page--max-width: 1024px;
$external-footer--height: 56px;

/* Bank connections */
$bank-connections--max-width: 650px;

/* Mobile spacing */
$mobile-side-gutter--small: 15px;

/* Borders */
$border--blue-bold: 1px solid $blue--bold;
$border--aqua-bold: 1px solid $aqua--bold;
$border--inset-content: 1px solid $blue--x-light;
$border--grey-x-light: 1px solid $grey--x-light;
$border--grey-med: 1px solid $grey--med;
$border--blue-light: 1px solid var(--color-grey20);


// noinspection CssUnknownProperty,CssInvalidPseudoSelector
:export {
  threadInfoWidth: $thread-info--width;
}
