@import '../../../global/css/base/color.scss';
@import '../../../global/css/base/margin.scss';
@import '../../../global/css/base/typography.scss';


.address-container {
  margin-bottom: 0;

  & > .address-component {
    display: block;
    color: $grey--x-dark;
    font-weight: $semibold;
    font-size: $font-regular;
    line-height: $paragraph;
  }
}
