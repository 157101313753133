@import 'css/base/color.scss';
@import 'css/base/typography.scss';


.contact-required-to-select-payment-hint {
  > a {
    color: $blue--bold;
    font-size: $font-sm;
    font-weight: $regular;

    &:hover {
      color: $primary;
      cursor: pointer;
      text-decoration: none;
    }

    &:visited {
      color: $blue--bold;
    }
  }
}
