@import '../mixins/flexbox.scss';


.align-items--center {
  @include align-items(center);
}

.align-items--flex-end {
  @include align-items(flex-end);
}

.align-items--flex-start {
  @include align-items(flex-start);
}

.align-self--center {
  @include align-self(center);
}

.align-self--flex-end {
  @include align-self(flex-end);
}

.flex-direction--column {
  @include flex-direction(column);
}

.flex-direction--column-reverse {
  @include flex-direction(column-reverse);
}

.flex-direction--row {
  @include flex-direction(row);
}

.flex-direction--row-reverse {
  @include flex-direction(row-reverse);
}

.flex-shrink--zero {
  @include flex-shrink(0);
}

.justify-content--flex-start {
  @include justify-content(flex-start);

  &--important {
    @include justify-content--important(flex-start);
  }
}

.justify-content--center {
  @include justify-content(center);
}

.justify-content--space-between {
  @include justify-content(space-between);
}

.justify-content--flex-end {
  @include justify-content(flex-end);

  &--important {
    @include justify-content--important(flex-end);
  }
}

.vertical-align--middle {
  vertical-align: middle;
}

.vertical-align--text-top {
  vertical-align: text-top;
}

.vertical-align--bottom {
  vertical-align: bottom;
}

.text-align--left {
  text-align: left;
}

.text-align--right {
  text-align: right;
}
