@import 'css/base/color.scss';
@import 'css/base/spacing.scss';
@import 'css/base/transition.scss';
@import 'css/base/typography.scss';
@import 'css/mixins/flexbox.scss';


.powered-by-wordmark {
  @include flexbox();
  @include align-items(center);

  &__logo {
    color: $white;
    background-clip: padding-box;
    background-color: $blue--bold;
    border-radius: 50%;
    font-size: $font-xs;
    margin-right: $spacing--8;
    padding: $spacing--8;
    transition: transform $transition-timing--m ease-in-out;
  }

  &__text {
    font-size: $font-xs;
    transition: color $transition-timing--m ease-in-out;
  }

  &:hover & {
    &__logo {
      // We want to increase the "R" logo mark for ~3-4px on hover
      // Original size is 28px, so if we multiply that size by 1.1
      // we get a value around ~31px, which is around 3px larger.
      transform: scale(1.1);
    }

    &__text {
      color: $blue--bold !important;
    }
  }
}
