@import 'css/base/padding.scss';
@import 'css/mixins/includeMedia.scss';


.external-receipt-payment-full-page-modal {
  &__footer {
    @include media($mobileOnly) {
      padding: $padding--m $padding--m-large !important;
    }
  }


  .modal-footer--button {
    @include media($mobileOnly) {
      margin: 0 !important;
    }
  }
}