@import 'css/base/color.scss';
@import 'css/base/padding.scss';
@import 'css/mixins/flexbox.scss';
@import 'css/mixins/includeMedia.scss';
@import 'css/variables/modal.scss';
@import 'css/variables/sizes.scss';


.slide-main {
  @include flexbox();
  @include flex-flow(column nowrap);

  height: 100vh;
  width: $side-panel-width-medium;

  &.large {
    width: $side-panel-width-large;
  }

  @media(max-width: 560px) {
    width: 100vw;
  }

  .slide-body {
    @include flexbox();
    @include flex-flow(column nowrap);

    background: $blue--xx-light;
    height: 100%;
    overflow-x: hidden;
  }

  .form,
  .slide-body--content {
    height: 100%;
    overflow-y: auto;
    padding: $padding--large;
    width: 100%;
  }

  .modal-footer {
    background-color: $blue--x-light;
    border-radius: 0 0 $modal-border-radius $modal-border-radius;
    border-top: 1px solid var(--color-grey20);
    padding: $padding--m $padding--large;
    width: 100%;
  }
}
