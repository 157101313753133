@import '../../../../../global/css/base/color.scss';
@import '../../../../../global/css/base/margin.scss';
@import '../../../../../global/css/base/padding.scss';
@import '../../../../../global/css/base/typography.scss';
@import '../../../../../global/css/mixins/flexbox.scss';
@import '../../../../../global/css/mixins/includeMedia.scss';

.guide-section {
  margin-bottom: $margin--large;

  background-color: $white;
  border: $border--blue-light;
  border-radius: 6px;
  box-shadow: -1px 2px 0 0 $blue--x-light;

  &:hover {
    border: 1px solid $blue--primary;
  }

  &.section-complete {
    background-color: darken($blue--xx-light, 2%);
  }

  &.section-disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  &.outside-guide {
    margin-bottom: 0;

    background-color: $blue--xx-light;
    border: inherit;
    border-bottom: 2px solid var(--color-grey20);
    border-radius: 0;
    box-shadow: none;

    .guide-section--row {
      padding-left: 30px;
      padding-right: 30px;

      &.section-complete {
        padding-top: $padding--large;
        padding-bottom: $padding--large;
      }

      &.section-failed {
        display: none;
      }
    }

    &:hover {
      border: inherit;
      border-bottom: 2px solid var(--color-grey20);
    }
  }
}

.guide-section--row {
  @include flexbox();
  @include align-items(flex-start);
  padding: $padding--m $padding--large $padding--m $padding--m;

  &.section-complete {
    padding-top: $padding--m;
    padding-bottom: $padding--m;
  }

  &.section-failed {
    padding-top: $padding--m;
    padding-bottom: $padding--m;

    background: $red--light;
    border-top: 1px solid var(--color-grey20);
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  .icon-ic-alert {
    display: none;
  }

  border-radius: 6px;

  p {
    line-height: 1.6;
  }

  @include media(">=phone_large") {
    @include align-items(center);
    padding: 16px 14px;

    .icon-ic-alert {
      display: inherit;
    }
  }

  .guide-section--icon {
    align-self: flex-start;
    width: 28px;
    margin-bottom: 0;
    padding-top: 1px;

    color: var(--color-grey20);
    font-size: 18px;

    &.section-complete {
      color: $green--bold;
    }
  }

  .guide-section--description {
    @include flexbox();
    @include flex-flow(column);
    width: 100%;

    @include media(">=phone_large") {
      @include flex-flow(row);
      @include align-items(center);
    }
  }

  .guide-section--text {
    &.right {
      margin-top: $margin--m;
      padding: 0;
    }

    @include media(">=phone_large") {
      &.right {
        margin-left: auto;
        margin-top: 0;
        padding-left: 14px;
      }
    }
  }
}
