@import '../base/color.scss';
@import '../mixins/flexbox.scss';
@import '../base/typography.scss';


.logo-container {
  @include flexbox();
  @include flex-direction(row);

  height: 32px;
  width: max-content;
}
