@import 'css/base/color.scss';
@import 'css/base/padding.scss';
@import 'css/variables/global.scss';


.panel-nav {
  padding: 0 $padding--large;
  height: $nav--height;
  width: 100%;
  border-bottom: 1px solid var(--color-grey20);
  background-color: $white;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .panel-tab {
    float: left;
    color: $grey--x-dark;
    line-height: $nav--height;

    &.panel-tab--selected {
      pointer-events: none;

      &:after {
        content: '';
        display: block;
        border-bottom: 3px solid $blue--primary;
        margin-top: -3px;
      }
    }
  }
}
