@import '../../../../global/css/base/color.scss';
@import '../../../../global/css/base/padding.scss';
@import '../../../../global/css/base/typography.scss';
@import '../../../../global/css/mixins/flexbox.scss';
@import '../../../../global/css/mixins/includeMedia.scss';
@import '../../../../global/css/variables/global.scss';
@import '../../../../global/css/variables/width.scss';

$external-modal--min-height: calc(100vh - #{$external-footer--height});

.external-onboarding-form--container {
  @include flexbox();
  @include flex-flow(row nowrap);
  @include justify-content(space-between);

  min-height: $external-modal--min-height;

  & > .content--left {
    @include flexbox();
    @include flex-flow(column nowrap);
    @include justify-content(space-between);

    background-color: $blue--xx-light;
    padding-top: $padding--large;
    width: 100%;

    .partner-company-form--wrapper {
      @include flexbox();
      @include flex-flow(row nowrap);
      @include align-items(center);
      @include justify-content(center);

      margin: auto 0;
      padding: 0 $padding--large;
    }

    .partner-company-form--container {
      margin: auto;
      max-width: $content-width--small;
      width: 100%;
      overflow-y: unset;
    }
  }

  & > .content--right {
    @include flexbox();
    @include flex-flow(column nowrap);
    @include align-items(space-between);
    @include justify-content(center);

    min-height: $external-modal--min-height;

    @include media("<=tablet_large") {
      display: none;
    }

    padding: $padding--x-large $padding--x-large 0 $padding--x-large;
    border-left: 1px solid var(--color-grey20);
    background-color: $blue--x-light;

    .item-id--container {
      display: table;
      padding: 10px;
      background-color: $white;
      text-align: center;
      border: 1px solid var(--color-grey20);
      border-bottom: none;
      border-radius: 11px 11px 0 0;
      width: 100%;
      margin-top: 28px;

      span {
        display: table-cell;
        text-align: left;
        color: $dark-jordan;
        font-size: 11px;
        font-weight: $semibold;

        &.item-id {
          text-align: right;
          width: 100px;
        }
      }
    }
  }
}
