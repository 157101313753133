@import 'css/base/color.scss';
@import 'css/base/margin.scss';
@import 'css/base/padding.scss';
@import 'css/base/sizes.scss';
@import 'css/base/typography.scss';

@import 'css/mixins/flexbox.scss';
@import 'css/mixins/includeMedia.scss';

@import 'css/variables/width.scss';


$header-title-line-height: 44px;
$header-title-font-size: 36px;
$body-line-height: 24px;
$button-width: 325px;
$column-padding-top: 96px;
$prompt-left-col-max-width: 460px;
$prompt-left-col-min-width: 343px;
$prompt-right-col-left-margin: 80px;
$sixty-forty-col-max-width: 554px;
$sixty-forty-col-min-width: 375px;
$sixty-forty-header-title-line-height: 36px;
$sixty-forty-header-title-font-size: 24px;


.external-block {
  .external-column--components-container {
    .external-components--component-wrapper {
      margin-bottom: $margin--larger;
    }
  }

  &.full-width,
  &.prompt,
  &.sixty-forty {
    @include justify-content(space-between);

    padding: $padding--m-large;

    @include media($tabletOrLarger) {
      &:first-child {
        padding-top: $column-padding-top;
      }
    }

    &:not(:first-child) {
      border-top: 1px solid $grey--x-light;
    }

    .external-column {
      padding: $padding--m $padding--sm;

      @include media($tabletOrLarger) {
        padding: 0 $padding--xm;
      }

      &--header-components {
        margin-bottom: $margin--m;
      }

      &--header-title {
        font-weight: $semibold;
        font-size: $header-title-font-size;
        line-height: $header-title-line-height;
        color: $black;
        margin-bottom: $margin--m;
      }

      &--body {
        font-weight: $regular;
        font-size: $font-m;
        line-height: $body-line-height;
        color: $grey--xx-dark;
        margin-bottom: $margin--larger;
        white-space: pre-line;
      }

      .external-button {
        @include media($tabletOrLarger) {
          width: $button-width;
        }

        @include justify-content(start);
        span {
          flex-grow: 1;
          text-align: left;
        }
      }
    }
  }

  &.full-width {
    @include flex-wrap(wrap);

    .external-column {
      @include flex-grow(1);
      width: 100%;
    }
  }

  &.prompt {
    .col-1 {
      @include media($tabletOrLarger) {
        max-width: $prompt-left-col-max-width;
        min-width: $prompt-left-col-min-width;
      }
    }

    .col-2 {
      @include justify-content(center);
    }

    @include media($tabletOrLarger) {
      .col-2 {
        margin-left: $prompt-right-col-left-margin;
      }
    }
  }

  &.sixty-forty {
    .external-column {
      &--header-title {
        font-size: $sixty-forty-header-title-font-size;
        line-height: $sixty-forty-header-title-line-height;
        margin-bottom: $margin--xm;
      }

      &.col-1 {
        width: 100%;
        max-width: $sixty-forty-col-max-width;
        min-width: $sixty-forty-col-min-width;
        @include media($mobileOnly) {
          // On Mobile, padding doesn't apply properly because min-width is too large.
          // This causes forms to be too close to the edge of screen.
          min-width: unset;
        }
      }
    }
  }

  &.full-content {
    margin: auto;
    height: calc(100vh - #{$footer--height} - #{$nav--height});
    max-width: $content-width--x-small;
    text-align: center;

    &.stack-on-mobile {
      flex-direction: row;
    }

    .external-column {
      justify-content: center;

      &--header-title {
          color: $black;
          font-size: $sixty-forty-header-title-font-size;
          font-weight: $semibold;
          line-height: $line-height--xxxl;
          margin-top: $margin--m-large;
          margin-bottom: $margin--xm;
      }

      &--body {
          color: $grey--xx-dark;
          font-size: $font-m;
          font-weight: $regular;
          line-height: $body-line-height;
      }
    }
  }
}
