@import 'css/base/color.scss';
@import 'css/base/margin.scss';
@import 'css/base/block.scss';
@import 'css/base/padding.scss';
@import 'css/base/typography.scss';
@import 'css/mixins/flexbox.scss';

.hint {
  @include flexbox();
  @include flex-flow(row nowrap);
  @include align-items(flex-start);
  @include justify-content(flex-start);

  padding: $padding--m-large;
  width: 100%;
  border-radius: $block-border-radius--m;

  .chevron-icon {
    margin-top: $spacing--3;
    transition: transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;

    &.is-open {
      -webkit-transform: rotate(90deg);
      -ms-transform: rotate(90deg);
      transform: rotate(90deg)
    }
  }

  // Body
  .hint--body {
    @include flexbox();
    @include flex-flow(column nowrap);
    @include align-items(flex-start);
    @include justify-content(flex-start);
  }

  // Text
  .hint--text,
  .hint--content {
    color: $black;
    font-size: $font-sm;
    font-weight: $regular;
    line-height: $paragraph;
    // account for the hint svg in the title and its padding
    padding-right: $spacing--24;

    &.dark {
      color: $black;
    }
  }


  .hint--title {
    color: $black;
    font-size: $font-sm;
    font-weight: $bold;
    line-height: $paragraph;
    margin-bottom: 0;

    &--container {
      @include flexbox();
      @include align-items(flex-start);
      @include justify-content(space-between);

      width: 100%;

      + .hint--text {
        margin-top: $spacing--4;
      }
    }
  }

  // Padding
  &.multiline {
    padding: $padding--m-large;
  }

  // Hint colors
  &.danger {
    background-color: $red--light;
  }

  &.info {
    background-color: $blue--x-light;
  }

  &.schedule {
    background-color: $extra-light-purple;
  }

  &.success {
    background-color: $aqua--light;
  }

  &.warning {
    background-color: $yellow--light;
    .hint--title {
      color: $yellow--dark;
    }
  }

  &.neutral {
    background-color: $blue--xx-light;
  }

  &.light {
    background-color: $grey--xx-light;
  }

  &.remove-background-color {
    background-color: $white;
  }
}

.hint-field {
  &.has-error {
    .checkbox .control-indicator {
      border-color: $red--bold;
    }
  }
}

.switch-delivery-option--btn {
  width: 245px;
}
