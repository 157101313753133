@import '../base/color.scss';
@import '../base/typography.scss';
@import '../mixins/text.scss';


a {
  font-family: $base-font-family;
  font-weight: $semibold;
  letter-spacing: 0.008em;
  cursor: auto;
  text-decoration: none;

  &:hover,
  &:focus {
    text-decoration: underline;
    cursor: pointer !important;
    pointer-events: visible;

    &.hover-primary {
      color: $primary;

      .link {
        color: $primary;
      }
    }
  }

  &.force-underline {
    text-decoration: underline !important;
  }

  &.remove-hover-underline {
    &:hover,
    &:focus {
      text-decoration: none !important;
    }

    &.hover-primary {
      &:hover,
      &:focus {
        color: $primary;

        svg {
          fill: $primary !important;
        }
      }
    }

    &.hover-bold {
      &:hover,
      &:focus {
        color: $blue--bold;

        svg {
          fill: $blue--bold !important;
        }
      }
    }

    &.hover-dark {
      &:hover,
      &:focus {
        color: darken($blue--bold, 20%);

        svg {
          fill: darken($blue--bold, 20%) !important;
        }
      }
    }

    &.hover-negative {
      &:hover,
      &:focus {
        color: $negative !important;

        svg {
          fill: $negative !important;
        }
      }
    }
  }

  &.disabled {
   pointer-events: none;
   cursor: default;
   opacity: 0.5;
  }

  &.truncate {
    @include truncate();
  }

  &.a-margin--left {
    margin-left: 16px;

    & > span {
      margin-right: 10px;
    }
  }

  &.a--xs {
    line-height: 18px;

    font-size: $font-regular;

    & > span {
      vertical-align: middle;
      line-height: 18px;

      &[class^="icon-ic-"] {
        position: relative;
        bottom: 1px;
        margin-right: 8px;
        opacity: 0.8;

        font-size: 9px;

        &.icon-ic-message, &.icon-ic-refresh {
          font-size: $font-m;
        }

        &.icon-ic-plus-full {
          font-size: 15px;
        }

        &.icon-ic-x-out {
          font-weight: $bold;
        }

        &.icon-flip-180 {
          font-size: 10px;
        }
      }
    }
  }

  &.is-inline-input {
    position: relative;
    float: right;
    margin: 20px 16px 0 16px;
  }

  &.a--sm {
    font-size: $font-sm;
  }
  &.a--m {
    font-size: $font-m;
  }
  &.a--l {
    font-size: $font-l;
    font-family: $alt-font-family !important;
    font-weight: $bold;
  }
  &.a--xl {
    font-size: $font-xl;
  }
  &.brand {
    font-family: $brand-font-family;
    font-weight: bold;
  }
  &.dark {
    color: $grey--xx-dark;
  }
  &.dark-grey {
    color: $dark-jordan;
  }
  &.dark-blue {
    color: $blue--bold;
  }
  &.white {
    color:$white;
  }
  &.primary {
    color:$primary;

    &.only-hover {
      color: inherit;

      &:hover,
      &:focus {
        color: $primary;
      }
    }
  }
  &.positive {
    color:$positive;
  }
  &.negative {
    color:$negative;
  }
  &.scheduled {
    color:$scheduled;
  }
  &.light-grey {
    color: $main-jordan
  }
  &.xero {
    color: $xero;
  }
  &.qbo {
    color: $qbo;
  }
}
