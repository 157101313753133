@import 'css/base/margin.scss';

.badge__safe-and-secure {
  display: inline-flex;
  align-items: center;

  svg {
    margin-right: $margin--xm;
  }
}
