@import "css/mixins/flexbox.scss";
@import "css/base/margin.scss";
@import "css/base/color.scss";
@import "css/base/typography.scss";

.optional-sso-login {
  .optional-sso-login--divider {
    @include flexbox();
    @include align-items(center);
    text-align: center;
    margin-top: $margin--large;
    margin-bottom: $margin--large;

    &:before,
    &:after {
      content: "";
      @include flex(1);
      border-bottom: 2px solid var(--color-grey20);
    }

    &__text {
      color: $grey--dark;
      font-family: $base-font-family;
      font-size: $font-xs;
      font-weight: $bold;
      margin-left: $margin--xm;
      margin-right: $margin--xm;
    }
  }

  .change-workspace-btn {
    display: block;
    font-weight: $semibold;
    color: $blue--bold !important;
  }
}