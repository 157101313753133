@import 'css/base/color.scss';
@import 'css/base/margin.scss';
@import 'css/base/typography.scss';


.action-nav-search-placeholder {
  font-size: $font-sm;
  font-weight: $bold;

  & > .action-nav-search-placeholder--search {
    color: $grey--x-dark;
  }

  & > .action-nav-search-placeholder--placeholder {
    color: $dark-jordan;
    margin-left: $margin--sm;
    text-transform: capitalize;
  }
}
