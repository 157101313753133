//===============Default screen map
//Add and remove screen widths to fit your needs
$SCREENS:(
  EXTRA_SMALL: 321px,
  _SMALL: 480px,
  TABLET_SMALL: 600px,
  TABLET: 768px,
  TABLET_LARGE: 1024px,
  LAPTOP: 1280px,
  LAPTOP_LARGE: 1440px,
  MONITOR: 2100px, 
)!default;

//===============General Media Query Mixin
@mixin media($SCREEN){
  @if map-has-key($SCREENS, $SCREEN){
    @media only screen
      and (min-width: map-get($SCREENS, $SCREEN)){
      @content;
    }
  }
}
