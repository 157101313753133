@import 'css/base/block.scss';
@import 'css/base/color.scss';
@import 'css/base/padding.scss';
@import 'css/base/table.scss';
@import 'css/base/typography.scss';
@import 'css/mixins/flexbox.scss';
@import 'css/mixins/includeMedia.scss';
@import 'css/variables/global.scss';


.info-card {
  background-color: $white;
  border: $border--inset-content;
  border-radius: $table-border-radius;
  box-sizing: border-box;
  box-shadow: $content-block-box-shadow;

  &.constrain-width {
    width: 400px;

    @include media("<=tablet_small") {
      max-width: 80vw;
    }
  }

  &.without-box-shadow {
    box-shadow: none;
  }

  & .info-card--header {
    color: $grey--xx-dark;
    font-size: $font-m;
    font-weight: $bold;
    text-align: center;
  }


  & .info-card--top {
    @include flexbox();
    @include flex-direction(column);
    @include justify-content(center);
    @include align-items(center);
    border-bottom: $border--inset-content;
    padding: $padding--large $padding--large * 3;
  }

  & .info-card--bottom {
    @include flexbox();
    @include flex-direction(column);

    > * {
      border-bottom: $border--inset-content;
      min-height: $info-card-section-height;

      &.disclosure-list.open {
        // When border list is open, it normally hides the bottom border
        border-bottom: $border--inset-content;
      }
    }

    & :last-child {
      border-radius: 0 0 10px 10px;
      border-bottom: none;
    }
  }

  .paid-to-date--amount {
    font-size: $font-xl;
    color: $grey--x-dark;
    margin-bottom: $margin--sm,
  };

  .paid-to-date--units {
    color: $main-jordan;
    letter-spacing: $letter-spacing--xs;
    font-size: $font-regular;
    margin: 0;
  };

  .paid-to-date--label {
    @include flexbox();
    @include justify-content(center);
    @include align-items(center);
    background-color: $blue--xx-light;
  }
}
