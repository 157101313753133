@import 'css/base/color.scss';
@import 'css/base/padding.scss';


.action-nav-file-upload {
  position: relative;
  border-radius: 4px;
  background-color: $white;
  transition: background-color 0.2s;

  input {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    opacity: 0;
    width: 100%;
    height: 100%;
  }

  label {
    display: inline-block;
    width: 100%;
    height: 100%;
    cursor: pointer;
    button {
      pointer-events: none;
    }
  }

  a {
    padding: $padding--xm $padding--m;
  }

  &:hover {
    background-color: $blue--x-light;
  }
}
