@import '../base/color.scss';
@import '../base/padding.scss';
@import '../base/typography.scss';
@import '../mixins/zIndex.scss';

.alert-main {
  padding: $padding--xm $padding--xm $padding--xm $padding--m;
  width: 100%;

  font-size: $font-regular;
  color: desaturate($dark-orange, 12%);

  background: $extra-light-yellow;
  border-left: 3px solid $light-orange;
  border-radius: 2px 4px 4px 2px;
  box-shadow: 1px 1px 2px 0 var(--color-grey20);

  span, p {
    font-size: $font-xs;
    font-weight: $semibold;
    line-height: 18px;
  }

  &.large-padding {
    padding: 14px;
  }

  &.note {
    border-left: 4px solid $dark-yellow;

    span, p {
      &.text {
        color: darken($dark-jordan, 8%);
        font-weight: $semibold;
      }

      &.title {
        margin-bottom: 4px;

        color: $grey--x-dark;
        font-weight: $bold;
      }

      &.sub-text {
        color: darken($dark-jordan, 12%);
        font-size: $font-xs;
        font-style: italic;
      }
    }
  }

  & span {
    vertical-align: middle;
  }
}

.inline-alert {
  div.alert {
    display: inline-block;
    width: 6px;
    height: 6px;
    vertical-align: middle;
    margin-right: 6px;

    background-color: $light-orange;
    border-radius: 50px;
  }

  span, p {
    line-height: $font-xs;

    color: darken($dark-jordan, 4%);
    font-size: $font-xs;
    font-style: italic;
  }
}

// Override colors for "danger" type Toaster alert
// Yes, that is a box emoji
.📦color_f36331 {
  color: $red--bold;
}

.css-12r4ua3:before {
  background-color: $red--bold;
}

