@import 'css/base/color.scss';
@import 'css/base/padding.scss';
@import 'css/mixins/includeMedia.scss';
@import "css/mixins/flexbox.scss";
@import 'css/base/typography.scss';

.quickswitch-modal {
  &--content {
    background-color: $white;
    padding: 0 !important;
    margin: 0;
  }

  &--sticky {
    top: 0;
    position: sticky;
    background-color: $white;
    border-bottom: 1px solid var(--color-grey20);
  }

  &--header {
    padding: $padding--large $padding--large  $padding--m;
    &--extra-bottom-padding {
      padding-bottom: $padding--large;
    }
  }


  &--search-box-row {
    padding: 0px $padding--large $padding--large;
  }

  &--footer {
    background-color: $white !important;
  }

  &--empty-state {
    padding: $padding--large 0px;
  }

  &--undo-search {
    color: $blue--bold !important;
    text-decoration: none !important;
  }

  .modal-header {
    @include media($mobileOnly) {
      height:0 !important;
    }
  }
  .modal-body .form {
    @include media($mobileOnly) {
      padding-bottom: 0px !important;
    }
  }
}
