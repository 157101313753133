@import "css/base/color.scss";
@import "css/base/spacing.scss";
@import "css/base/typography.scss";
@import "css/mixins/zIndex.scss";
@import "css/variables/width.scss";

$arrow-before--size: 18px;
$arrow-span--height: 12px; // Math.sqrt(($arrow-before--size^2) * 2) / 2  = 12.727922061357855
$arrow--border-radius: 2px;
$arrow--box-shadow-color: rgba(68, 55, 50, 0.09);

$tooltip--border-radius: 4px;
$tooltip--box-shadow-color: rgba(68, 55, 50, 0.16);

$popper--margin: 6px;
$popper-arrow--margin: 13px;

.tooltip {
    &--body {
        background-color: $white;
        border-radius: $tooltip--border-radius;
        box-shadow: 2px 2px 12px $tooltip--box-shadow-color;
        color: $grey--x-dark;
        max-width: $tooltip-width--medium;

        &__large {
            padding: $spacing--24;
        }

        &__skinny {
            padding: $spacing--12 $spacing--16;
            font-size: $font-xs;
        }

        &__x-skinny {
            padding: $spacing--6 $spacing--12;
        }
    }

    &-max-width--large {
        max-width: $tooltip-width--large;
    }

    &-max-width--100 {
        max-width: 100%;
    }

    &-max-width--none {
        max-width: none;
    }
}

.MuiTooltip-popper {
    // !important must be used to override default styles
    pointer-events: unset !important;
    // Override MUI's z-index default value (1500).
    @include zIndex("TOOLTIP");

    &[x-placement*="bottom"],
    &[x-placement*="top"] {
        .MuiTooltip-tooltip {
            margin: $popper--margin 0;
        }
    }

    &[x-placement*="left"],
    &[x-placement*="right"] {
        .MuiTooltip-tooltip {
            margin: 0 $popper--margin;
        }
    }

    &Arrow {
        &[x-placement*="bottom"],
        &[x-placement*="top"] {
            .MuiTooltip-tooltip {
                margin: $popper-arrow--margin 0;
            }

            .MuiTooltip-arrow {
                height: $arrow-span--height !important;
                &:before {
                    left: 50%;
                }
            }
        }

        &[x-placement*="left"],
        &[x-placement*="right"] {
            .MuiTooltip-tooltip {
                margin: 0 $popper-arrow--margin;
            }

            .MuiTooltip-arrow {
                width: $arrow-span--height !important;
                &:before {
                    top: 50%;
                }
            }
        }

        &[x-placement*="bottom"] {
            .MuiTooltip-arrow {
                margin-top: -$arrow-span--height !important;
                &:before {
                    border-top-left-radius: $arrow--border-radius;
                    top: 100%;
                }
            }
        }

        &[x-placement*="top"] {
            .MuiTooltip-arrow {
                margin-bottom: -$arrow-span--height !important;
                &:before {
                    border-bottom-right-radius: $arrow--border-radius;
                }
            }
        }

        &[x-placement*="right"] {
            .MuiTooltip-arrow {
                margin-left: -$arrow-span--height !important;
                &:before {
                    border-bottom-left-radius: $arrow--border-radius;
                    left: 100%;
                }
            }
        }

        &[x-placement*="left"] {
            .MuiTooltip-arrow {
                margin-right: -$arrow-span--height !important;
                &:before {
                    border-top-right-radius: $arrow--border-radius;
                }
            }
        }
    }
}

.MuiTooltip-tooltip {
    box-sizing: border-box;
    padding: 0 !important;
    max-width: none !important;

    &.MuiTooltip-tooltipArrow {
        background: $white;
    }
}

.MuiTooltip-arrow {
    color: $white !important;
    font-size: $font-sm !important;
    overflow: hidden;

    &:before {
        content: "";
        position: absolute;
        border-style: unset !important;
        width: $arrow-before--size !important;
        height: $arrow-before--size !important;
        background: white;
        transform: translateX(-50%) translateY(-50%) rotate(45deg);
        box-shadow: 1px 1px 5px 0 $arrow--box-shadow-color;
    }
}
