@import 'css/base/color.scss';
@import 'css/base/margin.scss';
@import 'css/base/typography.scss';
@import 'css/mixins/includeMedia.scss';
@import 'css/variables/global.scss';


$link-line-height: 18px;
$bank-options--padding: 16px;
$bank-options--border-radius: 10px;

.box-v2--body {
  .bank-options {
    box-shadow: none !important;
  }
}

.bank-options {
  border: $border--blue-light;
  border-radius: $bank-options--border-radius;
  box-shadow: -2px 3px 1px 0 $blue--x-light;

  @include media('>=tablet_large') {
    max-width: $bank-connections--max-width;
  }

  .bank-options--plaid {
    padding: $bank-options--padding;

    background-color: $white;
    border-top-left-radius: $bank-options--border-radius;
    border-top-right-radius: $bank-options--border-radius;
  }

  .bank-options--manual {
    padding: $bank-options--padding;

    background-color: $blue--x-light;
    border-top: $border--blue-light;
    border-bottom-left-radius: $bank-options--border-radius;
    border-bottom-right-radius: $bank-options--border-radius;
  }
}

.link-manual {
  span {
    vertical-align: middle;
    line-height: $link-line-height;

    font-weight: $semibold;
    font-size: $font-regular;
  }

  .icon-ic-payments-draft {
    margin-right: $margin--sm;

    font-size: 18px;
    color: $main-jordan;

    &.disabled {
      opacity: $disabled-opacity;
    }
  }
}
