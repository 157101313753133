@import 'css/base/color';
@import 'css/base/padding';
@import 'css/mixins/flexbox';


.add-payment-method-dropdown-option {

  &:not(:last-child) {
    border-bottom: 1px solid var(--color-grey20);
  }

  & > a {
    @include flexbox--important();
    @include flex-direction(column);
    @include align-items(flex-start);
  }
}