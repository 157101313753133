@import '../../../../../global/css/base/color.scss';
@import '../../../../../global/css/base/margin.scss';
@import '../../../../../global/css/base/typography.scss';
@import '../../../../../global/css/mixins/flexbox.scss';
@import '../../../../../global/css/mixins/includeMedia.scss';


.external-header--partner-info {
  @include flexbox();
  @include flex-flow(column);
  @include align-items(flex-start);
  pointer-events: none;

  @include media(">=tablet") {
    @include flex-flow(row);
    @include align-items(center);
  }

  .partner-info--logo {
    height: 36px;
  }

  .partner-info--name {
    color: $grey--xx-dark;
    font-weight: $bold;
  }


  span.department--divider {
    display: none;

    @include media(">=tablet") {
      display: inline;
      margin: 0 $margin--m;

      color: var(--color-grey20);
      font-size: $font-l;
      font-weight: $regular;
    }
  }

  span.department--name {
    display: block;
    margin-top: $margin--xm;

    color: $grey--x-dark;
    font-size: $font-regular;
    font-weight: $semibold;

    @include media(">=tablet") {
      display: inline;
      margin-top: 0;
    }
  }
}
