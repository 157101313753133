/* Widths */
$content-width--full: 1024px;
$content-width--medium: 800px;
$content-width--small: 600px;
$content-width--x-small: 400px;

$tooltip-width--medium: 300px;
$tooltip-width--large: 500px;

// noinspection CssUnknownProperty,CssInvalidPseudoSelector
:export {
  contentWidthFull: $content-width--full;
  contentWidthMedium: $content-width--medium;
  contentWidthSmall: $content-width--small;
  tooltipWidthMedium: $tooltip-width--medium;
  tooltipWidthLarge: $tooltip-width--large;
}
