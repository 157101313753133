@import 'css/base/padding.scss';

/**
  a map of padding sizes to their class postfixes.
 */
$postfixSizes: (
  'remove': 0,
  'xs': $padding--xs,
  'sm': $padding--sm,
  'm': $padding--m,
  'xm': $padding--xm,
  'm-large': $padding--m-large,
  'l': $padding--large,
  'xl': $padding--x-large,
  'xxl': $padding--xx-large,
  'xxxl': $padding--xxx-large,
  'form-control--m': $padding--form-control-m,
  'settings': $settings-section-padding,
  'settings-xl': $settings-section-padding-xl,
);

/**
  generates padding class names that can cascade separately from
  element class names and their increasing specificity.

  for each value in $postfixSizes, e.g. 'xs', generates class names
  such as 'padding--xs', 'padding-top--xs', etc, using its mapped
  value ($padding--xs) as the relevant padding value.
 */
@mixin makePaddingSizeClasses($postfix, $size) {
  $classPostfix: '--#{$postfix}';

  .padding#{$classPostfix} {
    padding: $size;
  }

  .padding-top#{$classPostfix} {
    padding-top: $size;
  }

  .padding-right#{$classPostfix} {
    padding-right: $size;
  }

  .padding-bottom#{$classPostfix} {
    padding-bottom: $size;
  }

  .padding-left#{$classPostfix} {
    padding-left: $size;
  }

  .padding#{$classPostfix}--important {
    padding: $size !important;
  }

  .padding-top#{$classPostfix}--important {
    padding-top: $size !important;
  }

  .padding-right#{$classPostfix}--important {
    padding-right: $size !important;
  }

  .padding-bottom#{$classPostfix}--important {
    padding-bottom: $size !important;
  }

  .padding-left#{$classPostfix}--important {
    padding-left: $size !important;
  }
}

@mixin makePaddingPostfixClasses() {
  @each $postfix, $size in $postfixSizes {
    @include makePaddingSizeClasses($postfix, $size);
  }
}

@include makePaddingPostfixClasses();
