@mixin circle($circle-color, $circle-diameter, $circle-text-color) {
  display: table-cell;
  width: $circle-diameter;
  height: $circle-diameter;
  margin: 6px 6px 6px 0;

  background-color: $circle-color;
  border-radius: 50%;

  .aligner {
    display: table;
    width: $circle-diameter;
    height: $circle-diameter;
    text-align: center;

    span {
      display: table-cell;
      line-height: $circle-diameter;
      vertical-align: middle;
      text-align: center;

      font-size: 11px;
      font-family: $base-font-family;
      font-weight: $bold;
      color: $circle-text-color;
    }
  }
}
