@import 'css/base/color.scss';
@import 'css/mixins/flexbox.scss';

.invoices {
  @include flexbox();
  @include flex-flow(row);
  @include align-items(baseline);
  @include justify-content(space-between);
  width: 100%;
  .circle {
    background-color: $blue--x-light;
    min-width: 23px;
    border-radius: 52px;
    text-align: center;
    padding: 1px 8px;
  }
}
