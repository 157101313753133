@import 'css/base/color.scss';
@import 'css/base/margin.scss';
@import 'css/base/padding.scss';
@import 'css/base/typography.scss';
@import 'css/mixins/alignment.scss';
@import 'css/mixins/flexbox.scss';
@import 'css/mixins/includeMedia.scss';
@import 'css/variables/global.scss';


.block-list-item {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;

  > div,
  > span {
    max-width: 100%;
  }

  .list-item-label {
    margin-bottom: $margin--sm;
  }

  svg {
    @include v-align-icon(middle);
  }

  .block--multi-text {
    display: block;
  }
}

/*********/
/* BlockListItems inside a grid column (the general case) */
/*********/
.column {

  > .block-list-item {
    border-bottom: $border--blue-light;
    padding-bottom: $padding--m;
  }

  &:last-child {

    > .block-list-item {
      border-bottom: 0;
      padding-bottom: 0;
    }
  }
}

/*********/
/* BlockListItems inside a grid column, at or above tablet width  */
/*********/
@include media(">=tablet") {
  .tablet--halves {
    /*
      remove border from the 2nd-to-last child ONLY
      if it's an odd-numbered child
      (2nd-to-last, even-numbered children do not fall in
      the last item row and thus need a border)
    */
    .column:nth-last-child(2):not(:nth-child(even)) {

      > .block-list-item {
        border-bottom: 0;
        padding-bottom: 0;
      }
    }
  }
}


/*********/
/* BlockListItems inside an EntityWidget (adjust icon vertical align)  */
/*********/
.entity-widget {

  .entity-widget--section {

    .column {

      .block-list-item {

        svg {
          @include v-align-icon();
        }
      }
    }
  }
}

/*********/
/* BlockListItems inside ThreadDetails  */
/*********/
.thread-details {

  .thread-details--section {

    &.disclosure-list {

      .disclosure-body {

        > .block-list-item {
          margin-bottom: calc(#{$margin--m} - 1px);
          border-bottom: $border--blue-light;
          padding-bottom: $padding--m;

          &:last-child {
            margin-bottom: 0;
            border-bottom: 0;
            padding-bottom: 0;
          }
        }
      }
    }
  }
}
