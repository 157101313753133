$icon-size--10: 10px;
$icon-size--11: 11px;
$icon-size--12: 12px;
$icon-size--13: 13px;
$icon-size--14: 14px;
$icon-size--15: 15px;
$icon-size--16: 16px;

$toolbar-button-size: 37px;
$nav-border-height: 3px;

$container--narrow: 450px;

$side-panel-width-medium: 560px;
$side-panel-width-large: 650px;

$external-receipt-header-height: 156px;
