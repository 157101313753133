@import 'css/base/margin.scss';
@import 'css/mixins/flexbox.scss';
@import 'css/mixins/includeMedia.scss';


.reconfirm-taxes-hint {
  @include flexbox();

  @include media("<=tablet_small") {
    // wrap link below button on small devices
    @include flex-direction(column);
  }

  // center link in own box
  & > .help-link {
    @include flexbox();
    @include align-items(center);

    margin-left: $margin--large;

    @include media("<=tablet_small") {
      // help-text is now below button, so we need space on the top, not the left
      margin-left: 0;
      margin-top: $margin--m;
    }
  }
}
