@import 'css/base/block.scss';
@import 'css/base/padding.scss';
@import 'css/base/typography.scss';


.bottom-sheet__header {
  border-bottom: $content-block-border;
  padding: $padding--m-large;

  input {
    font-size: $font-sm !important;
  }
}
