@import '../base/margin.scss';


$modal-width: 650px;
$modal-width-inner: $modal-width - 2;
$modal-border-radius: 10px;
$modal-padding--horizontal-large: $margin--large-aligner;
$modal-padding--horizontal-small: 20px;
$modal-padding--vertical: 14px;
$modal-footer-height: 62px;


//noinspection CssInvalidPseudoSelector,CssUnknownProperty
:export {
  modalFooterHeight: $modal-footer-height;
}
