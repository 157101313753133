@import '../base/color.scss';
@import '../base/typography.scss';

.icon-button--borderless {
  border: none;
  background-color: inherit;
  border-radius: 50%;
  overflow: hidden;
  transition: all .15s;

  & > span.icon-ic-arrow-thin {
    display: table-cell;
    font-size: 26px;
    font-weight: $bold;
    color: $main-jordan;
    margin-right: 0;
  }

  &:hover {
    background-color: darken($blue--x-light, 2%);
    text-decoration: none !important
  }

  &:focus {
    outline: none;
  }
}
