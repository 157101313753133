@import 'css/base/color.scss';
@import 'css/base/padding.scss';
@import 'css/base/margin.scss';
@import 'css/base/typography.scss';
@import 'css/mixins/includeMedia.scss';
@import 'css/mixins/flexbox.scss';

$border-radius: 6px;
$footer-height: 67px;
$swal--min-width: 500px;
$swal--min-width-mobile: 300px;

.swal-overlay {
  // @react-aria/overlays sets a z-index of 100000 for gross-ds popovers
  z-index: 100005;
}

.swal-modal {
  border-radius: $border-radius;
  min-width: $swal--min-width;

  @include media("<=tablet_small") {
    min-width: $swal--min-width-mobile;
  }

  .swal-title {
    margin: 0;
    padding: $padding--large $padding--large $padding--m $padding--large;
    font-size: $font-m;
    text-align: left;
    color: $blue--dark;
    font-weight: $bold;
  }

  .swal-wrapper__heading--title .base-text {
    @include flexbox();
    @include align-items(center);
  }

  .swal-content,
  .swal-text {
    display: block;
    font-size: $font-sm;
    line-height: $paragraph;
    margin-top: 0;
    max-width: 100%;
    padding: 0 $padding--large;
    text-align: left;
    width: 100%;

    .message-and-code {
      color: $grey--x-dark;
      font-size: $font-m;
      font-weight: $bold;
      margin-bottom: $margin--m;
      text-align: left;
    }
  }

  .swal-footer {
    @include flexbox();
    @include flex-flow(row);
    @include align-items(center);
    @include justify-content(space-between);
    margin-top: $margin--large;
    border-top: $border--inset-content;
    padding: $padding--xm $padding--m;
    overflow: hidden;
  }

  // Removing frame when in focus (from SWAL)
  .swal-button:focus {
    outline: none;
    box-shadow: none;
  }

  // Error specific styles
  &.swal-error {
    & > .swal-footer {
      background-color: $red--light;
    }
  }

  // Notification specific styles
  &.swal-confirmation {
    & > .swal-footer {
      @include justify-content(space-between); // notifications have two buttons
    }
  }
}
