@import 'css/base/block.scss';
@import 'css/base/color.scss';
@import 'css/base/padding.scss';
@import 'css/base/margin.scss';
@import 'css/base/typography.scss';
@import 'css/mixins/scrollbar.scss';
@import 'css/variables/global.scss';


.terms-scroll-box {
  @include forceScrollbars();
  background-color: $white;
  border-radius: $block-border-radius--xm;
  border: $border--blue-light;
  max-height: 100px;
  overflow-y: auto;
  padding: $padding--m $padding--large;

  & > .title {
    color: $grey--x-dark;
    font-size: $font-xs;
    font-weight: $bold;
    margin-bottom: $margin--m;
  }

  & > .body {
    & .paragraph {
      color: $grey--x-dark;
      margin-bottom: $margin--m;
      font-size: $font-xs;
      line-height: $line-height--l;
      letter-spacing: $letter-spacing--xs;
    }
  }
}
