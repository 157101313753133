@import '../../../../../../global/css/base/color.scss';
@import '../../../../../../global/css/base/padding.scss';
@import '../../../../../../global/css/base/margin.scss';
@import '../../../../../../global/css/base/typography.scss';


.account-status--tab {
  display: inline-block;
  margin-right: $margin--large;
  text-decoration: none;
  font-size: $font-sm;
  font-weight: $bold;
  color: $grey--x-dark;
  opacity: $disabled-opacity;
  padding: $padding--m $padding--m $padding--m 0;

  .account-status--tab-icon {
    vertical-align: middle;
    color: $main-jordan;
  }

  &:hover {
    text-decoration: none;
    cursor: pointer;
    color: $blue--primary;
    opacity: 1;

    .account-status--tab-text,
    .account-status--tab-icon {
      color: $blue--primary;
    }
  }

  &.selected {
    opacity: 1;

    &:hover {
      color: $grey--x-dark;

      .account-status--tab-text {
        color: $grey--x-dark;
      }

      .account-status--tab-icon {
        color: $main-jordan;
      }
    }

    &:after {
      content: '';
      display: block;
      margin-top: $margin--m;
      margin-bottom: $margin--m;
      border-bottom: 3px solid $blue--primary;
    }
  }
}
