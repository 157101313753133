// brand colors
$blue--xx-light: #FBFCFF;
$blue--x-light: #F6F8FF;
$blue--light: #E0E5F5;
$blue--primary: #5582FA;
$blue--bold: #3C65F1;
$blue--dark: #0E1A43;
$blue--x-dark: #0D1943;

$light-aqua: #6DD8CD;
$aqua--light: #EEFFF6;
$aqua--bold: #1EB2A3;

$green--light: #F5FBE8;
$green--bold: #76A86A;
$green--dark: #41482C;

$extra-light-purple: #F3EEFF;
$main-purple: #AF53FF;
$purple--light: #F4EEFF;
$purple--bold: #BA6FDD;
$purple--dark: #42345A;

$main-pink: #F15D98;

$red--05: #FFEDE7;
$red--light: #FFF2EE;
$red--bold: #EB6345;
$red--dark: #54342C;
$red--medium: #8E3420;

$dark-red: #CD5151;

$light-orange: #FFAB7C;
$main-orange: #FD8D4E;
$dark-orange: #E66F2C;

$main-cyan: #77F5FB;

$extra-light-yellow: #FFFFEE;
$lighter-yellow: #FFF4D4;
$light-yellow: #FFE9A8;
$main-yellow: #FDD54F;
$dark-yellow: #F9C01F;
$yellow--dark: #56390D;
$yellow--light: #FFF7E1;
$yellow--medium: #E49318;
$yellow--bold: #EFB866;

$ylw--light: #FFF7E1;

$light-green: #65E7A1;
$main-green: #30D37B;
$dark-green: #25AC64;

$black: #2F3A48;

// [TODO] remove first one, replace all instances with second one.
$neutral--black: #2E2F33;
$black--neutral: #2E2F33;

$grey--xxx-light: #7A84A3;
$grey--xx-light: #F9F9F9;
$grey--x-light: #EBEBEB;
$grey--05: #F9F9F9;
$grey--20: #D8D8D8;
$grey--med: #B7B9BE;
$grey--dark: #9498A3;
$grey--x-dark: #697186;
$grey--xx-dark: #4C5467;
$grey--xxx-dark: #3C4858;

$main-jordan: #99A6CC;
$dark-jordan: #7F8DB9;

$main-silver: #B9C2DC;
$dark-silver: #9BA5C1;

$white: #FFFFFF;
$white90: rgba(255, 255, 255, 0.9);

// ui colors
$primary: $blue--primary;
$positive: $main-green;
$negative: $red--bold;
$alert: $main-orange;
$confirm: $aqua--bold;
$warning: $light-yellow;
$scheduled: $main-purple;
$pending: $main-jordan;

// ledger colors
$xero: #2BAAED;
$qbo: #2CA01C;

$netsuite--loading: (
  start: transparentize($blue--bold, 0.2),
  middle: #7789C6,
  end: transparentize(#404F83, 0.2)
);

$qbo--loading: (
  start: transparentize(#2676C0, 0.2),
  middle: #6494A4,
  end: transparentize(#47A46C, 0.35)
);

$xero--loading: (
  start: #6595F2,
  middle: #6FC5F5,
  end: #5CCEE8
);

// sso colors
$google-oauth2--loading: (
  start: transparentize(#4079C8, 0.1),
  middle: transparentize(#459096, 0.1),
  end: transparentize(#49A963, 0.1)
);

// integration colors
$loft: #3E3B5E;

$transparent: transparent;

$panel-background: darken($blue--xx-light, 1%);

// noinspection CssUnknownProperty,CssInvalidPseudoSelector
:export {
  aquaBold: $aqua--bold;
  black: $black;
  // [TODO] remove first one, replace all instances with second one.
  neutralBlack: $neutral--black;
  blackNeutral: $black--neutral;
  blueBold: $blue--bold;
  blueDark: $blue--dark;
  blueXDark: $blue--x-dark;
  blueExtraLight: $blue--x-light;
  blueExtraExtraLight: $blue--xx-light;
  blueLight: var(--color-grey20);
  bluePrimary: $blue--primary;
  darkGreen: $dark-green;
  darkJordan: $dark-jordan;
  darkSilver: $dark-silver;
  darkYellow: $dark-yellow;
  greenLight: $green--light;
  greenBold: $green--bold;
  greyXLight: $grey--x-light;
  greyDark: $grey--dark;
  greyXDark: $grey--x-dark;
  greyXXDark: $grey--xx-dark;
  loft: $loft;
  mainGreen: $main-green;
  greenDark: $green--dark;
  mainJordan: $main-jordan;
  mainPurple: $main-purple;
  mainSilver: $main-silver;
  red05: $red--05;
  redBold: $red--bold;
  redLight: $red--light;
  redMedium: $red--medium;
  redDark: $red--dark;
  greyMed: $grey--med;
  purpleBold: $purple--bold;
  purpleDark: $purple--dark;
  steel: $grey--xx-dark;
  transparent: $transparent;
  white90: $white90;
  white: $white;
  xero: $xero;
  yellowBold: $yellow--bold;
  yellowLight: $yellow--light;
  yellowMedium: $yellow--medium;
  yellowDark: $yellow--dark;
}
