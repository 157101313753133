@import 'css/base/color.scss';
@import 'css/base/margin.scss';
@import 'css/base/typography.scss';
@import 'css/variables/global.scss';


.tax-faq-disclosure-list {

  .disclosure-header {
    height: $info-card-section-height;

    &:hover {
      cursor: pointer;
    }
  }
}

.tax-faq-disclosure-list--item {
  margin: $margin--m-large;
}
