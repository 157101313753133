@import '../base/color.scss';

@mixin forceScrollbars() {
  -ms-overflow-style: auto;

  &::-webkit-scrollbar {
    display: block;
    width: $scrollbar--width;
    height: $scrollbar--height;
    background: var(--color-grey10);
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: var(--color-grey30);
  }
  
  &::-webkit-scrollbar-thumb:hover {
    background-color: var(--color-grey50);
  }
}


@mixin hideScrollbar() {
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */

  /* Webkit: Chrome & Safari */
  &::-webkit-scrollbar {
    display: none;
  }
}

// Make divs and iframes scroll with smooth momentum on iOS
@mixin iOSSmoothScrolling() {
  @supports (-webkit-overflow-scrolling: touch) {
    -webkit-overflow-scrolling: touch;

    // Workaround to fix delayed touch events when momentum scrolling is applied
    & > * {
      -webkit-transform: translate3d(0, 0, 0);
    }
  }
}
