@import 'css/base/block.scss';
@import 'css/base/color.scss';
@import 'css/base/margin.scss';
@import 'css/base/padding.scss';
@import 'css/base/sizes.scss';
@import 'css/variables/sizes.scss';
@import 'css/base/typography.scss';
@import 'css/mixins/flexbox.scss';
@import 'css/mixins/includeMedia.scss';


$entity-widget-min-width: 250px;


.view-bank-account--info {
  .item {
    display: table;
    width: 100%;
  }
}

.thread-details {
  .view-bank-account--widget {

    .entity-widget--body {
      padding: 0 $padding--m;
    }

    .disclosure-list {
      padding-bottom: $padding--m;
    }
  }
}

.view-bank-account--widget {
  &:not(:first-child) {
    margin-top: $margin--m;
  }

  .entity-widget--body {
    background-color: $blue--xx-light;
  }

  .chip-icon {
    margin-right: $margin--xm;
  }

  .direction-control {
    padding-left: $padding--m;
    padding-right: $padding--m;
  }

  .bank-account--hint-action--link {
    color: $grey--x-dark;
    font-size: $font-xs;
    padding-left: $padding--large;
    @include flexbox();
    @include align-items(center);


    @include media('<phone_large') {
      padding-left: 0;
    }

    svg {
      min-width: $icon-size--14;
    }
  }

  .bank-account--header--content-right {
    @include flexbox();
    @include justify-content(flex-end);
    height: 100%;
    min-height: $bar-button-size;

    &.align-items--center {
      svg {
        margin-top: 0;
      }
    }

    .bank-account--header--flair-items {
      @include flexbox();
      @include justify-content(flex-end);
      margin-right: $margin--xm;

      .flair-badge {
        @include align-self(center);
        margin-left: $margin--sm;
      }
    }
  }

  .entity-widget--header {

    .entity-widget--header-subtitle {
      min-width: $entity-widget-min-width;
    }
  }

  &.open {

    .entity-widget--header {

      &.bank-account--address-header {
        border-bottom: 0;
        cursor: auto;
      }
    }
  }

  &.selectable,
  &.view-bank-account--no-body {
    max-width: initial;

    .entity-widget--header {

      &.bank-account--header {
        border-bottom: 0;
      }

      &.collapsible {

        &:hover {

          .entity-widget--header-title {
            color: $grey--x-dark;
          }
        }
      }
    }
  }

  &.selectable {

    .entity-widget--header {

      .entity-widget--header-title {
        margin-left: $text-block-margin;
      }

      .bank-account--address-header {

        &.collapsible {

          &:hover {

            .entity-widget--header-title {
              color: $dark-jordan;
            }
          }
        }
      }
    }
  }

  &:not(.selectable) {

    .entity-widget--header.bank-account--address-header {

      &.collapsible {

        &:hover {

          .entity-widget--header-title {
            color: $grey--x-dark;
          }
        }
      }
    }
  }

  &.selectable,
  &.collapsible {

    .entity-widget--header {
      cursor: pointer;
    }
  }
}

.disclosure-list {

  .bank-account--header {

    .column {
      padding-left: 0;
    }
  }
}
