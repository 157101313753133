@import 'css/base/color.scss';
@import 'css/base/padding.scss';
@import 'css/base/sizes.scss';
@import 'css/base/spacing.scss';
@import 'css/base/typography.scss';
@import 'css/mixins/flexbox.scss';
@import 'css/mixins/includeMedia.scss';
@import 'css/mixins/zIndex.scss';


.external__header {
  @include flexbox();
  @include align-items(center);
  @include justify-content(space-between);

  @include zIndex('STICKY');
  position: sticky;
  top: 0;

  height: $external-header--height;
  padding: 0 $padding--larger;

  background-color: $white;
  border-bottom: 1px solid $grey--x-light;

  @include media($mobileOnly) {
    height: $external-header--height-mobile;
    padding: 0 $padding--m-large;
  }

  &--company-logo {
    @include flexbox();
    @include align-items(center);
    @include justify-content(flex-start);

    height: $external-header--height - $spacing--24;

    @include media($mobileOnly) {
      height: $external-header--height-mobile - $spacing--16;
    }

    img {
      max-width: 115px;
      height: 100%;
      object-fit: contain;
    }

    &--text {
      font-size: $font-m-l !important;
    }
  }

  &--items {
    @include flexbox();
    @include align-items(center);
    @include justify-content(flex-end);
  }
}
