@keyframes startLoading {
  0% { width: 0; }
  100% { width: 80%; }
}

@keyframes finishLoading {
  0% { width: 80%; }
  100% { width: 100%; }
}

.fill-bar {
  position: absolute;
  left: 0;
  height: 100%;
  display: block;
  overflow: hidden;
  background-color: #fff;
  animation: startLoading 0.6s linear forwards;
}

.finish-loading {
  animation: finishLoading 0.3s linear forwards;
}
