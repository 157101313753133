@import '../../global/css/base/color.scss';
@import '../../global/css/base/margin.scss';
@import '../../global/css/base/typography.scss';
@import '../../global/css/mixins/flexbox.scss';
@import '../../global/css/mixins/mediaQuery.scss';
@import '../../global/css/variables/global.scss';


.brand--footer {
  @include flexbox();
  @include align-items(center);
  @include flex-flow(row);

  background-color: $white;
  border-top: $border--inset-content;
  margin-top: $margin--large;
  min-height: $footer--height;

  @include media($SCREEN:(TABLET)) {
    margin: 0 auto;
    max-height: $footer--height;
  }

  .footer--content {
    max-width: $branded-page--max-width;
    padding: $branded-page--padding;

    @include media($SCREEN:(TABLET)) {
      @include flexbox();
      @include align-items(center);
      @include flex-flow(row);
      @include justify-content(space-between);
      margin: auto;

      width: 100%;
      padding: 0 $branded-page--padding;
    }
  }

  .footer--content-left {
    @include flexbox();
    @include align-items(center);
    @include flex-flow(row);

    opacity: 0.8;
    pointer-events: none;

    span {
      font-family: $brand-font-family;
      font-size: $font-regular;

      @include media($SCREEN:(TABLET)) {
        font-size: $font-xs;
      }

      &.icon-ic-shield {
        font-size: $font-m;
      }
    }
  }

  .footer--content-right {
    margin-top: $margin--large;

    @include media($SCREEN:(TABLET)) {
      margin: 0;
    }

    & > ul.footer--links {
      @include flexbox();
      @include align-items(flex-start);
      @include flex-flow(column);

      @include media($SCREEN:(TABLET)) {
        @include align-items(center);
        @include flex-flow(row);
      }

      & > li {
        margin-bottom: $margin--m;

        a {
          font-size: $font-regular;
        }

        @include media($SCREEN:(TABLET)) {
          margin-bottom: 0;

          a {
            font-size: $font-xs;
          }
        }

        &:not(:last-child) {
          margin-right: $margin--large;
        }
      }
    }
  }
}
