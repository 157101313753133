@import 'css/base/color.scss';
@import 'css/base/margin.scss';
@import 'css/base/padding.scss';
@import 'css/base/table.scss';
@import 'css/base/typography.scss';
@import 'css/mixins/flexbox.scss';
@import 'css/mixins/borders.scss';
@import 'css/mixins/scrollbar.scss';

$load-more--number-size: 22px;

$table-select-menu--max-height: 152px;

// Table wrappers
.platform-table--full {
  border-bottom: $table-border--outer;
  border-radius: 0;
}

.platform-table--inline {
  border: $table-border--outer;
  border-radius: $table-border-radius;
  margin-bottom: $margin--m;
  width: 100%;

  // Show field errors differently for on the inline table (for inv generator)
  .form-error-container {
    pointer-events: none;

    /* centering at the bottom of container */
    text-align: center;
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    /* styling */
    background: var(--color-red00);
    padding: 2px 4px;
    width: 100%;
    border-radius: 0;

    p {
      color: var(--color-red60);  
      font-weight: 500;

      &::first-letter {
        text-transform: uppercase;
      }
    }
  }

  // this is for the select menu portal div
  > div {
    > .Select__menu {
      max-height: $table-select-menu--max-height;
      // !important must be used to override styles from react-select
      margin: 0 !important;
      min-width: 250px;
      top: $spacing--16;
      // unset width - to not wrap children
      width: unset !important;
      max-width: 350px;

      > .Select__menu-list {
        max-height: $table-select-menu--max-height;
      }
    }
  }
}

// Body row
.table-row--container {
  @include flexbox();
  @include flex-flow(row nowrap);

  height: auto;
  min-height: $table-body-row--height;
  background-color: $table-body-row--background-color;

  & > .align-center {
    & > .column-content {
      @include align-items(center);
    }
  }

  .remove-padding {
    padding: 0;
  }

  // LOAD MORE ROW
  &.table-footer--load-more {
    background-color: $white;
    border-top: $table-border--inner;

    padding: $spacing--8;

    // LOAD MORE BUTTON
    .load-more--button {
      @include flexbox();
      @include justify-content(center);
      @include align-items(center);

      background-color: $blue--x-light;
      border-radius: $spacing--8;
      border: none;
      color: $blue--bold;
      cursor: pointer;

      font-size: $font-sm;
      font-weight: $bold;
      line-height: $font-xs;

      transition: all 0.2s;
      text-align: center;
      height: 100%;
      width: 100%;

      .load-more--number {
        @include flexbox();
        @include align-items(center);
        @include justify-content(center);

        background-color: $white;
        border-radius: 50%;
        cursor: pointer;

        font-size: $font-xs;

        margin-left: $spacing--8;
        height: $load-more--number-size;
        width: $load-more--number-size;
      }

      .sk-three-bounce {
        margin-left: 0;
        color: $blue--bold !important;
      }

      > span {
        color: $blue--bold;
      }

      &:hover {
        background-color: $blue--bold;
        > span:not(.load-more--number) {
          color: $white;
        }
      }
    }
  }

  &.archived {
    background-color: $blue--xx-light;
  }

  &.confirmed {
    background-color: $table-body-row--background-color--confirmed;
  }

  &.disabled {
    pointer-events: none;
    background-color: $table-body-row--background-color--disabled;

    .checkbox--indicator {
      background-color: $table-body-row--background-color--disabled;
    }
  }

  &.error {
    background-color: $table-body-row--background-color--error;
  }

  &:hover:not(.disable-hover) {
    background-color: $table-body-row--background-color--hover;
    cursor: pointer;
    pointer-events: none;
  }

  &:last-child {
    @include borderRadii($table-border-radius, bottom-left, bottom-right);

    // in the last row, if the first or last column cells have background colors, the background spills over the border
    // inherit the radius from the outer table wrapper
    & > .table-row--column:first-child {
      border-bottom-left-radius: inherit;
    }

    & > .table-row--column:last-child {
      border-bottom-right-radius: inherit;
    }
  }
}

// use this class to wrap a table row in a link
// that will make the entire row clickable
.table-row--link-wrapper {
  display: block;
  color: inherit;

  .table-row--container:hover {
    background-color: $table-body-row--background-color--hover;
  }
}

// Header row
.table-row--header {
  @include flexbox();
  @include flex-flow(row nowrap);
  @include borderRadii($table-border-radius, top-left, top-right);

  height: $table-header-row--height;

  span {
    color: $table-form-field--font-color--header;
    font-size: var(--font-size-1);
    font-weight: var(--font-weight-medium);
  }

  // Align header to center if specified
  & > .align-center {
    & > .column-content {
      @include align-items(center);
    }
  }

  .table-row--column {
    border-top: none;

    &:first-child {
      border-top-left-radius: $table-border-radius;
    }
    &:last-child {
      border-top-right-radius: $table-border-radius;
    }

    .column-content {
      word-break: break-word;
    }
  }

  .remove-padding {
    padding: 0;
  }
}

// Column
.table-row--column {
  border-top: $table-border--inner;
  flex: 0 0 auto;
  padding: 0 $table-padding--horizontal;
  // Style needed for the combobox.
  position: relative;

  @at-root .platform-table--full #{&}:first-child {
    padding-left: $table-align-cell-to-container;
  }

  &.disabled {
    pointer-events: none;
    background-color: $table-body-row--background-color--disabled;
  }

  &.warn {
    background-color: $table-body-row--background-color--warn;
  }

  &:not(:last-child) {
    border-right: $table-border--inner;
  }

  .select-wrapper {
    &.showing-value {
      height: 100%;
    }

    .Select {
      border: none;
      &.has-value {
        min-height: 58px;
        height: unset;
      }

      .Select__control {
        @include flexbox();
        @include justify-content(center);
        padding: $spacing--8 $spacing--16 0 $spacing--16;
        height: 100%;
      }

      .Select__placeholder,
      .Select__single-value,
      .Select__input {
        font-size: $font-xs;
        line-height: $font-m;
      }

      .Select__placeholder {
        font-size: $font-xs;
        line-height: $font-m;
        // workaround to align properly
        bottom: calc(50% - #{$spacing--8});
      }

      .Select__single-value {
        position: relative;
        white-space: normal;
      }

      .Select__input {
        // workaround to align properly with placeholder
        transform: translateX(-2px) translateY(-1px);
      }
    }

    .Select__menu {
      // !important must be used to override styles from react-select
      margin: 0 !important;
      width: 100% !important;
    }
  }
}

// Cell
@for $i from 1 through 50 {
  .table-cell--width-#{$i} {
    width: $table-cell--width-block * $i;
  }
}

.column-content {
  @include flexbox();
  @include align-items(flex-start);
  @include flex-flow(column nowrap);
  @include justify-content(center);

  padding: $padding--form-control-l var(--spacing-4);

  line-height: $paragraph;

  height: 100%;
  width: 100%;

  & .align-items--center {
    @include align-items(center);
  }

  // used to properly align table fields without a class (ex. checkboxes)
  // an example would be the description field on a xero connected ledger
  > div:first-child[class=''],
  > span:first-child[class=''] {
    width: 100%;
  }

  // Fixes the issue where span overflows the parent div container and thus
  // does not truncate the text correctly
  span.truncate {
    display: inline-block;
    vertical-align: middle;
  }
}

.align-cell {
  @include flexbox();
  @include flex-flow(row nowrap);
  position: relative;

  &.column {
    @include flex-flow(column nowrap);
  }

  width: 100%;
}

.ledger-form-field {
  width: 100%;

  &:not(.disabled):not(.is-disabled):not(:has(.po-cell)) {
    background-color: $white;
  }

  &.disabled,
  &.is-disabled {
    pointer-events: none;
    background-color: $table-body-row--background-color--disabled;
  }

  textarea {
    // must be !important so it doesnt overflow table row
    height: 100% !important;
  }

  input,
  textarea {
    height: 100%;
    width: 100%;

    min-height: $table-body-row--height;

    &:disabled {
      color: $table-form-field--font-color--disabled;

      &::placeholder {
        color: $table-form-field--font-color--disabled;
      }
    }

    /**
      We need a size correction when input field gets autofilled due to the
      box-shadow inset "hack" - otherwise, the bottom border of the input container
      disappear. This will only affect no-ledger scenario since ledger inputs
      never get autofilled.
     */
    &:-webkit-autofill {
      min-height: $input-container-height;
    }

    background-color: transparent;
    outline: 0;
    border: hidden;
    resize: none;
    -webkit-appearance: none;
    -moz-appearance: none;
  }

  input[type='checkbox'] {
    height: unset;
    width: unset;
    min-height: unset;
  }

  .react-datepicker-wrapper,
  .react-datepicker__input-container {
    height: 100%;
  }

  .react-datepicker__input-container .form-style {
    border: none;

    input {
      color: $grey--x-dark;
      font-size: $font-xs;
      font-weight: $semibold;
      padding: $padding--form-control-l $padding--m;
    }
  }

  input,
  textarea,
  .Select__single-value {
    color: var(--neutral-black);
    font-size: $font-xs;
  }

  &.input-container {
    label {
      display: none;
    }
    .placeholder {
      font-size: $font-xs;
      left: $padding--m;
    }
  }
}

.tablematic-table {
  .ledger-form-field textarea,
  .ledger-form-field input,
  .ledger-form-field .po-cell  {
    // padding: 0;
    min-height: unset;
    height: unset;
  }

  .ledger-form-field textarea {
    padding: 13px 14px;

    @include forceScrollbars();

    &:not(:focus) {
      @include hideScrollbar();
    }
  }

  .ledger-form-field.input-container .placeholder {
    top: 0;
  }

  .input-container, .select-container, .ledger-form-field {
    height: 42px;
  }
  .ledger-form-field {
    display: flex;
    align-items: center;

    &:not(.disabled):not(.is-disabled):not(:has(.po-cell)) {
      background-color: transparent;
    }

    &.disabled {
      background-color: inherit;
      color: inherit;
  
      .currency-code,
      input {
        color: inherit;
      }
    }
  }
}
.funding-match-list {
  &.Select {
    border: none;

    .Select__control {
      padding-left: 0;
      padding-right: 0;
    }

    + .select--label {
      left: 0;
    }

    &.has-value {
      + .select--label {
        opacity: 0;
      }
    }
  }
}

/*
react-virtualized table-specific styles below
*/

.ReactVirtualized__Table__headerRow,
.ReactVirtualized__Table__row {
  @include flexbox();
  @include flex-direction(row);
  @include align-items(center);
}

.ReactVirtualized__Table__row {
  background-color: $white;
  border-top: 1px solid var(--color-grey20);

  &.confirmed {
    background-color: $table-body-row--background-color--confirmed;
  }

  &.archived {
    background-color: $table-body-row--background-color--archived;
  }

  &.disabled {
    background-color: $table-body-row--background-color--disabled;
    pointer-events: none;

    .checkbox--indicator {
      background-color: $table-body-row--background-color--disabled;
    }
  }

  &.error {
    background-color: $table-body-row--background-color--error;
  }

  &.unread {
    background-color: $table-body-row--background-color--unread;
  }

  &:last-child {
    border-bottom: $table-border--inner;
  }

  &:hover:not(.disable-hover) {
    background-color: $table-body-row--background-color--hover;
    cursor: pointer;
  }
}

.ReactVirtualized__Table__headerTruncatedText {
  display: inline-block;
  max-width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.ReactVirtualized__Table__headerColumn,
.ReactVirtualized__Table__rowColumn {
  @include flexbox();
  @include align-items(center);

  text-overflow: ellipsis;
  white-space: nowrap;
  height: 100%;
  min-width: 0;
  padding: 0 14px;
  border-right: $table-border--inner;

  &:last-child {
    border-right: none;
  }
}

.ReactVirtualized__Table__headerColumn {
  background-color: $table-header-row--background-color;
  border-top: $table-border--inner;

  &:first-child {
    padding-left: $table-align-cell-to-container;
  }

  &.no-background {
    background-color: transparent;
  }

  &.remove-border-top {
    border-top: none;
  }

  &.ledger {
    span {
      font-size: $font-xs;
      color: $dark-jordan;
      letter-spacing: unset;
    }
  }

  span {
    color: $table-form-field--font-color--header;
    font-size: $font-xs;
    font-weight: $bold;
  }
}

.ReactVirtualized__Table__sortableHeaderColumn {
  cursor: pointer;
}

/*
 * .table--borders styles apply to TableBorders component. sets:
 *  - border radii to the $table-border-radius
 *  - right and left borders to the $table-border--outer
 *  - overrides an unwanted outer cell padding applied by react-virtualized
 */
.table--borders {
  border-radius: $table-border-radius;

  .ReactVirtualized__Table__headerColumn {
    &:first-child {
      @include borderRadii($table-border-radius, 'top-left');
      /* override react-virtualized extra padding on first column */
      padding-left: $padding--form-control-l;
    }

    &:last-child {
      @include borderRadii($table-border-radius, 'top-right');
    }
  }

  .ReactVirtualized__Table__row,
  .ReactVirtualized__Table__headerRow {
    border-right: $table-border--outer;
    border-left: $table-border--outer;

    &:last-child {
      @include borderRadii($table-border-radius, 'bottom-left', 'bottom-right');
    }
  }

  .ReactVirtualized__Table__headerRow {
    @include borderRadii($table-border-radius, 'top-left', 'top-right');
  }
}
