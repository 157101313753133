@import 'css/mixins/includeMedia.scss';


.nav-breadcrumbs {

  .breadcrumb-li {
    display: grid;

    // the back button should only be visible on mobile
    &.back-button {
      display: none;
    }
  }

  @include media("<tablet_small") {
    .breadcrumb-li {
      &:not(:last-child) {
        display: none;
      }

      // the back button should only be visible on mobile
      &.back-button {
        display: grid;
      }
    }
  }
}
