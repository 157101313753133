@import 'css/base/color.scss';
@import 'css/base/spacing.scss';

.edit-btn-input {
  position: absolute;
  top: $spacing--12;
  right: $spacing--8;

  & > svg {
    color: $main-jordan;
  }
}

.locked-input-tooltip {
  position: absolute;
  top: $spacing--20;
  right: $spacing--16;
}
