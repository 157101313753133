@import './global.scss';
@import '../base/margin.scss';
@import '../base/margin.scss';
@import '../base/typography.scss';
@import '../mixins/includeMedia.scss';
@import '../mixins/flexbox.scss';
@import '../mixins/background.scss';


.branded--container {
  @include fancy-marketing-background-image();
  height: 100%;
  min-height: 100vh;
}

.branded--contents {
  @include flexbox();
  @include flex-flow(column nowrap);
  @include justify-content(center);
  @include align-items(center);

  @include media("<=phone_large") {
    @include justify-content(flex-start);
    min-height: auto;
  }

  padding: $padding--large;
  height: 100%;

  &:not(.no-min-height) {
    min-height: calc(100vh - #{$nav--height});
  }
}

.branded-form-submit--confirmation {
  width: 100%;
  display: table;
  height: 56px;

  // Adjusting for button margin-top of 14px
  margin-top: $margin--m + 2px;
  padding: 4px 16px;
  background-color: rgb(106, 125, 200);
  border-radius: 10px;

  > * {
    // Aligning child elements to middle
    display: table-cell;
    vertical-align: middle;
  }
}
