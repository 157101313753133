@import '../../global/css/base/color.scss';
@import '../../global/css/base/typography.scss';
@import '../../global/css/mixins/mediaQuery.scss';

$left-icon-size: 14px;

.document {

  & > div.document-form {
    display: table;
    table-layout: fixed;
    height: $form-height-xl;

    .document-form--left {
      display: table-cell;
      width: 56%;
      vertical-align: middle;
      padding-left: 14px;

      .icon-left {
        font-size: $left-icon-size;
        color: $dark-jordan;
        line-height: 16px;
        display: inline-block;
        vertical-align: top;
      }

      a {
        width: calc(100% - #{$left-icon-size});
        display: inline;
        vertical-align: middle;
        line-height: 16px;

        span {
          display: inline-block;
          padding-left: 10px;

          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;

          font-size: 13px;
          font-weight: $semibold;

          &:hover {
            color: $primary;
            text-decoration: underline;;
          }
        }
      }

      @include media($SCREEN:(_SMALL)) {
        width: 70%;
      }

      @include media($SCREEN:(TABLET_SMALL)) {
        width: 68%;
      }
    }

    .document-form--date {
      display: table-cell;
      vertical-align: middle;

      span {
        font-size: 13px;
      }
    }

    .document-form--right {
      display: table-cell;
      vertical-align: middle;
      text-align: right;
      padding-right: 14px;

      & > a {
        float: right;
      }

      & > span.icon-ic-x-out {
        float: right;
        padding: 4.6px 10px;
        line-height: 19px;

        font-size: 8px;
        font-weight: $bold;
        color: $dark-jordan;
        background-color: $blue--x-light;
        border-radius: 50px;

        &:hover {
          color: $red--bold;
          background-color: $red--light;
        }
      }
    }
  }

  @media (max-width: 500px) {
    min-width: auto;

    > div.document-form .document-form--left {
      width: 50%;

      .like-p {
        font-size: 12px;
      }
    }

    .document-form--date .like-p {
      font-size: 11px;
    }

    .document-form--right .like-p {
      font-size: 10px;
    }
  }
}
