@import '../../global/css/base/color.scss';
@import '../../global/css/base/margin.scss';
@import '../../global/css/base/typography.scss';
@import '../../global/css/mixins/circleItem.scss';


$circle-diameter--large: 26px;
$circle-diameter--small: 6px;

.instruction--container {
  display: table;
  width: 100%;
  margin-bottom: $margin--large;

  .content--left {
    display: table-cell;
    width: 40px;
    vertical-align: middle;

    .instruction--circle {
      pointer-events: none;
      @include circle(darken($blue--x-light, 3%), $circle-diameter--large, $blue--primary);
    }
  }

  .content--right {
    display: table-cell;
    vertical-align: middle;

    .instruction--text {
      line-height: 18px;

      color: $dark-jordan;
      font-family: $base-font-family;
      font-size: $font-regular;
      font-weight: $semibold;
    }
  }

  &:last-child {
    margin-bottom: 0;
  }

  &:only-child {
    margin-bottom: 0;
  }

  &.is-step-header {
    margin-bottom: 0;

    .instruction--circle {
      @include circle(var(--color-grey20), $circle-diameter--large, darken($dark-jordan, 7%));

      &.has-step-above {
        margin-top: 48px;
      }
    }

    .instruction--text {
      line-height: $font-l;

      color: $grey--x-dark;
      font-size: 15px;
      font-weight: $semibold;
    }
  }

  &.external {
    .instruction--circle {
      @include circle(var(--color-grey20), $circle-diameter--large, darken($dark-jordan, 8%));
    }

    .instruction--text {
      color: darken($dark-jordan, 6%);
      font-size: $font-sm;
      font-weight: $regular;
      line-height: 1.6;
     }
  }

  &.is-bullet {
    margin-bottom: $margin--sm;

    &:last-child {
      margin-bottom: 0;
    }

    .content--left {
      width: 16px;
    }

    .instruction--circle {
      @include circle($aqua--bold, $circle-diameter--small, darken($dark-jordan, 7%));
    }

    .instruction--text {
      line-height: $font-l;

      color: darken($dark-jordan, 4%);
      font-size: $font-xs;
      font-weight: $semibold;
    }
  }
}
