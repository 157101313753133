@import 'css/variables/global.scss';

// font weights
$thin: 100;
$light: 300;
$regular: 400;
$semibold: 500;
$bold: 600;
$extra-bold: 700;

// font sizes
$font--10: 10px;
$font--11: 11px;
$font--12: 12px;
$font--13: 13px;
$font--14: 14px;
$font--16: 16px;
$font--18: 18px;
$font--20: 20px;
$font--22: 22px;
$font--24: 24px;
$font--26: 26px;
$font--32: 32px;
$font--40: 40px;
$font--50: 50px;
$font--60: 60px;

$font-xxs: $font--11;
$font-xs: $font--12;
$font-regular: $font--13;
$font-sm: $font--14;
$font-m: $font--16;
$font-m-l: $font--20;
$font-l: $font--22;
$font-larger: $font--24;
$font-x: $font--26;
$font-xl: $font--40;
$font-xxl: $font--60;

$select-indicator-font-size: 9px;

$font--currency-xl: 36px;

// icon font sizes
$icon-font-xs: 10px;

// font-faces
@font-face {
  font-family: 'Gilroy-ExtraBold';
  src: url('/fonts/gilroy/Gilroy-ExtraBold.woff2') format('woff2'),
  url('/fonts/gilroy/Gilroy-ExtraBold.woff') format('woff');
}

@font-face {
  font-family: 'Inter';
  src: url('/fonts/inter/Inter-Light.ttf');
  font-weight: 300;
}
@font-face {
  font-family: 'Inter';
  src: url('/fonts/inter/Inter-Regular.ttf');
  font-weight: 400;
}
@font-face {
  font-family: 'Inter';
  src: url('/fonts/inter/Inter-Medium.ttf');
  font-weight: 500;
}
@font-face {
  font-family: 'Inter';
  src: url('/fonts/inter/Inter-SemiBold.ttf');
  font-weight: 600;
}
@font-face {
  font-family: 'Inter';
  src: url('/fonts/inter/Inter-Bold.ttf');
  font-weight: 700;
}


// base font
$base-font-family: (
/* 1 */ "Inter",
/* 2 */ -apple-system, BlinkMacSystemFont,
/* 3 */ "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
/* 4 */ "Helvetica Neue", sans-serif);

$emoji-font-family: "Noto Color Emoji", "Apple Color Emoji", "Segoe UI Emoji", $base-font-family;
$monospaced-font-family: Monaco, Consolas, "Liberation Mono", "Courier New", monospace;

// line-height
$base-line-height: normal;
$paragraph: 1.555;

$line-height--0: 0;
$line-height--14: 14px;
$line-height--15: 15px;
$line-height--16: 16px;
$line-height--17: 17px;
$line-height--18: 18px;
$line-height--20: 20px;
$line-height--22: 22px;
$line-height--24: 24px;
$line-height--26: 26px;
$line-height--28: 28px;
$line-height--32: 32px;
$line-height--36: 36px;
$line-height--40: 40px;
$line-height--sm: $line-height--14;
$line-height--xm: $line-height--16;
$line-height--m: $line-height--18;
$line-height--l: $line-height--20;
$line-height--xl: $line-height--22;
$line-height--xxl: $line-height--26;
$line-height--xxxl: $line-height--32;

// letter-spacing
$letter-spacing--xxs: -0.07px;
$letter-spacing--xs: -0.05px;
$letter-spacing--l: 0.25px;
$letter-spacing--xl: 0.5px;

// alt font
$alt-font-family: (
/* 1 */ "Inter",
/* 2 */ "Helvetica Neue",
/* 3 */ -apple-system, BlinkMacSystemFont,
/* 4 */ "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
/* 5 */ "Helvetica Neue", sans-serif);

// brand-font
$brand-font-family: ('Inter', Arial Rounded MT Bold, Arial, Helvetica, sans-serif);

// marketing-font
$marketing-font-family: ('Gilroy-ExtraBold', $brand-font-family);

// disabled states
$disabled-opacity: 0.5;

//noinspection CssInvalidPseudoSelector,CssUnknownProperty
:export {
  paragraph: $paragraph;
  letterSpacingXxs: $letter-spacing--xxs;
  baseLineHeight: $base-line-height;
  lineHeightZero: $line-height--0;
  lineHeight24: $line-height--24;
  lineHeight28: $line-height--28;
  lineHeight40: $line-height--40;
  lineHeightSm: $line-height--sm;
  lineHeightXm: $line-height--xm;
  lineHeightM: $line-height--m;
  lineHeightL: $line-height--l;
  lineHeightXl: $line-height--xl;
  lineHeightXxl: $line-height--xxl;
  lineHeightXxxl: $line-height--xxxl;
}
