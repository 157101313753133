@import '../../global/css/base/color.scss';
@import '../../global/css/base/padding.scss';
@import '../../global/css/mixins/flexbox.scss';

$label-dimension: 62px;
$label-logo-dimension: 28px;

.label {
  @include flexbox();
  @include align-items(center);
  background-color: $blue--x-light;
  border: 1px solid var(--color-grey20);
  border-radius: 6px;
  height: $label-dimension;
  overflow: hidden;

  & > .body {
    @include flexbox();
    @include align-items(center);
    @include justify-content(space-between);
    width: 100%;
    padding-left: $padding--m;
    padding-right: $padding--m;
  }

  & > .logo {
    @include flexbox();
    @include align-items(center);
    @include align-self(stretch);
    @include justify-content(space-between);
    height: 100%;
    width: $label-dimension;
    padding-left: $padding--m;
    padding-right: $padding--m;
    border-right: 1px solid var(--color-grey20);
    background-color: $white;

    & > img {
      height: $label-logo-dimension;
      width: $label-logo-dimension;
    }
  }
}
