@import 'css/base/margin.scss';
@import 'css/base/color.scss';

.form-divider.content-block-simple-divider {
  margin: $margin--large 0;
  opacity: 1;

  &:after {
    border-bottom-color: $grey--20;
    border-bottom-width: 1px;
  }
}
