@import 'css/base/color.scss';
@import 'css/base/padding.scss';
@import 'css/base/spacing.scss';
@import 'css/base/typography.scss';


.mutually-exclusive-selects {
  width: 100%;

  > div {
    &:not(:first-child) {
      margin-top: $spacing--12;
    }
  }

  .select-wrapper {
    margin-top: $spacing--8;
  }

  .select-alt--root {
    margin-left: 0;
    margin-right: 0;
  }
}
