@import 'css/base/margin.scss';
@import 'css/base/padding.scss';
@import 'css/base/typography.scss';


.payment-method-segment {
  &:not(:last-child) {
    margin-bottom: $margin--large;
  }

  // Adding .segment here due to specificity, we have a style in src/modules/dashboard/settings/global/presenters/Settings.scss
  // which adds a margin-top to all segments inside .settings-wrapper 
  &.segment:first-child {
    margin-top: 0;
  }

  &--body {
    background-color: $blue--xx-light;
  }

  &--settings {
    background: white;

    padding: $padding--m-large;
    // Adjust spacing so we can override the body background color on this section
    margin-top: -$padding--m-large;
    margin-right: -$padding--m-large;
    margin-left: -$padding--m-large;

    display: flex;
    flex-direction: column;
    gap: $margin--m;

    border-bottom: 1px solid var(--color-grey20);
  }
}
