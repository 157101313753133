@import '../../../../global/css/base/color.scss';
@import '../../../../global/css/base/padding.scss';
@import '../../../../global/css/base/typography.scss';
@import '../../../../global/css/mixins/alignment.scss';
@import '../../../../global/css/mixins/includeMedia.scss';
@import '../../../../global/css/mixins/boxShadow.scss';
@import '../../../../global/css/mixins/flexbox.scss';


.error-card--container {
  @include flex-col-center();
  @include align-items(center);
  height: 100%;
  min-height: 410px;

  .error-card--body {
    @include flexbox();
    @include flex-flow(column nowrap);
    @include align-items(center);

    @include box-shadow--extra-light();

    background-color: $white;
    border: 1px solid var(--color-grey20);
    border-radius: 6px;
    padding: $padding--x-large;
    max-width: 460px;

    @include media("<tablet") {
      padding: $padding--x-large $padding--large;
    }

    &.no-border {
      border: none !important;
      box-shadow: none !important;
    }

    .error-card--img {
      width: 275px;
      margin-bottom: 35px;
    }

    .error-card--header {
      color: $grey--xx-dark;
      font-size: $font-larger;
      margin-bottom: $spacing--12;
      text-align: center;
    }

    .error-card--reason {
      color: $grey--xx-dark;
      font-size: $font-m;
      font-weight: $regular;
      line-height: $line-height--24;
      margin: 0;
      max-width: 380px;
      text-align: center;
    }
  }
}
