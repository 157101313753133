@import 'css/base/color.scss';
@import 'css/base/typography.scss';
@import 'css/base/spacing.scss';

.search-x-icon {
    padding: $spacing--6;
    font-size: $spacing--8;
    font-weight: $bold;
    color: $dark-jordan;
    background-color: $blue--x-light;
    border-radius: 50%;
    vertical-align: middle;

    &:hover {
        background-color: var(--color-grey20);
    }
}
