@import 'css/base/color.scss';
@import 'css/base/margin.scss';
@import 'css/base/typography.scss';
@import 'css/variables/global.scss';
@import 'css/base/margin.scss';
@import 'css/mixins/flexbox.scss';


$image-height: 125px;
$image-width: 135px;
$border-radius: 6px;
$footer-height: 67px;
$line-height--text: 20px;
$margin-top--text: 6px;

.bounced-email--image {
  height: $image-height;
  width: $image-width;
  margin-top: $margin--large;
}

.bounced-email--title {
  display: block;
  // workaround to have the right amount of margin
  // because the image is not properly cropped
  margin-top: -20px;
}

.bounced-email--text {
  color: $grey--xx-dark;
  font-size: $font-sm;
  line-height: $line-height--text;
  margin-top: $margin-top--text;
  margin-bottom: $margin--m;
}

.bounced-email--footer {
  @include flexbox();
  @include align-items(center);
  @include justify-content(space-between);
  background-color: $red--light;
  border-bottom-left-radius: $border-radius;
  border-bottom-right-radius: $border-radius;
  height: $footer-height;
  margin: $margin--large $margin--large*-1 $margin--large*-1 $margin--large*-1;
  padding: 0 $spacing--24;
}

// change icon color to white on hover
.ghost--primary {
  &:hover {
    svg {
      fill: $white !important;
    }
  }
}
