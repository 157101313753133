@import 'css/base/margin.scss';
@import 'css/mixins/flexbox.scss';

.step-header {
  margin-bottom: 0;

  &.step-has-content-above {
    margin-top: $margin--x-large;
  }

  .step-header--instruction {
    display: table;
    width: 100%;

    & > div.content--left {
      display: table-cell;
      width: 50%;
      vertical-align: middle;
      line-height: 26px;
    }

    & > div.content--right {
      display: table-cell;
      text-align: right;
      vertical-align: middle;
      line-height: 26px;
      @include align-items(center);
    }
  }
}
