@import 'css/base/block.scss';
@import 'css/base/color.scss';
@import 'css/base/padding.scss';
@import 'css/base/margin.scss';
@import 'css/base/typography.scss';
@import 'css/mixins/flexbox.scss';
@import 'css/variables/global.scss';


$border--radius: $spacing--8;


div.pagination-bar {
  @include flexbox();

  width: 100%;
  padding: $spacing--16;
  background-color: $white;
  border-top-left-radius: $border--radius;
  border-top-right-radius: $border--radius;
  border: 1px solid var(--color-grey20);

  .pagination-bar-button {
    &:not(.no-margin-right) {
      margin-right: $spacing--6;
    }

    > .btn-v2 {
      height: $spacing--32;
    }
  }

  > div {
    @include flexbox();
    @include align-self(center);

    &.left {
      @include flexbox();
      @include flex-direction(column);
    }

    &.right {
      @include flexbox();
      margin-left: auto;
    }
  }

  .filter-result-strip {
    @include flexbox();
    @include align-items(center);

    margin-left: $margin--m;
  }

  .remaining-filters {
    cursor: pointer;
    user-select: none;
  }
}

.pagination-filter-bar {
  @include flexbox();

  font-size: $font-xs;
  color: $grey--x-dark;
  border: $border--aqua-bold;
  border-radius: $block-border-radius--sm;
  margin-right: $margin--m;
  overflow: hidden;

  &.within-menu {
    margin: 0;
    border: 0;
    border-bottom: 1px solid $main-silver;
    border-radius: 0;

    .filter-label {
      width: 50%;
    }

    &:last-child {
      border: 0;
    }
  }

  .filter-label {
    font-weight: $semibold;
    background-color: $blue--x-light;
    padding: $padding--xm;
    border-right: 1px solid var(--color-grey20);
    white-space: nowrap;
  }

  .filter-value {
    background-color: $white;
    padding: $padding--xm;
    white-space: nowrap;

    &.with-icon {
      margin-top: 1px;
      padding: $padding--sm $padding--xm;
    }
  }
}

.pagination--divider {
  .pagination--divider-inner {
    width: 1px;
    height: 100%;
    background-color: var(--color-grey20);
  }
}
