@import 'css/base/color.scss';
@import 'css/base/margin.scss';
@import 'css/base/table.scss';
@import 'css/base/typography.scss';
@import 'css/mixins/flexbox.scss';
@import 'css/mixins/includeMedia.scss';
@import 'css/variables/global.scss';

$external-items-list---padding: 16px;

.external-items-list,
.external-payments-list {
  // Bring attention to the item table beneath
  h4 {
    padding: $external-items-list---padding;
    background-color: lighten($light-yellow, 10%);
    border: 1px solid darken($light-yellow, 1%);
    border-radius: 6px;
    margin-top: $margin--large;
  }

  .external-items-table-container {
    // The cool way to do a border on the outside of the container element
    // without having to calculate widths
    box-shadow: 0 0 0 1px var(--color-grey20);
    border-radius: $table-border-radius;
  }

  .invoice-table {
    width: auto;
    border: none;
    margin-bottom: 0;

    .ReactVirtualized__Table__headerRow {
      border-top-left-radius: $table-border-radius;
      border-top-right-radius: $table-border-radius;
    }

    .ReactVirtualized__Table__row {
      &:last-child {
        border-bottom: none;
        border-bottom-left-radius: $table-border-radius;
        border-bottom-right-radius: $table-border-radius;
      }
    }

    .ReactVirtualized__Table__headerColumn {
      &:first-child {
        padding-left: 14px;
      }

      // Hide certain columns on mobile
      @include media('<=tablet') {
        &:not(.show-mobile) {
          display: none;
        }
      }
    }

    .ReactVirtualized__Table__rowColumn {
      span {
        &.like-p {
          color: $grey--x-dark;
          font-size: $font-xs;
          font-weight: $semibold;
        }

        &.not-available {
          color: $main-jordan;
          font-style: italic;
        }
      }

      @include media('<=tablet') {
        &:not(.show-mobile) {
          display: none;
        }
      }
    }
  }
  &--body {
    margin: 0 auto;
    padding: $external-items-list---padding 0;
    max-width: $external-page--max-width;
  }
  
  &-table-column--text {
    pointer-events: none;
  }
  
  &-table-column--status {
    .global-status--text {
      font-size: $font-xs !important;
    }
  }
}

