@import "css/base/margin.scss";
@import "css/mixins/flexbox.scss";

.external-receipt-attachments {
  .document-v2 {
    .document-form {
      @include flexbox();
      @include align-items(center);
      @include justify-content(space-between);

      height: auto;

      &--left {
        padding-left: 0 !important;
      }

      &--right {
        padding-right: 0 !important;
      }
    }

    &:first-child {
      margin-top: $margin--xm;
    }

    &:not(:last-child) {
      margin-bottom: $margin--xm;
    }
  }
}