@import '../../../../../global/css/base/color.scss';
@import '../../../../../global/css/base/margin.scss';
@import '../../../../../global/css/base/typography.scss';
@import '../../../../../global/css/mixins/flexbox.scss';
@import '../../../../../global/css/mixins/includeMedia.scss';
@import '../../../../../global/css/variables/global.scss';


.external-header--container {
  width: 100vw;
  margin: 0 auto;
}

.external-header {
  @include flexbox();
  @include align-items(center);
  @include justify-content(space-between);
  width: 100%;
  padding: $margin--m;

  background-color: $white;
  border-bottom: 2px solid $blue--x-light;

  @include media(">=tablet") {
    max-height: $nav--height;
    padding: $margin--large;
  }
}
