@import '../base/color.scss';
@import '../base/margin.scss';
@import '../base/typography.scss';
@import './global.scss';
@import '../mixins/includeMedia.scss';

$line-height--sm: 18px;

.panel-wrapper {
  width: 50%;
  max-width: 420px;
  margin-bottom: 30px;

  @media(max-width: 880px) {
    width: 100%;
  }
}

.panel {
  width: 100%;
  margin-bottom: 20px;

  background-color: $white;
  border: 1px solid var(--color-grey20);
  border-radius: 8px;
  box-shadow: -2px 3px 0 0 $blue--x-light;

  &.external-receipt {
    margin-bottom: 0;

    border-radius: 16px;
  }

  &.error {
    background-color: $red--light;
    border: 1px solid $red--light;
  }

  &.ledger-invoice--total {
    display: inline-block;
    margin-bottom: 0;

    background-color: $panel-background;
    border: $border--blue-light;
    border-radius: 10px;
    box-shadow: none;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &.settings {
    background-color: $blue--xx-light;
    box-shadow: -2px 3px 1px 0 $blue--x-light;

    &.payment-method {
      background-color: $blue--x-light;
      border: 1px solid var(--color-grey20);
      box-shadow: 0 2px 2px 0 $blue--x-light;
    }

    &.ledger-match {
      margin-top: 30px;
    }
  }

  &:last-child {
    margin-bottom: 8px;
  }

  &:only-child {
    margin-bottom: 0;
  }
}

.panel-heading {
  display: table;
  width: 100%;
  padding: 20px;

  border-bottom: 1px solid var(--color-grey20);
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;

  > .dropdown .dropdown-toggle {
    color: inherit;
  }

  &.no-panel-body {
    border-bottom-left-radius: 7px;
    border-bottom-right-radius: 7px;
  }

  &.external-receipt {
    background-color: $blue--x-light;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
  }

  &.bank-account,
  &.ledger-match {
    padding: 16px 20px;

    @include media("<=phone_large") {
      position: relative;
    }
  }

  &.payment-method {
    padding: 12px 16px;
  }

  .panel-heading--row {
    display: table-row;

    .panel-heading--title {
      display: table-cell;
      width: 50%;
      vertical-align: middle;

      margin-top: 0;
      margin-bottom: 0;
      margin-right: auto;

      color: $grey--x-dark;

      &.bank-account--title-row {
        width: 75%;

        @include media("<=phone_large") {
          width: 90%;
        }
      }

      &.external-receipt--amount {
        h3 {
          margin-bottom: 0;
        }
      }

      span {
        display: inline-block;
        vertical-align: middle;
        line-height: $line-height--sm;

        color: $dark-silver;
      }

      p {
        display: inline-block;
        vertical-align: middle;
        line-height: $line-height--sm;
        margin: 0 0 0 10px;
        font-weight: $semibold;

        @include media("<=phone") {
          margin-left: $margin--sm;
        }
      }

      > {
        a, small, .small, small > a, .small > a {
          color: inherit;
        }
      }
    }

    .panel-heading--subtext {
      display: table-cell;
      width: 50%;
      vertical-align: middle;
      text-align: right;

      &.bank-account--title-row {
        width: 25%;

        // Move the subtext down into the body on phone portrait
        @include media("<=phone_large") {
          position: absolute;
          top: 68px;
          right: 20px;
          width: auto;
        }

        @include media("<=phone") {
          top: 56px;
        }
      }

      &.external-receipt--amount {
        h3 {
          color: $blue--bold;
          margin-bottom: 0;
        }
      }

      &.verification {
        p {
          margin: 0 8px 0 0;
        }

        &.verified {
          p, span {
            color: $aqua--bold;
          }
        }

        &.unverified {
          p {
            color: $main-jordan;
          }
          span {
            color: var(--color-grey20);
          }
        }
      }

      span {
        display: inline-block;
        vertical-align: middle;
        line-height: $line-height--sm;
      }

      p {
        display: inline-block;
        vertical-align: middle;
        line-height: $line-height--sm;

        @include media("<=phone_large") {
          font-size: $font-xs;
        }
      }
    }
  }
}

.panel-body {
  display: table;
  width: 100%;
  padding: 28px 20px;
  border-bottom: 1px solid var(--color-grey20);

  .panel-body--title {
    margin-bottom: $margin--sm;
    pointer-events: none;
  }

  &.bank-account {
    padding: 16px 20px;

    &.payment-method {
      padding: 16px;
    }
  }

  &.ledger-match {
    padding: 16px 20px;
  }

  &.external--container {
    padding: 20px;

    background-color: $blue--xx-light;
    border-radius: 16px;
  }

  &.external-receipt {
    padding: 16px 20px;

    &.status {
      border-top: $border--inset-content;
      background-color: $blue--xx-light;

      &.pending {
        background-color: lighten($light-yellow, 12%);
      }

      &.completed {
        background-color: lighten($light-aqua, 32%);
      }

      &.initiated {
        background-color: $blue--x-light;
      }

      @include media(">phone") {
        padding: 12px 20px;
      }
    }

    &:last-child {
      border-bottom-right-radius: 15px;
      border-bottom-left-radius: 15px;
      padding-bottom: 16px;
    }
  }

  &.ledger-invoice {
    padding: 6px 20px 6px 18px;

    p {
      color: $grey--x-dark;
      font-size: $font-xs;
      font-weight: $bold;
    }

    > div.item > {
      .left, .right {
        width: 50%;
      }
    }

    &.total {
      background-color: $grey--x-dark;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;

      p {
        color: $blue--xx-light;
      }
    }
  }

  &.receipt {
    padding: 20px 20px;

    &:last-child {
      margin-bottom: 10px;

      &.payment-terms {
        margin-bottom: 0;

        background-color: lighten($light-yellow, 12%);
        border-bottom-right-radius: 8px;
        border-bottom-left-radius: 8px;
      }
    }
  }

  &.receipt--header {
    padding: 16px 20px 24px 20px;

    h1 {
      margin: 4px 0 6px 0;
      font-weight: $regular;
      font-size: 38px;

      > span {
        margin-left: 6px;

        font-size: $font-xs;
        color: $main-jordan;
        font-weight: $bold;
        letter-spacing: 1px;
        text-transform: uppercase;
      }
    }
  }

  .panel-body--title {
    color: $grey--x-dark;
    font-size: $font-regular;
    font-weight: $bold;

    &.search {
      color: $aqua--bold;
      margin-bottom: $margin--m;
    }
  }

  .panel-body--description {
    pointer-events: none;

    color: $dark-jordan;
    font-size: $font-regular;
    line-height: 1.6;
  }

  .item {
    display: table-row;
    height: 30px;

    &.receipt > p, div > p {
      margin-bottom: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }

    @include media("<phone_large") {

      &.external-receipt {
        height: auto;
      }

      &:not(:last-child) {
        display: block;
        margin-bottom: $margin--m;
      }
    }

    .left {
      display: block;
      width: 100%;
      line-height: 30px;

      @include media(">=phone_large") {
        display: table-cell;
        width: 30%;
        vertical-align: middle;
        margin-right: auto;
      }

      &.toggle {
        width: 70%;
        height: 26px;

        p {
          margin-bottom: 0;

          color: $dark-jordan;
        }
      }

      &.external-receipt--status {
        line-height: 25px;
      }
    }

    .right {
      display: block;
      width: 100%;
      text-align: left;
      line-height: 30px;

      @include media(">=phone_large") {
        display: table-cell;
        width: 70%;
        vertical-align: middle;
        margin-left: auto;
        text-align: right;
      }

      &.toggle {
        width: 30%;

        span {
          line-height: $line-height--l;
          font-size: $font-xs;
          font-weight: $semibold;
          color: $main-jordan;

          &.toggle-text {
            margin-left: 0.8em;
          }
        }
      }

      &.has-avatar {
        > div.child-left {
            display: inline-block;

          p {
            margin-bottom: 0;
            padding-right: 10px;
          }
        }

        > div.child-right {
          display: inline-block;
          float: right;
          height: 30px;
        }
      }

      & > .global-status {
        width: initial;

        @include media(">phone_large") {
          margin-left: auto;
        }
      }
    }
  }

  &:last-child {
    height: 100%;
    border-bottom: 0;
  }
}

.panel-footer {
  padding: 10px 15px;
  background-color: #f5f5f5;
  border-top: 1px solid var(--color-grey20);
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
}

.panel > {
  .list-group, .panel-collapse > .list-group {
    margin-bottom: 0;
  }
  .list-group .list-group-item, .panel-collapse > .list-group .list-group-item {
    border-width: 1px 0;
    border-radius: 0;
  }
  .list-group:first-child .list-group-item:first-child, .panel-collapse > .list-group:first-child .list-group-item:first-child {
    border-top: 0;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
  }
  .list-group:last-child .list-group-item:last-child, .panel-collapse > .list-group:last-child .list-group-item:last-child {
    border-bottom: 0;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
  }
  .panel-heading + .panel-collapse > .list-group .list-group-item:first-child {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}

.panel-heading + .list-group .list-group-item:first-child, .list-group + .panel-footer {
  border-top-width: 0;
}

.panel > {
  .table, .table-responsive > .table, .panel-collapse > .table {
    margin-bottom: 0;
  }
  .table caption, .table-responsive > .table caption, .panel-collapse > .table caption {
    padding-right: 15px;
    padding-left: 15px;
  }
  .table:first-child, .table-responsive:first-child > .table:first-child, .table:first-child > thead:first-child > tr:first-child, .table-responsive:first-child > .table:first-child > thead:first-child > tr:first-child, .table:first-child > tbody:first-child > tr:first-child, .table-responsive:first-child > .table:first-child > tbody:first-child > tr:first-child {
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
  }
  .table:first-child > thead:first-child > tr:first-child td:first-child, .table-responsive:first-child > .table:first-child > thead:first-child > tr:first-child td:first-child, .table:first-child > tbody:first-child > tr:first-child td:first-child, .table-responsive:first-child > .table:first-child > tbody:first-child > tr:first-child td:first-child, .table:first-child > thead:first-child > tr:first-child th:first-child, .table-responsive:first-child > .table:first-child > thead:first-child > tr:first-child th:first-child, .table:first-child > tbody:first-child > tr:first-child th:first-child, .table-responsive:first-child > .table:first-child > tbody:first-child > tr:first-child th:first-child {
    border-top-left-radius: 3px;
  }
  .table:first-child > thead:first-child > tr:first-child td:last-child, .table-responsive:first-child > .table:first-child > thead:first-child > tr:first-child td:last-child, .table:first-child > tbody:first-child > tr:first-child td:last-child, .table-responsive:first-child > .table:first-child > tbody:first-child > tr:first-child td:last-child, .table:first-child > thead:first-child > tr:first-child th:last-child, .table-responsive:first-child > .table:first-child > thead:first-child > tr:first-child th:last-child, .table:first-child > tbody:first-child > tr:first-child th:last-child, .table-responsive:first-child > .table:first-child > tbody:first-child > tr:first-child th:last-child {
    border-top-right-radius: 3px;
  }
  .table:last-child, .table-responsive:last-child > .table:last-child, .table:last-child > tbody:last-child > tr:last-child, .table-responsive:last-child > .table:last-child > tbody:last-child > tr:last-child, .table:last-child > tfoot:last-child > tr:last-child, .table-responsive:last-child > .table:last-child > tfoot:last-child > tr:last-child {
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
  }
  .table:last-child > tbody:last-child > tr:last-child td:first-child, .table-responsive:last-child > .table:last-child > tbody:last-child > tr:last-child td:first-child, .table:last-child > tfoot:last-child > tr:last-child td:first-child, .table-responsive:last-child > .table:last-child > tfoot:last-child > tr:last-child td:first-child, .table:last-child > tbody:last-child > tr:last-child th:first-child, .table-responsive:last-child > .table:last-child > tbody:last-child > tr:last-child th:first-child, .table:last-child > tfoot:last-child > tr:last-child th:first-child, .table-responsive:last-child > .table:last-child > tfoot:last-child > tr:last-child th:first-child {
    border-bottom-left-radius: 3px;
  }
  .table:last-child > tbody:last-child > tr:last-child td:last-child, .table-responsive:last-child > .table:last-child > tbody:last-child > tr:last-child td:last-child, .table:last-child > tfoot:last-child > tr:last-child td:last-child, .table-responsive:last-child > .table:last-child > tfoot:last-child > tr:last-child td:last-child, .table:last-child > tbody:last-child > tr:last-child th:last-child, .table-responsive:last-child > .table:last-child > tbody:last-child > tr:last-child th:last-child, .table:last-child > tfoot:last-child > tr:last-child th:last-child, .table-responsive:last-child > .table:last-child > tfoot:last-child > tr:last-child th:last-child {
    border-bottom-right-radius: 3px;
  }
  .panel-body + {
    .table, .table-responsive {
      border-top: 1px solid #ddd;
    }
  }
  .table + .panel-body, .table-responsive + .panel-body {
    border-top: 1px solid #ddd;
  }
  .table > tbody:first-child > tr:first-child {
    th, td {
      border-top: 0;
    }
  }
  .table-bordered, .table-responsive > .table-bordered {
    border: 0;
  }
  .table-bordered > thead > tr > th:first-child, .table-responsive > .table-bordered > thead > tr > th:first-child, .table-bordered > tbody > tr > th:first-child, .table-responsive > .table-bordered > tbody > tr > th:first-child, .table-bordered > tfoot > tr > th:first-child, .table-responsive > .table-bordered > tfoot > tr > th:first-child, .table-bordered > thead > tr > td:first-child, .table-responsive > .table-bordered > thead > tr > td:first-child, .table-bordered > tbody > tr > td:first-child, .table-responsive > .table-bordered > tbody > tr > td:first-child, .table-bordered > tfoot > tr > td:first-child, .table-responsive > .table-bordered > tfoot > tr > td:first-child {
    border-left: 0;
  }
  .table-bordered > thead > tr > th:last-child, .table-responsive > .table-bordered > thead > tr > th:last-child, .table-bordered > tbody > tr > th:last-child, .table-responsive > .table-bordered > tbody > tr > th:last-child, .table-bordered > tfoot > tr > th:last-child, .table-responsive > .table-bordered > tfoot > tr > th:last-child, .table-bordered > thead > tr > td:last-child, .table-responsive > .table-bordered > thead > tr > td:last-child, .table-bordered > tbody > tr > td:last-child, .table-responsive > .table-bordered > tbody > tr > td:last-child, .table-bordered > tfoot > tr > td:last-child, .table-responsive > .table-bordered > tfoot > tr > td:last-child {
    border-right: 0;
  }
  .table-bordered > thead > tr:first-child > td, .table-responsive > .table-bordered > thead > tr:first-child > td, .table-bordered > tbody > tr:first-child > td, .table-responsive > .table-bordered > tbody > tr:first-child > td, .table-bordered > thead > tr:first-child > th, .table-responsive > .table-bordered > thead > tr:first-child > th, .table-bordered > tbody > tr:first-child > th, .table-responsive > .table-bordered > tbody > tr:first-child > th, .table-bordered > tbody > tr:last-child > td, .table-responsive > .table-bordered > tbody > tr:last-child > td, .table-bordered > tfoot > tr:last-child > td, .table-responsive > .table-bordered > tfoot > tr:last-child > td, .table-bordered > tbody > tr:last-child > th, .table-responsive > .table-bordered > tbody > tr:last-child > th, .table-bordered > tfoot > tr:last-child > th {
    border-bottom: 0;
  }
  .table-responsive {
    > .table-bordered > tfoot > tr:last-child > th {
      border-bottom: 0;
    }
    margin-bottom: 0;
    border: 0;
  }
}

.panel-group {
  margin-bottom: 20px;
  .panel {
    margin-bottom: 0;
    border-radius: 4px;
    + .panel {
      margin-top: 5px;
    }
  }
  .panel-heading {
    border-bottom: 0;
    + .panel-collapse > {
      .panel-body, .list-group {
        border-top: 1px solid #ddd;
      }
    }
  }
  .panel-footer {
    border-top: 0;
    + .panel-collapse .panel-body {
      border-bottom: 1px solid #ddd;
    }
  }
}

.panel-default {
  border-color: darken($grey--20, 01%);
  > {
    .panel-heading {
      background-color: $blue--x-light;
      border-color: var(--color-grey20);

      &.settings {
        background-color: $white;
      }

      + .panel-collapse > .panel-body {
        border-top-color: var(--color-grey20);
      }
      .badge {
        background-color: var(--color-grey20);
      }
    }
    .panel-footer + .panel-collapse > .panel-body {
      border-bottom-color: var(--color-grey20);
    }
  }
}

.panel-primary {
  border-color: #337ab7;
  > {
    .panel-heading {
      color: #fff;
      background-color: #337ab7;
      border-color: #337ab7;
      + .panel-collapse > .panel-body {
        border-top-color: #337ab7;
      }
      .badge {
        color: #337ab7;
        background-color: #fff;
      }
    }
    .panel-footer + .panel-collapse > .panel-body {
      border-bottom-color: #337ab7;
    }
  }
}

.panel-success {
  border-color: #d6e9c6;
  > {
    .panel-heading {
      color: #3c763d;
      background-color: #dff0d8;
      border-color: #d6e9c6;
      + .panel-collapse > .panel-body {
        border-top-color: #d6e9c6;
      }
      .badge {
        color: #dff0d8;
        background-color: #3c763d;
      }
    }
    .panel-footer + .panel-collapse > .panel-body {
      border-bottom-color: #d6e9c6;
    }
  }
}

.panel-info {
  border-color: #bce8f1;
  > {
    .panel-heading {
      color: #31708f;
      background-color: #d9edf7;
      border-color: #bce8f1;
      + .panel-collapse > .panel-body {
        border-top-color: #bce8f1;
      }
      .badge {
        color: #d9edf7;
        background-color: #31708f;
      }
    }
    .panel-footer + .panel-collapse > .panel-body {
      border-bottom-color: #bce8f1;
    }
  }
}

.panel-warning {
  border-color: #faebcc;
  > {
    .panel-heading {
      color: #8a6d3b;
      background-color: #fcf8e3;
      border-color: #faebcc;
      + .panel-collapse > .panel-body {
        border-top-color: #faebcc;
      }
      .badge {
        color: #fcf8e3;
        background-color: #8a6d3b;
      }
    }
    .panel-footer + .panel-collapse > .panel-body {
      border-bottom-color: #faebcc;
    }
  }
}

.panel-danger {
  border-color: #ebccd1;
  > {
    .panel-heading {
      color: #a94442;
      background-color: #f2dede;
      border-color: #ebccd1;
      + .panel-collapse > .panel-body {
        border-top-color: #ebccd1;
      }
      .badge {
        color: #f2dede;
        background-color: #a94442;
      }
    }
    .panel-footer + .panel-collapse > .panel-body {
      border-bottom-color: #ebccd1;
    }
  }
}
