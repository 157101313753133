@import 'css/base/block.scss';
@import 'css/base/color.scss';
@import 'css/base/margin.scss';
@import 'css/base/padding.scss';
@import 'css/base/sizes.scss';
@import 'css/base/typography.scss';
@import 'css/mixins/borders.scss';
@import 'css/mixins/colors.scss';
@import 'css/mixins/flexbox.scss';
@import 'css/mixins/transitions.scss';


/*
  the tag component is built in pieces to work with react-select's props.components
  structure, so the label and close-icon appear unified, but are rendered separately.

  to that end, the element containing the close icon is built in two layers-
  one with the tag background color on it, and a foreground overlay with 90% opacity
  that transitions in on hover. both elements need dimension (inline-XYZ).

  this layout is great for react-select but can cause a <=1px aliasing issue around
  the hovered foreground element (more or less noticeable depending on scroll position).
  can avoid this by setting the inner element border-radius to that of it's parent, minus 1px.
  a small negative margin (which also accomplishes perfectly aligning inline-block siblings)
  offsets overflow.
*/
$tag-inset-border-radius: $block-border-radius--sm - 1px;

// the 'div.tag' was added to make more specific. storybook uses this same class name
// in their docs: 'show code' window in Docs, so making it be as specific as possible
// to avoid collisions.
div.tag {
  @include align-items(center);
  @include inline-flex();
  @include justify-content(center);

  height: $tag-height;

  outline: 0;
  vertical-align: middle;
  white-space: nowrap;

  padding: $padding--sm $padding--m;

  border-radius: $block-border-radius--sm;
  border: 0;

  cursor: pointer;

  .tag-close {
    cursor: pointer;
    background: $white;
  }

  &.readOnly {
    cursor: initial;
  }

  &.warning, &.locked, &.developer {
    background: $yellow--light;

    .tag-icon {
      color: $yellow--medium;
    }

    .tag-label-text {
      // !important to override BaseText default inline style color
      color: $yellow--dark !important;
    }

    .tag-close-icon {
      color: $yellow--bold;
    }

    &:hover .tag-close {
      background: $yellow--bold;

      .tag-close-icon {
        color: $white;
      }
    }
  }

  &.info, &.primary, &.primary_and_preferred, &.manage_items, &.creator {
    background: $blue--x-light;

    .tag-icon {
      color: $blue--bold;
    }

    .tag-label-text {
      // !important to override BaseText default inline style color
      color: $blue--dark !important;
    }

    .tag-close-icon {
      color: $blue--bold;
    }

    &:hover .tag-close {
      background: $blue--bold;

      .tag-close-icon {
        color: $white;
      }
    }
  }

  &.misc {
    background: $purple--light;

    .tag-icon {
      color: $purple--bold;
    }

    .tag-label-text {
      // !important to override BaseText default inline style color
      color: $purple--dark !important;
    }

    .tag-close-icon {
      color: $purple--bold;
    }

    &:hover .tag-close {
      background: $purple--bold;

      .tag-close-icon {
        color: $white;
      }
    }
  }

  &.preferred, &.error, &.vendor_and_customer_manager {
    background: $red--light;

    .tag-icon {
      color: $red--bold;
    }

    .tag-label-text {
      // !important to override BaseText default inline style color
      color: $red--dark !important;
    }

    .tag-close-icon {
      color: $red--bold;
    }

    &:hover .tag-close {
      background: $red--bold;

      .tag-close-icon {
        color: $white;
      }
    }
  }

  &.success, &.manage_company, &.administrator {
    background: $green--light;

    .tag-icon {
      color: $green--bold;
    }

    .tag-label-text {
      // !important to override BaseText default inline style color
      color: $green--dark !important;
    }

    .tag-close-icon {
      color: $green--bold;
    }

    &:hover .tag-close {
      background: $green--bold;

      .tag-close-icon {
        color: $white;
      }
    }
  }

  &.note, &.approver {
    background: $purple--light;

    .tag-icon {
      color: $purple--bold;
    }

    .tag-label-text {
      // !important to override BaseText default inline style color
      color: $purple--dark !important;
    }

    .tag-close-icon {
      color: $purple--bold;
    }

    &:hover .tag-close {
      background: $purple--bold;

      .tag-close-icon {
        color: $white;
      }
    }
  }

  // archive and default have same styles
  &.archived,
  &.bounced_email,
  &.collaborator,
  &.default,
  &.it_admin,
  &.no_email,
  &.self_managed,
  &.variable
  {
    background: $blue--x-light;

    .tag-icon {
      color: $grey--xx-dark;
    }

    .tag-label-text {
      // !important to override BaseText default inline style color
      color: $grey--xx-dark !important;
    }

    .tag-close-icon {
      color: $grey--xx-dark;
    }

    &:hover .tag-close {
      background: $grey--xx-dark;

      .tag-close-icon {
        color: $white;
      }
    }
  }

  &.meta {
    background: $white;
    border: 1px solid $grey--x-light;

    .tag-icon {
      color: $grey--x-dark;
    }

    .tag-label-text {
      // !important to override BaseText default inline style color
      color: $black !important;
    }

    .tag-close {
      background: var(--color-grey20);

      .tag-close-icon {
        color: $grey--x-dark;
      }
    }

    &:hover .tag-close {
      background: $grey--x-dark;

      .tag-close-icon {
        color: $white;
      }
    }
  }

  // while we support styles for multiselect here, the tags used in TagsMultiSelect
  // uses different JSX/CSS because react-select requires a different component tree structure
  // than the one we have here for Tag.js (ie. the wrapper, the text and the "x" components need
  // to all be different components instead). You can use this tag anywhere else in the app
  // but if you edit the styles here, make sure to edit the styles
  &.multiselect {
    background: $white;

    .tag-icon {
      color: $blue--bold;
    }

    .tag-label-text {
      // !important to override BaseText default inline style color
      color: $blue--bold !important;
    }

    .tag-close {
      background: $blue--x-light;

      .tag-close-icon {
        color: $blue--bold;
      }
    }

    &:hover {
      .tag-icon {
        color: $red--bold;
      }

      .tag-label-text {
        color: $red--bold !important;
      }

      .tag-close {
        background: $red--light;

        .tag-close-icon {
          color: $red--bold;
        }
      }
    }
  }

  .tag-icon {
    @include flex-col-center();

    margin-right: $spacing--6;

    svg {
      vertical-align: middle;
    }
  }

  .tag-label {
    @include inline-flex();
    border-radius: $block-border-radius--sm;
    overflow: hidden;
    text-overflow: ellipsis;
    user-select: none;
    white-space: nowrap;
  }

  .tag-close {
    @include flexbox();
    margin-left: $spacing--8;

    border-radius: 50%;
    padding: $padding--xs;
  }

  &.flair-tag {
    .tag-icon {
      margin-right: 0;
    }
  }

  &.square {
    width: $tag-height;
    padding: 0 $padding--m-large;

    .tag-icon {
      margin-right: 0;
    }

    .tag-close {
      margin-left: 0;
    }
  }

  &.round {
    width: $tag-height;
    border-radius: 50%;
    padding: 0;

    .tag-icon {
      margin-right: 0;
    }

    .tag-close {
      margin-left: 0;
    }
  }
}
