@import '../base/color.scss';
@import '../base/typography.scss';

$dd-menu--border-radius: 8px;
$dd-menu-box-shadow: 0px 4px 16px rgba(60, 101, 241, 0.15);

.dd-menu {
  display: block !important;
}

.dd-menu-items {
  width: inherit !important;
  margin-top: 38px !important;

  & > ul {
    border-radius: $dd-menu--border-radius;
    box-shadow: $dd-menu-box-shadow !important; // override the library default

    li {
      border-bottom: 1px solid var(--color-grey20);
      border-radius: 0;

      a,
      > div {
        width: 100% !important;

        p.link--title {
          margin-bottom: 0;

          color: $grey--x-dark;
          font-size: $font-xs;
        }

        span.link--description {
          margin-left: 0;

          color: $dark-jordan;
          font-size: $font-xxs;
        }

        &:focus {
          background-color: transparent !important;
        }
      }

      &:first-child {
        border-top-right-radius: $dd-menu--border-radius !important;
        border-top-left-radius: $dd-menu--border-radius !important;
      }

      &:last-child {
        border-bottom-right-radius: $dd-menu--border-radius !important;
        border-bottom-left-radius: $dd-menu--border-radius !important;
      }

      &:hover, &:focus {
        background-color: $blue--x-light !important;
        outline: none;

        & > a,
        & > div {
          & > p {
            color: $primary;
          }
        }
      }
    }
  }

  &.dd-items-upwards {
    margin-bottom: 30px !important;
  }
}
