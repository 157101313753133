@import 'css/base/margin.scss';
@import 'css/mixins/flexbox.scss';
@import 'css/mixins/includeMedia.scss';


// Use ID because we had to set an ID on the <FormControl> anyway for the useOnElementResize hook.
#formControlForHorizontalRadios {
  &.stack {
    @include media("<=tablet") {
      @include flex-direction(column);

      .second-radio-option {
        margin-top: $margin--xm;
      }
    }
  }
}
