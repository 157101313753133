// Icons CSS
@font-face {
  font-family: 'icomoon';
  src:  url('/fonts/icomoon.eot');
  src:  url('/fonts/icomoon.eot') format('embedded-opentype'),
    url('/fonts/icomoon.ttf') format('truetype'),
    url('/fonts/icomoon.woff') format('woff'),
    url('/fonts/icomoon.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-container {
  text-decoration: none !important;
  display: table;
}

.icon-flip-180 {
  transform: rotate(180deg);
}

.icon-flip-90 {
  transform: rotate(90deg);
}

.icon-flip-270 {
  transform: rotate(270deg);
}

// Icon definitions

.icon-ic-alert:before {
  content: "\e900";
}
.icon-ic-all-payments:before {
  content: "\e901";
}
.icon-ic-arrow-back:before {
  content: "\e902";
}
.icon-ic-attachment:before {
  content: "\e903";
}
.icon-ic-bank:before {
  content: "\e904";
}
.icon-ic-bell:before {
  content: "\e905";
}
.icon-ic-bolt:before {
  content: "\e906";
}
.icon-ic-briefcase:before {
  content: "\e907";
}
.icon-ic-camera:before {
  content: "\e908";
}
.icon-ic-cc-front:before {
  content: "\e909";
}
.icon-ic-cc:before {
  content: "\e90a";
}
.icon-ic-checkmark:before {
  content: "\e90b";
}
.icon-ic-chevron-sharp:before {
  content: "\e90c";
}
.icon-ic-chevron:before {
  content: "\e90d";
}
.icon-ic-companies:before {
  content: "\e90e";
}
.icon-ic-compass:before {
  content: "\e90f";
}
.icon-ic-contact:before {
  content: "\e910";
}
.icon-ic-decline:before {
  content: "\e911";
}
.icon-ic-doc-verified:before {
  content: "\e912";
}
.icon-ic-doc:before {
  content: "\e913";
}
.icon-ic-document-edit:before {
  content: "\e914";
}
.icon-ic-documentation:before {
  content: "\e915";
}
.icon-ic-download:before {
  content: "\e916";
}
.icon-ic-edit:before {
  content: "\e917";
}
.icon-ic-filters:before {
  content: "\e918";
}
.icon-ic-flag:before {
  content: "\e919";
}
.icon-ic-handshake:before {
  content: "\e91a";
}
.icon-ic-heart:before {
  content: "\e91b";
}
.icon-ic-image:before {
  content: "\e91c";
}
.icon-ic-info:before {
  content: "\e91d";
}
.icon-ic-integration:before {
  content: "\e91e";
}
.icon-ic-link:before {
  content: "\e91f";
}
.icon-ic-message:before {
  content: "\e920";
}
.icon-ic-mult-check:before {
  content: "\e921";
}
.icon-ic-note:before {
  content: "\e922";
}
.icon-ic-numbers:before {
  content: "\e923";
}
.icon-ic-payments-draft:before {
  content: "\e924";
}
.icon-ic-payments-payables:before {
  content: "\e925";
}
.icon-ic-payments-receivables:before {
  content: "\e926";
}
.icon-ic-payments-inbox:before {
  content: "\e927";
}
.icon-ic-people:before {
  content: "\e928";
}
.icon-ic-pin:before {
  content: "\e929";
}
.icon-ic-play:before {
  content: "\e92a";
}
.icon-ic-plus:before {
  content: "\e92b";
}
.icon-ic-preview:before {
  content: "\e92c";
}
.icon-ic-question:before {
  content: "\e92d";
}
.icon-ic-receipt:before {
  content: "\e92e";
}
.icon-ic-refresh:before {
  content: "\e92f";
}
.icon-ic-reply:before {
  content: "\e930";
}
.icon-ic-reports:before {
  content: "\e931";
}
.icon-ic-search:before {
  content: "\e932";
}
.icon-ic-settings:before {
  content: "\e933";
}
.icon-ic-shield:before {
  content: "\e934";
}
.icon-ic-skip:before {
  content: "\e935";
}
.icon-ic-star:before {
  content: "\e936";
}
.icon-ic-stop:before {
  content: "\e937";
}
.icon-ic-tag:before {
  content: "\e938";
}
.icon-ic-tax:before {
  content: "\e939";
}
.icon-ic-text-field:before {
  content: "\e93a";
}
.icon-ic-track:before {
  content: "\e93b";
}
.icon-ic-trash:before {
  content: "\e93c";
}
.icon-ic-tutorial:before {
  content: "\e93d";
}
.icon-ic-upload:before {
  content: "\e93e";
}
.icon-ic-warren:before {
  content: "\e93f";
}
.icon-ic-x-out:before {
  content: "\e940";
}
.icon-ic-ach:before {
  content: "\e941";
}
.icon-ic-arrow-thin:before {
  content: "\e942";
}
.icon-ic-calendar:before {
  content: "\e943";
}
.icon-ic-cash:before {
  content: "\e944";
}
.icon-ic-cc-back:before {
  content: "\e945";
}
.icon-ic-check:before {
  content: "\e946";
}
.icon-ic-contacts:before {
  content: "\e947";
}
.icon-ic-mail:before {
  content: "\e948";
}
.icon-ic-more:before {
  content: "\e949";
}
.icon-ic-notify:before {
  content: "\e94a";
}
.icon-ic-question-squared:before {
  content: "\e94b";
}
.icon-ic-send:before {
  content: "\e94c";
}
.icon-ic-checkmark-circle:before {
  content: "\e94d";
}
.icon-ic-landing-api:before {
  content: "\e94e";
}
.icon-ic-landing-contacts:before {
  content: "\e94f";
}
.icon-ic-landing-messaging:before {
  content: "\e950";
}
.icon-ic-landing-payables:before {
  content: "\e951";
}
.icon-ic-landing-payment-methods:before {
  content: "\e952";
}
.icon-ic-landing-payments:before {
  content: "\e953";
}
.icon-ic-landing-receivables:before {
  content: "\e954";
}
.icon-ic-landing-refresh:before {
  content: "\e955";
}
.icon-ic-lock:before {
  content: "\e956";
}
.icon-ic-me:before {
  content: "\e957";
}
.icon-ic-plus-full:before {
  content: "\e958";
}
.icon-ic-status:before {
  content: "\e959";
}
.icon-ic-warren-thick:before {
  content: "\e95a";
}
.icon-ic-logo-letter:before {
  content: "\e95b";
}

