@import 'css/base/block.scss';
@import 'css/base/color.scss';
@import 'css/base/padding.scss';
@import 'css/mixins/flexbox.scss';


.content-block-simple {
  @include flex-direction(column);
  padding: $padding--large;
  background-color: var(--color-grey05);
  border-radius: $block-border-radius--xm;
}

.no-background {
  padding: calc(#{$padding--large} - 1px);
  border: 1px solid var(--color-grey10);
  background-color: transparent;
}
