@import "css/base/color.scss";
@import "css/base/margin.scss";
@import "css/base/typography.scss";

.branded-error--container {
  .branded-error--logo-container {
    position: absolute;
    top: 30px;
  }

  .branded-error--help-container {
    margin-top: $margin--large;

    .branded-error--need-help {
      color: $grey--xx-dark;
      font-weight: $regular;
      font-size: $font-regular;
      margin-right: $margin--sm;
    }

    .branded-error--contact-us {
      color: $blue--bold;
      font-weight: $regular;
      font-size: $font-regular;
      text-decoration: none;
    }
  }
}
