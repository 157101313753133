@import '../../global/css/variables/global.scss';
@import '../../global/css/base/color.scss';
@import '../../global/css/base/typography.scss';
@import '../../global/css/mixins/flexbox.scss';
@import '../../global/css/mixins/includeMedia.scss';
@import '../../global/css/base/margin.scss';

$circle-r: 8px;

@mixin circle($circle-color) {
  width: $circle-r;
  height: $circle-r;
  border-radius: 50%;
  margin: 6px;
  line-height: 6px;

  background-color: $circle-color;

  .aligner {
    display: inline-block;
    height: 100%;
    text-align: center;
  }
}

@mixin step-text($text-color) {
  margin-left: $margin--sm;
  line-height: 24px;

  color: $text-color;
  font-family: $base-font-family;
  font-weight: $semibold;
  font-size: $font-m;

  @include media(">=tablet") {
    font-size: $font-regular;
    line-height: 6px;
  }
};

.progress-bar {
  background-color: $blue--xx-light;
  border-top: 2px solid var(--color-grey20);
  margin-top: $margin--large;
  text-align: center;
  height: auto;

  @include media(">=tablet") {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    height: $external-progress-bar--height;
    margin-top: 0;
  }

  &.external {
    background-color: $white;
    border-top: 1px solid var(--color-grey20);
    position: static;
    margin-top: $margin--large;

    @include media("<=phone_large") {
      margin-bottom: $action-footer-height;
    }
  }

  .progress-bar--steps {
    display: block;
    width: 100%;
    margin: auto;
    padding: 21px 0;
    vertical-align: middle;

    .progress-bar--step-container {

      @include media(">=tablet") {
        display: inline-block;
        vertical-align: middle;
        margin-right: 40px;
      }

      .progress-bar--step {
        width: 175px;
        margin: 0 auto;
        line-height: 20px;

        @include media(">=tablet") {
          display: table;
          width: auto;
          margin: 0;
        }

        & > div.content--left {
          display: table-cell;
          width: 10px;
          line-height: 12px;
        }

        & > div.content--right {
          display: table-cell;
          line-height: 12px;
        }
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .active {
    .circle {
      @include circle($blue--primary);
    }
    .text {
      @include step-text($grey--x-dark);
    }
  }

  .incomplete {
    .circle {
      @include circle($main-silver);
    }
    .text {
      @include step-text($main-jordan);
    }
  }

  .complete {
    .circle {
      @include circle($aqua--bold);
    }
    .text {
      @include step-text($grey--x-dark);
      opacity: 0.6;
    }
  }
}
