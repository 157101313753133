@import 'css/base/color.scss';
@import 'css/base/typography.scss';

.password-entry-container,
.password-entry-control {
  width: 100%;
  display: flex;
  flex-direction: column;

  .toggle-password-reveal {
    position: absolute;
    right: 1.25rem;
  }
}

