@import '../../../../../global/css/base/color.scss';
@import '../../../../../global/css/base/margin.scss';
@import '../../../../../global/css/base/typography.scss';
@import '../../../../../global/css/mixins/flexbox.scss';
@import '../../../../../global/css/mixins/includeMedia.scss';
@import '../../../../../global/css/variables/global.scss';


.powered-by {
  @include flexbox();
  @include align-items(center);
  text-align: right;

  a {
    @include flexbox();
    @include align-items(center);
    @include justify-content(flex-end);

    &:hover > span {
      color: $blue--primary;
    }
  }

  .powered-by--logo {
    color: $main-jordan;
    font-size: $font-m;
  }

  .powered-by--text {
    display: none;

    @include media(">=tablet_small") {
      display: inline;
      margin-left: $margin--m;

      color: $main-jordan;
      font-size: $font-xs;
      font-weight: $semibold;
    }
  }
}
