@import 'css/base/color.scss';
@import 'css/base/margin.scss';
@import 'css/base/padding.scss';
@import 'css/base/spacing.scss';
@import 'css/base/typography.scss';
@import 'css/mixins/flexbox.scss';
@import 'css/mixins/includeMedia.scss';

.external {
  &__footer {
    @include flexbox();
    @include align-items(center);
    @include justify-content(space-between);

    padding: $padding--large $padding--larger;

    border-top: 1px solid $grey--x-light;
    background-color: $white;

    @include media($tabletOrSmaller) {
      @include flex-direction(column);
      @include align-items(flex-start);

      padding: $padding--larger;
    }

    &--wordmark {
      @include media($tabletOrSmaller) {
        order: 2;
      }
    }

    &--items {
      @include flexbox();
      @include align-items(center);
      @include justify-content(flex-end);

      @include media($tabletOrSmaller) {
        @include flex-direction(column);
        @include align-items(flex-start);
        margin-bottom: $margin--large;
      }

      &--has-help-widget {
        // On desktop, we want to push the items from the right
        // to ensure that the Support widget is not overlaping
        // the ToS link
        @include media($tabletOrLarger) {
          padding-right: calc(160px - #{$padding--larger});
        }
      }

      a,
      span {
        color: $grey--xx-dark;
        font-size: $font-xs;
        font-weight: $semibold;
        line-height: $spacing--24;

        &:not(:last-child) {
          margin-right: $margin--larger;
        }

        @include media($tabletOrSmaller) {
         &:not(:last-child) {
           margin-right: 0;
           margin-bottom: $margin--xm;
         }
        }
      }
    }
  }
}
