$z-layers: (
  "BELOW": -1,
  "BASE": 1,
  "ABOVE_BASE": 2,
  "PICKER": 5,
  "STICKY": 10,
  "SIDE_NAV": (
    "BASE": 20,
    "DIALOG": 25,
    "TOOLTIP": 26,
  ),
  "SLIDEOUT": (
    "OVERLAY": 110,
    "VIEW_CONTENT": 111,
    "SIDEBAR": 117,
    "PICKER": 118,
    "TOOLTIP": 119,
  ),
  "POPOVER": (
    "BASE": 120,
    "SELECT": 121,
  ),
  "MODAL": (
    "OVERLAY": 130,
    "BASE": 131,
    "PICKER": 132,
    "TOOLTIP": 133,
    "POPOVER": 134,
    "SELECT": 135,
    "DROPDOWN": 136,
  ),
  "HELP": 140,
  "SLIDEOUT_PRIME": (
    "OVERLAY": 141,
    "VIEW_CONTENT": 142,
    "SIDEBAR": 142,
    "PICKER": 143,
    "TOOLTIP": 144,
  ),
  "SELECT": (
    "BOTTOM_SHEET": 150,
  ),
  "CORNER": 160,
  "ALERT": 170,
  "TOOLTIP": 180,
);

@function map-deep-get($map, $keys...) {
  @each $key in $keys {
    $map: map-get($map, $key);
  }

  @return $map;
}

@mixin zIndex($layers...) {
  // Include !important since this should mostly be used for 3rd party ioverrides
  z-index: map-deep-get($z-layers, $layers...) !important;
}
