@import '../base/margin.scss';
@import '../variables/global.scss';


@mixin widgetHeaderTitleColors($color) {
  .entity-widget {

    .entity-widget--header {

      .entity-widget--header-title {
        color: $color;
      }

      &:hover {

        .entity-widget--header-title {
          color: $color;
        }
      }
    }
  }
}

@mixin widgetLeftContentIconSpacing() {
  > .grid > .column:first-child {
    max-width: $form-radio-size-with-margin;
    margin-right: $margin--xs;
  }
}
