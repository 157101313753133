@import 'css/base/color.scss';
@import 'css/base/margin.scss';
@import 'css/base/padding.scss';
@import 'css/base/typography.scss';
@import 'css/mixins/flexbox.scss';
@import 'css/variables/global.scss';
@import 'css/variables/sizes.scss';


@mixin divider-after($after-color, $after-opacity) {
  @include flex(1);
  border-bottom: 2px solid $after-color;
  content: '';
  margin: auto 0;
  opacity: $after-opacity;
  top: 2px;
  width: 100%;
}

@mixin divider-after-thin() {
  border-bottom-width: 1px;
}

.form-divider {
  @include flexbox();
  margin-top: $margin--m;
  margin-bottom: $margin--large;
  opacity: 0.6;

  &.width-full {
    width: 100%;
  }

  &.side-panel-divider {
    // The .form, .slide-body--content padding wrapper uses this constant. To offset the divider, we have to apply it
    // to the margin.
    margin-left: $padding--large*-1;
    width: $side-panel-width-medium;

    &.large {
      width: $side-panel-width-large;
    }

    &:after {
      @include divider-after(var(--color-grey20), 1);
      @include divider-after-thin();
    }
  }

  &.margin-bottom--m {
    margin-bottom: $margin--m;
  }

  &.margin-bottom--x-large {
    margin-bottom: $margin--x-large;
  }

  &.margin-top--large {
    margin-top: $margin--large;
  }

  &.margin-top--x-large {
    margin-top: $margin--x-large;
  }

  &:after {
    @include divider-after(var(--color-grey20), 1)
  }

  &.thin {

    &:after {
      @include divider-after-thin();
    }
  }

  &.dark {
    opacity: 1;

    &:after {
      @include divider-after($blue--bold, 0.26)
    }
  }
}
