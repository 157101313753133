@import 'css/base/color.scss';
@import 'css/base/typography.scss';


.payment-method-same-hint {
  &.info {
    background-color: $purple--light;
  }

  svg {
    fill: $purple--bold !important;
  }

  span.base-text {
    line-height: $line-height--xl;
  }
}
