@import "css/base/color.scss";
@import "css/base/padding.scss";
@import "css/mixins/includeMedia.scss";

.quickswitch-error-modal {
  &--link {
    color: $blue--bold !important;
  }

  &--content {
    background-color: $white;
    padding: 0 !important;
    margin: 0;
  }

  &--paragraph {
    padding-left: $padding--large;
    padding-right: $padding--large;
    word-break: break-word;

    @include media($mobileOnly) {
      padding-left: $padding--m-large;
      padding-right: $padding--m-large;
    }
  }

  &--title {
    padding-top: $padding--large;
    padding-bottom: $padding--m;

    @include media($mobileOnly) {
      padding-bottom: $padding--large;
    }
  }

  &--footer {
    background-color: $white !important;
  }

  // DEV-3880 
  .modal-footer--container {
    @include media($mobileOnly) {
      flex-direction: row !important;
    }
  }
}
