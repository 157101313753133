@import './variables/reset.scss';

@tailwind base;
@tailwind components;
@tailwind utilities;

@import './base/color.scss', './base/margin.scss', './base/transition.scss', './base/typography.scss';

@import './classes/alignment.scss', './classes/borders.scss', './classes/display.scss', './classes/flex-utility.scss',
  './classes/grid.scss', './classes/hint.scss', './classes/heading.scss', './classes/icon.scss', './classes/margin.scss',
  './classes/padding.scss', './classes/scrollbar.scss', './classes/size.scss', './classes/transitions.scss',
  './classes/typography.scss';

@import './variables/alerts.scss', './variables/avatar.scss', './variables/chip.scss', './variables/datePicker.scss',
  './variables/dropdownMenu.scss', './variables/forms.scss', './variables/icons.scss', './variables/link.scss',
  './variables/list.scss', './variables/panel.scss', './variables/platform.scss', './variables/swal.scss',
  './variables/toggle.scss', './variables/toolTip.scss';

@import './mixins/zIndex.scss';

body {
  margin: 0;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: $base-font-family;
  font-weight: $regular;
}

input:required {
  box-shadow: none;
}

// Override Intercom bubble settings
.intercom-launcher-frame {
  @include zIndex('HELP');
}

@import './modules/tablematic.scss';

// TODO: FRON-295 - FIX CSS IMPORTS
@import 'classes/misc.scss';
@import 'classes/ocr.overlays.scss';
@import 'variables/branded.scss';
@import '../../components/contentBlock/ContentBlock.scss';
@import '../../components/button/Button.scss';
@import '../../components/buttonTypes/ButtonTypes.scss';
@import '../../components/card/Card.scss';
@import '../../components/disclosureList/DisclosureList/DisclosureList.scss';
@import '../../components/entityWidget/EntityWidget.scss';
@import '../../components/document/Document.scss';
@import '../../components/icon/Icon.scss';
@import '../../modules/fundingAccount/partnerPaymentMethod/PartnerPaymentMethod.scss';
@import '../../modules/fundingAccount/partnerPaymentMethodStatus/PartnerPaymentMethodStatus.scss';
@import '../../components/routing/ProtectedRouteWarn/ProtectedRouteWarn.scss';
@import '../../components/relation/Relation.scss';
@import '../../components/link/SecondaryActionLink/SecondaryActionLink.scss';
@import '../../components/table/Table.scss';
@import '../../components/text/CurrencyAmount/CurrencyAmount.scss';
@import '../../components/form/Textarea.scss';
@import '../../components/transactionDetails/TransactionDetails.scss';
@import '../../components/tooltip/Tooltip.scss';
@import '../../modules/dashboard/settings/account/balance/components/Balance/Balance.scss';
@import '../../modules/dashboard/settings/account/paymentMethods/components/PaymentMethods/PaymentMethods.scss';
@import '../../modules/dashboard/errors/BalanceError/BalanceError.scss';
@import '../../modules/dashboard/errors/BalanceWarning/BalanceWarning.scss';
@import '../../components/listItems/BlockListItem/BlockListItem.scss';
@import '../../components/text/Text/Text.scss';

// Sometimes react-aria can add padding on the html component
// and this makes sure we always set it to 0
html {
  padding: 0 !important;
}

// remove the details disclosure element icon (Safari browser)
details > summary {
  list-style: none;
}
