@import 'css/base/spacing.scss';
@import 'css/base/typography.scss';


.tag-list {
  .tag {
    margin-bottom: $spacing--8;
    margin-right: $spacing--8;

    .tag-label.rect,
    .tag-label-text {
      height: 100%;
      line-height: $font-l;
    }
  }
}
