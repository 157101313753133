@import 'css/base/margin';
@import 'css/mixins/flexbox';


.tooltip-entry {
  @include flexbox();
  @include flex-direction(column);

  &.is-row {
    @include justify-content(space-between);
    @include flex-direction(row);
    width: 100%;
  }

  &:not(:last-child) {
    &.is-row {
      margin-top: $margin--m;
    }

    &:not(.is-row) {
      margin-right: $margin--larger;
    }
  }
}