@import 'css/base/color.scss';
@import 'css/base/typography.scss';
@import 'css/base/spacing.scss';
@import 'css/mixins/flexbox.scss';
@import 'css/mixins/includeMedia.scss';

.tlp--quick-jumper {
  @include flexbox();
  @include align-items(center);
  gap: 0 8px;

  .quick-jumper--text {
    @include align-self(center);

    &.focus {
      color: $blue--bold;
    }
  }

  .input-container {
    width: 76px;

    input {
      color: $grey--x-dark;
    }

    @include media('<=phone_large') {
      input {
        font-size: $font--12 !important;
      }
    }

    .addon-container {
      padding-right: $spacing--12;
      padding-left: $spacing--12;
      z-index: unset;
    }

    &.has-addon {
      padding-right: $spacing--32;
    }
  }
}
