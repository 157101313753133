@import 'css/mixins/borders.scss';


$allSides: (
  'bottom',
  'bottom-left',
  'bottom-right',
  'left',
  'right',
  'top',
  'top-left',
  'top-right',
);

@mixin makeBorderClasses($side) {
  $classPostfix: '-#{$side}';

  .border#{$classPostfix}--none {
    @include borderNone($side);

    &--important {
      border-#{$side}: none !important;
    }
  }

  .border#{$classPostfix}-radius--none {
    @include borderRadii(0, $side);

    &--important {
      border-#{$side}-radius: 0 !important;
    }
  }
}

@mixin makeBorderPostfixClasses() {
  @each $side in $allSides {
    @include makeBorderClasses($side);
  }
}

@include makeBorderPostfixClasses();

.border--none {
  border: none;

  &--important {
    border: none !important;
  }
}

.border-radius--none {
  border-radius: 0;

  &--important {
    border-radius: 0 !important;
  }
}
