@import 'css/base/block.scss';
@import 'css/base/spacing.scss';
@import 'css/base/typography.scss';
@import 'css/mixins/flexbox.scss';
@import 'css/mixins/zIndex.scss';


$avatar--offset: 40px;

$border-style: 1px solid var(--color-grey20);
$border-radius: 4px;

$line-height--label: 20px;
$line-height--option: 16px;
$line-height--value: 24px;

$menu--border-style: 1px solid $white;
$menu--box-shadow: 0 4px 16px var(--color-grey20);

$select--height: 58px; // 56 + 2px for border (same as text input)
// subtract 2px because of the border
$control--height: $select--height - 2px;

$transition: all 0.2s;
$translate-x-value: -2px;
$translate-y-value: 8.2px;

.select-wrapper {
  position: relative;
  width: 100%;

  .select--required-input {
    display: none;
  }
}

.Select {
  background-color: $white;
  border: $border-style;
  border-radius: $border-radius;
  box-sizing: border-box;
  box-shadow: none;
  position: relative;
  height: $select--height;
  width: 100%;
  transition: $transition;

  .Select__control {
    background-color: transparent;
    border: none;
    border-radius: $border-radius;
    box-shadow: none;
    cursor: pointer;
    height: $control--height;
    overflow: hidden;
    padding: $spacing--8 $spacing--16;

    &--is-disabled {
      cursor: default;
    }
  }

  .Select__value-container {
    margin: 0;
    padding: 0;
    position: relative;
    height: 100%;
    flex-wrap: nowrap;
    font-size: $font-sm;

    .Select__input,
    .Select__placeholder,
    .Select__single-value {
      margin: 0;
      padding: 0;
      bottom: 0;
      width: 100%;
      font-size: $font-sm;
      font-weight: $regular;
      line-height: $line-height--value;
    }

    .Select__single-value,
    .Select__placeholder {
      position: absolute;
      top: unset;
      transform: unset;
      -webkit-transform: unset;
      -ms-transform: unset;

      > span.asterisk {
        color: $blue--bold;
      }
    }

    .Select__placeholder {
      color: $grey--xx-dark;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .Select__input,
    .Select__single-value {
      left: 0;
      color: var(--neutral-black);
    }

    &.Select__value-container--has-value {
      // AVATAR SELECT
      &.has-avatar {
        .Select__single-value {
          left: $avatar--offset;
        }
      }
    }
  }

  &:not(.select-alt) {
    .Select__input {
      transform: translateX($translate-x-value) translateY($translate-y-value);
    }
  }

  /* INDICATORS */
  .Select__indicators {
    .Select__indicator {
      padding: 0;
    }
    .Select__indicator-separator {
      display: none;
    }
    // workaround to even-out icons with select padding
    .Select__dropdown-indicator {
      margin-right: -4px;
    }
    .Select__clear-indicator {
      margin-right: -5px;

      ~ .Select__dropdown-indicator {
        display: none;
      }
    }
  }
  .Select__indicators:has(.Select__loading-indicator) {
    .Select__dropdown-indicator {
     display: none;
    }
 }

  &.has-input {
    // 'No matches found' styles
    .Select__menu-notice--no-options {
      // To be replaced in phase 2 of color updates
      // No substitute exists
      background-color: #F9F9F9; // $grey--xx-light
    }
  }

  /* MULTI SELECT */
  .tag {
    margin-right: $spacing--2;
  }

  // LABEL
  +.select--label {
    position: absolute;
    top: $spacing--8;
    // workaround to properly align the label with the value / placeholder below
    left: 16.2px;

    color: var(--color-grey70);
    font-size: $font-xs;
    font-weight: $semibold;
    line-height: $line-height--label;

    &.focused {
      color: $blue--bold;
    }
  }

  &.is-focused {
    border-color: $blue--bold;

    .Select__indicator {
      color: $blue--bold;

      > svg {
        fill: $blue--bold !important;
      }
    }
  }

  &.hidden-label {
    .Select__single-value {
      position: relative;
      white-space: normal;
    }

    .Select__input {
      transform: unset;
    }
  }

  &.has-error {
    border-color: $red--bold;
  }

  // move label to the right when value has avatar
  &.has-avatar {
    &.has-value {
      +.select--label {
        left: #{$avatar--offset+$spacing--16};
      }

      .Select__input {
        transform: translateX(8px) translateY($translate-y-value);
      }
    }
  }

  &.is-disabled {
    background-color: var(--color-grey10);

    +.select--label,
    .Select__single-value,
    .Select__placeholder {
      color: var(--color-grey70);
    }

    +.Select__single-value,
    .Select__placeholder {
      font-weight: $regular;
    }
  }
}

/* MENU */
.Select__menu {
  border: none;
  // !important must be used to override styles from react-select
  box-shadow: $menu--box-shadow !important;
  border-radius: $border-radius !important;
  margin: $spacing--4 $spacing--16 !important;
  width: calc(100% - #{$spacing--16*2}) !important;
  @include zIndex("MODAL", "SELECT");

  &--bottom-sheet {
    margin: 0 !important;
    width: 100% !important;
  }

  .Select__menu-list {
    border-radius: $border-radius;
    padding: 0;

    .Select__option,
    .Select__option--is-selected {
      background-color: transparent;
      border-radius: $border-radius;
      color: $grey--xx-dark;
      cursor: pointer;
      font-size: $font-xs;
      line-height: $line-height--option;

      .search-company-option__label {
        color: var(--neutral-black);
      }
      .search-company-option__details {
        color: var(--color-grey60);
      }

      &.Select__option--is-focused {
        background-color: $blue--bold;
        color: $white;

        .new-option-icon {
          fill: $white !important;
        }
      }

      &.Select__option--is-disabled {
        background: $white;
        color: $main-jordan;
        cursor: default;

        .multi-line-option__label,
        .multi-line-option__details,
        .multi-line-option--addons svg,
        .search-company-option__label,
        .search-company-option__details,
        .search-company-option--addons svg {
          opacity: 0.5;
        }

        &:hover {
          pointer-events: none;
        }
      }

      &.multi-line-option {
        border-radius: 0;
        border-bottom: $content-block-border;
        color: $grey--dark;

        .multi-line-option__label,
        .multi-line-option__details {
          letter-spacing: unset !important;
        }

        &:last-child {
          border-bottom: none;
        }

        &:not(.Select__option--is-disabled).Select__option--is-focused {
          background-color: $blue--x-light;
          color: $blue--bold;

          .multi-line-option__label {
            font-weight: $bold;
            color: $blue--bold !important;
          }

          .blue-on-option-hover,
          .search-company-option__label {
            color: var(--color-blue40);
          }
          .search-company-option__details {
            color: var(--color-grey60);
          }

          .multi-line-option__details {
            color: $blue--x-dark !important;
          }
        }
      }
    }
  }

  // 'Type to search' styles
  .Select__menu-notice--no-options {
    @include flexbox();
    @include align-items(center);
    background-color: $blue--x-light;
    color: $grey--x-dark;
    cursor: default;
    font-size: $font-xs;
    line-height: $line-height--option;
    padding: $spacing--12;
  }
}

.Select.no-label {
  .Select__value-container {
    .Select__input,
    .Select__placeholder,
    .Select__single-value {
      position: absolute;
      bottom: 50%;
      transform: translate(0, 50%);
      grid-area: unset !important;
    }
  }
}
