@import 'css/base/color.scss';
@import 'css/base/margin.scss';
@import 'css/base/typography.scss';
@import 'css/mixins/flexbox.scss';
@import 'css/mixins/includeMedia.scss';


.external-tax--full-width-page {
  width: 600px;

  @include media("<=tablet_small") {
    width: 100%;
  }
}

.external-tax--sidebar {
  @include flexbox();
  @include flex-flow(row nowrap);
  @include align-items(flex-start);
  @include justify-content(center);

  height: 100%;
  // aligns with external prompt header
  margin-top: $margin--x-large * 2;
}
