@import './spacing.scss';


$margin--xs: $spacing--2;
$margin--sm: $spacing--4;
$margin--xm: $spacing--8;
$margin--m: $spacing--12;
$margin--m-large: $spacing--16;
$margin--large: $spacing--24;
$margin--larger: $spacing--32;
$margin--large-aligner: $spacing--32;
$margin--x-large: $spacing--40;
$margin--xx-large: $spacing--60;
$margin--xxx-large: $spacing--120;

$text-block-margin: 0.6em;
$spacer-margin--xs: 1px;
