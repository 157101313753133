@import 'css/base/color.scss';
@import 'css/base/typography.scss';
@import 'css/base/margin.scss';
@import 'css/base/padding.scss';
@import 'css/base/spacing.scss';
@import 'css/mixins/flexbox.scss';


.invoice-discount {
  display: inline-flex;
  @include align-items(center);
  @include justify-content(flex-start);
  width: 100%;

  margin-top: $margin--m;
  padding: $padding--m;

  background: lighten($light-yellow, 10%);
  border: 1px solid desaturate($light-yellow, 4%);
  border-radius: 10px;

  div.form-control {
    width: inherit;
    @include align-items(center);

    &:last-child {
      // applied to discountCalculation radio inputs
      max-width: 382px;
      padding-left: $spacing--32;
    }

    // TODO: FRON-574 - Standardize input error fields
    // w-discount-field is targeting our discount field
    // added w- so it will appear in the Input.js widthClasses
    .w-discount-field {
      .form-top-label.error {
        position: relative;
      }

      .form-error-container {
        position: absolute;
        // width specific to error message
        width: 184px;
        // error aligns with input field
        right: 170px;
        padding: $padding--xm;

        background-color: $red--light;
        border: 1px solid $red--light;
        border-radius: 4px;
        box-shadow: 0 2px 6px $main-silver;

        p {
          font-size: $font-xs;
        }
      }
    }
  }

  .select-wrapper {
    width: $form-width-sm;
  }
};
