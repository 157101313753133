@import "css/base/typography.scss";

.form-footer {
  display: flex;
  justify-content: flex-start;
  flex-grow: 0;
  gap: 2rem;
  font-size: $font--12;
  font-weight: 500;
  align-items: center;
  margin-top: 2.25rem;

  &.extra-bottom-padding {
    padding-bottom: 2.5rem;
  }
}
