@import 'css/mixins/flexbox.scss';


.role-card-entity-widget__header-title {
  @include flexbox();
  @include flex-direction(row);
  @include align-items(center);

  white-space: nowrap;
  min-height: 100%;
}
