@import "css/base/color.scss";
@import "css/base/margin.scss";


.external-block {
  background-color: $white;

  &:not(:last-child) {
    margin-bottom: $margin--xm;
  }
}
