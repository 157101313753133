@import 'css/base/block.scss';
@import 'css/base/color.scss';
@import 'css/base/padding.scss';
@import 'css/mixins/boxShadow.scss';
@import 'css/mixins/includeMedia.scss';
@import 'css/variables/global.scss';


.generic-card {
  background-color: $white;
  padding: $padding--m-large $padding--m;
  width: 100%;
  border-radius: $block-border-radius--xm;

  &.with-shadow {
    @include box-shadow--dark();
  }

  &.with-border {
    border: $border--blue-light;
  }
}
