@import 'css/base/color.scss';
@import 'css/base/typography.scss';
@import 'css/mixins/text.scss';


/* font family */

.font-family--monospace {
  font-family: $monospaced-font-family;

  &--important {
    font-family: $monospaced-font-family !important;
  }
}


.font-size--zero {
  font-size: 0 !important;
}

/* font size */

.font-size--xxs {
  font-size: $font-xxs !important;
}

.font-size--xs {
  font-size: $font-xs !important;
}

.font-size--regular {
  font-size: $font-regular !important;
}

.font-size--sm {
  font-size: $font-sm !important;
}

.font-size--m {
  font-size: $font-m !important;
}

.font-size--16 {
  font-size: $font--16 !important;
}

.font-size--18 {
  font-size: $font--18 !important;
}

.font-size--32 {
  font-size: $font--32 !important;
}

.font-size--m-l {
  font-size: $font-m-l !important;
}

.font-size--l {
  font-size: $font-l !important;
}

.font-size--larger {
  font-size: $font-larger !important;
}

.font-size--xl {
  font-size: $font-xl !important;
}

.font-size--xxl {
  font-size: $font-xxl !important;
}

/* Font weights */

.font-weight--thin {
  font-weight: $thin;
}

.font-weight--light {
  font-weight: $light;
}

.font-weight--regular {
  font-weight: $regular;
}

.font-weight--semibold {
  font-weight: $semibold;

  &--important {
    font-weight: $semibold !important;
  }
}

.font-weight--bold {
  font-weight: $bold;
}

.font-weight--extra-bold {
  font-weight: $extra-bold;
}

/* text line height */

.base-line-height {
  line-height: $base-line-height !important;
}
.paragraph-line-height {
  line-height: $paragraph !important;
}
.line-height--0 {
  line-height: $line-height--0 !important;
}
.line-height--sm {
  line-height: $line-height--14 !important;
}
.line-height--xm {
  line-height: $line-height--16 !important;
}
.line-height--m {
  line-height:  $line-height--18 !important;
};
.line-height--l {
  line-height: $line-height--20 !important;
};
.line-height--xl {
  line-height: $line-height--22 !important;
};
.line-height--xxl {
  line-height: $line-height--26 !important;
};
.line-height--28 {
  line-height: $line-height--28 !important;
};
.line-height--xxxl {
  line-height: $line-height--32 !important;
};
.line-height--40 {
  line-height: $line-height--40 !important;
};

/* letter spacing */

.letter-spacing--xxs {
  letter-spacing: $letter-spacing--xxs;
}

.letter-spacing--xs {
  letter-spacing: $letter-spacing--xs;
}

/* text decoration */
.text-decoration--line-through {
  text-decoration: line-through;
}

.text-decoration--none {
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
}

/* text alignment */
.text-align--center {
  text-align: center;
}

.white-space--nowrap {
  white-space: nowrap;
}

.overflow--hidden {
  overflow: hidden;
}

.text-overflow--ellipsis {
  text-overflow: ellipsis;
}

.user-select--none {
  @include user-select();
}
