@import 'css/base/block.scss';
@import 'css/base/color.scss';
@import 'css/base/margin.scss';
@import 'css/base/padding.scss';
@import 'css/mixins/flexbox.scss';
@import 'css/mixins/widget.scss';
@import 'css/variables/global.scss';


.bank-account-radio {
  border-radius: $block-border-radius--xm;
  background-color: $white;
  border: $border--blue-light;
  width: 100%;

  .bank-account--header {
    cursor: pointer;

    @include widgetLeftContentIconSpacing();

    .entity-widget--header-subtitle {
      margin-left: $text-block-margin;
    }
  }

  &:hover:not(.no-highlight) {
    border-color: $blue--primary;
  }

  @include widgetHeaderTitleColors($dark-jordan);

  &.checked {
    @include widgetHeaderTitleColors($grey--x-dark);
  }

  &.disabled {
    pointer-events: none;

    // add styles for text, background etc.
    .bank-account--header {
      background: $blue--xx-light;

      .entity-widget--header-titles {
        * {
          color: $grey--med !important;
          fill: $grey--med !important;
        }
      }
    }
  }
}
