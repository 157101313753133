@mixin centerer($alignment) {
  position: absolute;

  @if ($alignment == 'both') {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  } @else if ($alignment == 'horizontal') {
    left: 50%;
    transform: translate(-50%, 0);
  } @else if ($alignment == 'vertical') {
    top: 50%;
    transform: translate(0, -50%);
  }
}

@mixin v-align-icon($alignment: 'text-bottom') {
  vertical-align: $alignment;
}
