@import '../../global/css/base/color.scss';
@import '../../global/css/base/margin.scss';
@import '../../global/css/base/typography.scss';
@import '../../global/css/mixins/includeMedia.scss';


.copyright {
  pointer-events: none;
  margin-bottom: 0;

  color: $dark-jordan;
  font-size: $font-xs;
  font-weight: $regular;
  line-height: $paragraph;
  width: 100%;

  // Restrict width of copyright on non-mobile screens so that
  // the copyright text won't break the legal links (positioned right)
  @include media('>tablet') {
    max-width: 75%;
  }
}
