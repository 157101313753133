/* RESET
----------------------------------------------------------------------- */
* {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  box-sizing: border-box;
  vertical-align: baseline;
  background: transparent;
  text-decoration: none;
  font-weight: normal;
}

// body reset moved into tailwind config file due to specificity issues

ol,
ul {
	list-style: none;
	margin: 0;
	padding: 0;

	-webkit-margin-before: 0;
	-webkit-margin-after: 0;
	-webkit-margin-start: 0;
	-webkit-margin-end: 0;
	-webkit-padding-start: 0;
}

blockquote,
q {
	quotes: none;
}

:focus {
	outline: 0;
}

span {
	font-size: inherit;
	font-family: inherit;
	font-weight: inherit;
	color: inherit;
}
