@import './color.scss';
@import './margin.scss';
@import './padding.scss';


// Borders
$table-border--inner: 1px solid var(--color-grey20);
$table-border--outer: 1px solid var(--color-grey20);
$table-border-radius: 10px;

// Padding
$table-padding--horizontal: $padding--m;
$table-align-cell-to-container: $margin--large-aligner;

// Header row
$table-header-row--background-color: darken($blue--xx-light, 2%);
$table-header-row--height: 40px;

// Body row
$table-body-row--background-color: $white;
$table-body-row--background-color--archived: $blue--xx-light;
$table-body-row--background-color--confirmed: lighten($aqua--bold, 62%);
$table-body-row--background-color--error: $red--05;
$table-body-row--background-color--disabled: $blue--x-light;
$table-body-row--background-color--hover: $blue--x-light;
$table-body-row--background-color--warn: $yellow--light;
$table-body-row--background-color--unread: $blue--x-light;
$table-body-row--height: 62px;
$table-body-row--select-height: 71px;

// Cell sizing
$table-cell--width-block: 2%;

// Form fields
$table-form-field--font-color: $grey--x-dark;
$table-form-field--font-color--header: $dark-jordan;
$table-form-field--font-color--disabled: $dark-jordan;

// Footer
$table-footer-height: 34px;
