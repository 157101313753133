@import 'css/base/block';
@import 'css/base/color';
@import 'css/base/margin';
@import 'css/base/padding';
@import "css/base/transition";
@import 'css/base/typography';

@import 'css/mixins/flexbox';


$header--height: 64px;
$header-content--height: 40px;

.company-address-data-card {
  background-color: $white;

  &__header {
    @include flexbox();
    @include align-items(center);
    @include justify-content(space-between);

    height: $header--height;
    padding: $padding--m $padding--m-large;
    border: 1px solid var(--color-grey20);
    border-radius: $block-border-radius--sm;

    &--expanded {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }

    &:hover {
      cursor: pointer;
    }

    &__left {
      @include flexbox();
      @include align-items(center);

      height: $header-content--height;
    }
  }

  &__expandable {
    max-height: 0;
    overflow: hidden;
    transition: max-height $transition-accordion-timing ease-in-out;

    &--expanded {
      max-height: 500px;
    }

    &__item {
      @include flexbox();
      @include align-items(flex-start);
      @include justify-content(space-between);

      padding: $padding--m $padding--m-large;
      border: 1px solid var(--color-grey20);
      border-top: none;

      &:not(:first-child){
        align-items: center !important;
      }

      &:last-child {
        border-bottom-left-radius: $block-border-radius--sm;
        border-bottom-right-radius: $block-border-radius--sm;
      }

      &__actions {
        @include flexbox();
        @include align-items(center);
      }

      &__action-icon {
        &:hover {
          cursor: pointer;
        }
      }
    }

    &__actions {
      @include flexbox();
      @include justify-content(flex-end);
      @include align-items(center);

      border: 1px solid var(--color-grey20);
      border-top: none;

      padding: $padding--m $padding--m-large;

      &__button {
        &:not(:last-child) {
          margin-right: $margin--m-large;
        }
      }
    }
  }
}
