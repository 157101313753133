@import 'css/base/color.scss';
@import 'css/base/margin.scss';
@import 'css/base/padding.scss';
@import 'css/base/typography.scss';


.action-nav-search-clear {
  padding: $padding--sm;
  margin-right: $margin--sm;
  font-size: 6px;
  font-weight: $bold;
  color: $dark-jordan;
  background-color: $blue--x-light;
  border-radius: 50%;

  &:hover {
    background-color: var(--color-grey20);
    cursor: pointer;
  }
}
