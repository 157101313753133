@import '../../global/css/base/color.scss';
@import '../../global/css/base/padding.scss';
@import '../../global/css/base/typography.scss';
@import '../../global/css/mixins/boxShadow.scss';
@import '../../global/css/mixins/flexbox.scss';
@import '../../global/css/mixins/includeMedia.scss';
@import '../../global/css/variables/buttons.scss';
@import '../../global/css/variables/global.scss';



@mixin btn-style($text-color, $background-color) {
  background-color: $background-color;
  border: $border--blue-light;
  color: $text-color;

  span {
    color: $text-color;

    &.icon-ic-arrow-back, &.icon-left {
      color: $text-color;
    }
  }
}

.btn {
  @include flexbox();
  @include align-items(center);
  text-align: center;
  margin-bottom: $form-margin;
  font-weight: $semibold;
  border: none;
  border-radius: 8px;
  box-sizing: border-box;
  cursor: pointer;

  // Tweaks for <a> tags with the .btn class
  @at-root {
    a#{&} {
      height: initial;
      display: inline-flex;

      &.btn--sm {
        padding: 0 $padding--m;
      }
    }
  }

  // margin
  &.margin {
    &.left {
      margin-left: $form-margin;
    }

    &.right {
      margin-right: $form-margin;
    }
  }

  // element state
  &:disabled {
    opacity: 0.6;
    pointer-events: none;
  }

  &:hover {
    text-decoration: none;
  }

  &.error {
    border: 1px solid $red--bold !important;
  }

  &:focus {
    outline: 0;
  }

  // width
  &.btn--full {
    width: $form-width-full;
    margin-top: $form-margin;
  }

  &.btn--half {
    width: $form-width-m;
    margin-top: $form-margin;
  }

  &.btn--auto {
    width: auto;
  }

  &.btn--inherit {
    width: inherit;
  }

  &.btn--max-content {
    width: max-content;
  }


  // box-shadow
  &.external--light {
    @include box-shadow--light();
  }

  &.external--dark {
    @include box-shadow--dark();
  }

  // Inline loading animation
  & > div.sk-three-bounce {
    margin-left: auto;
    margin-right: $button-spacing;
    padding-left: $button-spacing;
    line-height: inherit;
    height: auto;
  }

  // height
  &.btn--xl {
    height: $form-height-xl;
    line-height: 28px;
    font-weight: $bold;
    font-size: $font-sm;

    & > .icon-left {
      margin-left: $button-spacing;
      line-height: 28px;
      font-size: 14px;

      &.spacing-m {
        margin-left: $button-spacing--m;
      }

      &.icon-left--large {
        font-size: 18px;
      }
    }

    & > .icon-ic-arrow-back {
      margin-left: auto;
      margin-right: $button-spacing;
      padding-right: $button-spacing;
      line-height: 28px;
      font-size: $font-xs;
      font-weight: $bold;
    }

    span {
      line-height: 28px;
    }
  }

  &.btn--m {
    margin-bottom: 0;
    padding: 11px 14px;
    line-height: 16px;
    font-weight: $bold;
    font-size: $font-xs;
    border-radius: 8px;

    &.has-margin-bottom {
      margin-bottom: 12px;
    }

    > span {
      margin: 0;
      line-height: 16px;
    }

    & > .icon-left {
      margin-right: 8px;

      &.icon-left--large {
        font-size: 16px;
      }
    }

    & > .icon-ic-arrow-back {
      margin-left: auto;
      padding-right: $button-spacing;
      font-size: 10px;
      font-weight: $bold;
    }

    & > div.sk-three-bounce {
      // align IsLoadingInline component
      margin-right: 0;
    }
  }

  &.btn--sm {
    height: $form-height-sm;
    line-height: $line-height--sm;
    font-size: $font-xs;
    font-weight: $bold;
    padding: 0 $padding--xm;
    border-radius: 6px;

    > span {
      margin: 0;
      line-height: $line-height--sm;
    }

    & > .icon-left {
      margin-right: 8px;

      line-height: $line-height--sm;
      font-size: 14px;
    }

    & > .icon-small {
      font-size: $font-xs;
    }

    & > .icon-right {
      margin-left: 8px;

      line-height: $font-sm;
      font-size: $font-sm;
    }

    & > .icon-ic-arrow-back {
      font-size: 12px;

      &:not(.no-float-right) {
        float: right;
      }
    }

    & > div.sk-three-bounce {
      // align IsLoadingInline component
      margin-right: 0;
    }
  }

  &.btn--align-center {
    @include justify-content(center);
  }

  // main button
  &.main {
    color: $white;

    &:hover {
      box-shadow: inset 0 -2.6px 0 rgba(31,45,61,0.24);

      &.external--light {
        box-shadow: inset 0 -2.6px 0 rgba(31,45,61,0.24), 0 2px 6px $main-silver;
      }

      &.external--dark {
        box-shadow: inset 0 -2.6px 0 rgba(31,45,61,0.24), 0 4px 4px rgba(77, 92, 113, 0.2);
      }
    }

    &.primary {
      background-color: $primary;
    }

    &.cancel {
      background-color: $red--bold;
    }

    &.white {
      background-color: $white;
      color: $grey--xx-dark;
    }

    &.dark {
      background-color: $blue--bold;
    }

    &.darker {
      background-color: $blue--dark;

      &:hover:enabled {
        box-shadow: inset 0 -2.6px 0 rgba(61, 75, 99, 0.35);
        background-color: darken($blue--dark, 10);
      }
    }

    &.positive {
      background-color: $positive;
    }

    &.negative {
      background-color: $negative;
    }

    &.scheduled {
      background-color: $scheduled;
    }

    &.confirm {
      background-color: $aqua--bold;
    }

    &.qbo {
      background-color: $qbo;
    }

    &.xero {
      background-color: lighten($xero, 4%);
    }

    &.loft {
      background-color: $loft;
    }
  }

  &.ghost {
    background-color: $white;
    border: $border--blue-light;

    // This is needed for SWAL buttons that don't have span children
    color: $grey--x-dark;

    span {
      color: $grey--x-dark;

      &.icon-ic-arrow-back {
        color: $main-jordan;
      }

      &.icon-left {
        color: $main-jordan;
      }
    }

    &.ghost--primary-link {
      color: $grey--x-dark;

      &:hover {
        @include box-shadow--ghost();
        border-color: $blue--bold;

        span {
          color: $blue--bold;
        }

        svg {
          fill: $blue--bold !important;
        }
      }
    }

    &.ghost--cancel:hover {
      @include btn-style($white, $red--bold);
      box-shadow: inset 0 -2.6px 0 rgba(31,45,61,0.24) !important;
    }

    &.ghost--success:hover {
      @include btn-style($white, $aqua--bold);
      box-shadow: inset 0 -2.6px 0 rgba(31,45,61,0.24) !important;
    }

    &.ghost--primary:hover {
      @include btn-style($white, $primary);
      box-shadow: inset 0 -2.6px 0 rgba(31,45,61,0.24) !important;
    }
  }

  span {
    align-items: flex-start;
    margin-left: 16px;
    white-space: nowrap;

    // Truncate extra long button text on mobile
    @include media("<=phone_large") {
      max-width: $button-mobile--max-width;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    // Truncate shorter for Zoolander phones
    @include media("<=phone") {
      max-width: $button-mobile--max-width-small;
    }
  }
}

.btn-wrapper--action {
  width: $button-mobile--max-width !important; // !important needed to override element style
}
