@import 'css/base/block.scss';
@import 'css/base/color.scss';
@import 'css/base/margin.scss';
@import 'css/base/padding.scss';
@import 'css/base/typography.scss';
@import 'css/mixins/flexbox.scss';
@import 'css/mixins/zIndex.scss';
@import 'css/variables/global.scss';
@import 'css/variables/sizes.scss';
@import './Select.scss';


.Select.multi-select {
  border-radius: $border-radius;

  .Select__value-container {
    padding: 0 !important;
  }

  .Select__single-value {
    margin: 0 !important;
    // override react-select's inline style attributes
    overflow: visible !important;
    transform: translateY(0) !important;
    color: $grey--xx-dark !important;
  }

  .Select__menu {
    position: absolute;
    height: auto;
    background-color: $white;
    border-radius: 0 0 $border-radius $border-radius;
    margin-top: 0;
  }

  .Select__menu-list {
    position: relative;
    margin-top: 0;
    margin-left: 0;
    box-shadow: none;
    width: 100%;
    border-radius: 0 0 $border-radius $border-radius;
    padding-bottom: $padding--xm;
    border-top: 0;
  }

  .Select__control--menu-is-open {
    border-bottom: 0;

    .Select__dropdown-indicator {
      transform: rotate(180deg);
    }

    ~ .Select__menu {
      .Select__menu-list {
        animation: slideMenu 0.2s;
      }
    }
  }

  &.react-select-redux-form {
    .Select__control {
      border-radius: $border-radius;
      border: $border-style !important;

      &.Select__control--menu-is-open {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        border-bottom: 0;
      }
    }
  }

  .multi-check-select--option {
    @include flex-col-center();
    cursor: pointer;
    min-height: 30px;
    padding: $padding--xs 0 $padding--xs $padding--m;

    label span {
      transition: color 0.2s;
      cursor: pointer;
    }

    .content--left {
      line-height: unset;
    }

    .control-indicator {
      width: $icon-size--15;
      height: $icon-size--15;
    }

    .agreement-checkbox .content--right span {
      font-size: $font-xxs;
    }

    &.all-option {
      margin-bottom: $margin--xs;
    }

    &:hover label span {
      color: $blue--bold;
    }
  }

  .Select__group {
    padding: 0;
    border-bottom: $border-style;

    &:last-child {
      border-bottom: 0;
    }
  }

  .multi-check-select--group-heading {
    padding-top: $padding--m;

    .Select__group-heading {
      text-transform: none;
      color: $grey--xx-dark;
      font-size: $font-xs;
      font-weight: $semibold;
    }

    &.empty-heading {
      display: none;
    }
  }
}
