@import './color.scss';


$block-border-radius-xs: 3px;
$block-border-radius--sm: 4px;
$block-border-radius--xm: 6px;
$block-border-radius--m: 8px;
$block-border-radius--large: 14px;

$content-block-padding--xm: 16px;
$content-block-padding--m: 24px;
$content-block-padding--l: 36px;

$content-block-box-shadow: 0px 2px 2px var(--color-grey20);

$content-block-border: 1px solid var(--color-grey20);
$content-block-border-light: 1px solid $blue--x-light;
$content-block-border-error: 1px solid $red--bold;

$content-block-header-instruct-circle-size: 20px;
