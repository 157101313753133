@import '../../global/css/base/color.scss';
@import '../../global/css/base/margin.scss';
@import '../../global/css/base/typography.scss';
@import '../../global/css/mixins/flexbox.scss';


.confirmation-section-header {
  @include flexbox();
  @include flex-flow(row nowrap);
  @include align-items(center);

  color: $aqua--bold;
  margin-bottom: $margin--m;

  h3 {
    font-weight: $bold;
  }
}
