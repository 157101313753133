@import 'css/base/color.scss';
@import 'css/base/typography.scss';
@import 'css/base/spacing.scss';
@import 'css/mixins/flexbox.scss';
@import 'css/mixins/includeMedia.scss';

$current-page-chip-height: 28px;
$page-nav-radius: 100px;

.tlp--page-nav {
  & > .tlp--page-nav-wrapper {
    @include flexbox();
    @include align-items(center);
    @include justify-content(center);

    background-color: $white;
    border-radius: $page-nav-radius;
    gap: $spacing--8;
    padding: $spacing--4 $spacing--12;

    .page-navigation-block {
      @include flexbox();
      @include align-items(center);
      @include justify-content(center);

      height: $spacing--24;
      width: $spacing--24;

      .btn-v2 {
        border-radius: 50%;
        color: $grey--dark;
        font-size: $font-xs;
        padding: 0;
      }
    }

    .tlp-page-nav--display {
      color: $grey--dark;
      font-size: $font-xs !important;
      padding: $spacing--8 $spacing--12;

      &.current-page {
        @include align-self(center);

        background-color: $blue--xx-light;
        border-radius: 100%;
        color: $grey--x-dark !important;
        height: $current-page-chip-height;
        line-height: $current-page-chip-height;
        padding: 0 $spacing--12;
      }
    }
  }
}
