@import 'css/base/block.scss';
@import 'css/base/color.scss';
@import 'css/base/padding.scss';
@import 'css/base/margin.scss';
@import 'css/base/typography.scss';
@import 'css/mixins/flexbox.scss';
@import 'css/mixins/transitions.scss';
@import 'css/variables/global.scss';


.relation {
  @include flexbox();
  @include align-items(center);

  background-color: $white;
  border-radius: $block-border-radius--xm;
  border: $border--blue-light;
  padding: $padding--xm;

  .relation--right-icon,
  .relation_document--icon-right {
    @include transitionColor();
    color: $dark-silver;
  }

  .relation_document--icon-right:hover {
    color: $aqua--bold;
  }

  .relation--right-icon {
    @include align-self(center);
    margin-left: auto;

    &:hover {
      color: $primary;
    }
  }

  svg {
    cursor: pointer;
  }

  &.relation_transaction-fee {

    .relation--left-icon {
      cursor: auto;
    }
  }
}

.relation_ledger-status {

  .item-ledger--logo {
    @include align-self(center);
    margin-right: $margin--sm;
  }
}

.relation_document {
  @include flexbox();
  @include justify-content(space-between);

  .relation_document--link {
    @include transitionColor();
    color: $grey--x-dark;
    display: block;
    font-weight: $semibold;
    margin: 0;

    span {
      position: relative;
      display: inline-block;
    }

    span,
    svg {
      @include transitionColor();
      vertical-align: middle;
    }

    &:hover,
    &:hover span,
    &:hover svg {
      color: $primary;
    }
  }
}

/*********/
/* Relations inside ThreadDetails (provisions needed due to the floating help icon) */
/*********/
.thread-details {

  .relation {
    max-width: 85%;
  }

  /*
   * if inside a nested disclosure list, 85% of container width makes the relation component
   * too short, and using 90% gives us nice alignment with the non-nested relations
   */
  .disclosure-list .disclosure-list {

    .relation {
      max-width: 90%;
    }
  }
}
