@import 'css/base/color.scss';
@import 'css/base/padding.scss';
@import 'css/base/spacing.scss';


.data-card {
  display: inline-flex;
  flex-direction: column;
  border: 1px solid var(--color-grey20);
  border-radius: 4px;
  
  &__entries {
    padding: $padding--m;
    gap: $spacing--24;
  }

  &__footer {
    border-top: 1px solid var(--color-grey20);
    padding: $padding--sm 0;
  }
}