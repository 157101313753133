@import 'css/base/block.scss';
@import 'css/base/color.scss';
@import 'css/base/padding.scss';
@import 'css/base/margin.scss';

.legalNameChangeConfirmationHint__list-container {
  padding: 0;
  margin: 0 0 -1 * $margin--xm;
  overflow: hidden;
}

.legalNameChangeConfirmationHint__list-item {
  display: inline-block;
  background-color: $white;
  padding: $padding--sm $padding--xm;
  margin: 0 $margin--xm $margin--xm 0;
  border: 1px solid var(--color-grey20);
  border-radius: $block-border-radius--sm;
}
