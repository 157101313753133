@import '../../global/css/base/color.scss';
@import '../../global/css/mixins/flexbox.scss';
@import '../../global/css/base/margin.scss';
@import '../../global/css/base/padding.scss';
@import '../../global/css/base/typography.scss';
@import '../../global/css/variables/global.scss';

$block--base-icon-size: 13px;
$block--line-height: 17px;

.detail-block {
  margin-bottom: 18px;

  &:after {
    content: '';
    display: block;
    margin-top: $margin--sm;
    border: 1px solid lighten($blue--x-light, 1%);
  }

  &:last-child,
  &:only-child {
    margin-bottom: 0;

    &:after {
      content: '';
      display: block;
      margin-top: 0;
      border: none;
    }
  }

  .detail-block--label {
    display: block;
    margin-bottom: $margin--sm;

    color: darken($main-jordan, 3%);
    font-size: $font-xs;
    font-weight: $semibold;
  }

  .detail-block--content {
    @include flexbox();
    @include align-items(center);
    width: 100%;

    $icon-opacity: 0.7;

    // Mimic behavior for Evergreen icons
    & > svg {
      opacity: $icon-opacity;
    }

    .content--left {
      @include flexbox();
      padding-right: $padding--xm;
      width: 20px;
      line-height: 1.6;

      &.rotate-90 {
        transform: rotate(270deg);
      }

      & > span {
        @include align-self(center);
        color: $main-jordan;
        font-weight: $regular;
        opacity: $icon-opacity;
        vertical-align: middle;

        &.icon-ic-attachment {
          font-size: $block--base-icon-size + 2px;
          font-weight: $semibold;
        }

        &.icon-ic-alert {
          font-size: $block--base-icon-size + 1px;
        }

        &.icon-ic-bank {
          font-size: $block--base-icon-size;
        }

        &.icon-ic-briefcase {
          font-size: $block--base-icon-size;
        }

        &.icon-ic-calendar {
          font-size: $block--base-icon-size;
        }

        &.icon-ic-check {
          font-size: $block--base-icon-size + 4px;
        }

        &.icon-ic-edit {
          font-size: $block--base-icon-size + 1px;
        }

        &.icon-ic-mail {
          font-size: $block--base-icon-size + 4px;
        }

        &.icon-ic-receipt {
          font-size: 15px;
        }
      }
    }

    .content--right {
      @include flexbox();
      @include flex-direction(column);
      @include justify-content(center);
      font-size: $font-xs;
      vertical-align: middle;
      line-height: 1.6;
      width: 100%;

      & > a {
        display: block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        color: $blue--primary;
        font-weight: $bold;
      }

      & > span {
        width: 96%;
        display: block;

        color: lighten($grey--x-dark, 10%);
        font-weight: $bold;

        .payment-terms {
          float: right;
          color: darken($dark-yellow, 10%);
          font-weight: $bold;
        }
      }
    }
  }
}
