@import '../base/color.scss';
@import '../base/typography.scss';
@import '../mixins/text.scss';
@import '../variables/global.scss';

$line-height--h2: 25px;

h1 {
  font-family: $base-font-family;
  font-weight: $regular;
  line-height: $base-line-height;
  font-size: $font-xl;

  &.dark {
    color: $grey--x-dark;
  }
  &.primary {
    color: $primary;
    font-weight: 300;
  }
  &.positive {
    color: $positive;
  }
  &.negative {
    color: $negative;
  }
  &.white {
    color: $white;
  }
}

h2 {
  font-family: $alt-font-family;
  font-weight: $bold;
  font-size: $font-l;
  line-height: $line-height--h2;
  text-rendering: auto;
  letter-spacing: 0.01em;

  &.has-margin-bottom {
    margin-bottom: 12px;
  }

  &.brand {
    font-family: $brand-font-family;
    font-weight: bold;
  }
  &.dark {
    color: $grey--x-dark;
  }
  &.dark-blue {
    color: $blue--bold;
  }
  &.grey {
    color: $dark-silver;
  }
  &.dark-grey {
    color: $dark-jordan;
  }
  &.white {
    color: $white;
  }
  &.primary {
    color: $primary;
  }
  &.positive {
    color: $positive;
  }
  &.negative {
    color: $negative;
  }
  &.scheduled {
    color: $scheduled;
  }
  &.confirm {
    color: $aqua--bold;
  }
}

.line-height--h2 {
  line-height: $line-height--h2;
}

h3 {
  font-family: $base-font-family;
  font-weight: $semibold;
  line-height: $base-line-height;
  font-size: $font-m;

  -webkit-margin-before: 0;
  -webkit-margin-after: 0;
  -webkit-margin-start: 0;
  -webkit-margin-end: 0;
  margin: 0;

  &.dark {
    color: $grey--x-dark;
  }
  &.dark-blue {
    color: $blue--bold;
  }
  &.dark-grey {
    color: $dark-jordan;
  }
  &.grey {
    color: $main-silver;
  }
  &.white {
    color: $white;
  }
  &.primary {
    color: $primary;
  }
  &.positive {
    color: $positive;
  }
  &.negative {
    color: $negative;
  }
  &.scheduled {
    color: $scheduled;
  }
}

h4 {
  font-family: $base-font-family;
  font-weight: $regular;
  line-height: $base-line-height;
  font-size: 15px;

  -webkit-margin-before: 0;
  -webkit-margin-after: 0;
  -webkit-margin-start: 0;
  -webkit-margin-end: 0;
  margin: 0;

  &.dark {
    color: $grey--x-dark;
  }
  &.dark-blue {
    color: $blue--bold;
  }
  &.dark-grey {
    color: $dark-jordan;
  }
  &.grey {
    color: $main-silver;
  }
  &.white {
    color: $white;
  }
  &.primary {
    color: $primary;
  }
  &.positive {
    color: $positive;
  }
  &.negative {
    color: $negative;
  }
  &.scheduled {
    color: $scheduled;
  }
}

p {
  font-family: $base-font-family;
  font-weight: $regular;
  line-height: $base-line-height;
  letter-spacing: $letter-spacing--xxs;
  font-size: $font-sm;
  margin: 0 0 0.8em 0;

  &.dark {
    color: $grey--x-dark;
  }
  &.dark-blue {
    color: darken($blue--bold, 10%);
  }
  &.dark-grey {
    color: $dark-jordan;
  }
  &.light-grey {
    color: $main-jordan;
  }
  &.positive {
    color: $positive;
  }
  &.negative {
    color: $negative;
  }
  &.white {
    color: $white;
  }
  &.alert {
    color: darken($dark-yellow, 22%);
  }
}

span.like-p {
  font-family: $base-font-family;
  font-weight: $regular;
  line-height: $base-line-height;
  letter-spacing: $letter-spacing--xxs;
  font-size: $font-sm;
  margin: 0;

  &.truncate {
    @include truncate();
  }

  &:hover {
    pointer-events: none;
    cursor: text;
  }

  &.semibold {
    font-weight: $semibold;
  }
  &.dark {
    color: $grey--x-dark;
  }
  &.dark-blue {
    color: darken($blue--bold, 10%);
  }
  &.dark-grey {
    color: $dark-jordan;
  }
  &.light-grey {
    color: $main-jordan;
  }
  &.positive {
    color: $positive;
  }
  &.negative {
    color: $negative;
  }
  &.confirm {
    color: $aqua--bold;
  }
  &.white {
    color: $white;
  }
}

.truncate {
  @include truncate();
}

// Font size
.font-xxs {
  font-size: $font-xxs;
}

.font-xs {
  font-size: $font-xs;
}

.font-regular {
  font-size: $font-regular;
}

.font-sm {
  font-size: $font-sm;

  &--important {
    font-size: $font-sm !important;
  }
}

.font-m {
  font-size: $font-m;
}

.font-l {
  font-size: $font-l;
}

.font-xl {
  font-size: $font-xl;
}

// icon font size classes
.icon-font-xs {
  font-size: $icon-font-xs;
}

// Font weight classes
.thin {
  font-weight: $thin !important;
}

.light {
  font-weight: $light !important;
}

.regular {
  font-weight: $regular !important;
}

.semibold {
  font-weight: $semibold !important;
}

.bold {
  font-weight: $bold !important;
}

.extra-bold {
  font-weight: $extra-bold !important;
}

// Font colors
.font-color--black {
  color: $black;

  &--important {
    color: $black !important;
  }
}

.font-color--blue-bold {
  color: $blue--bold;

  &--important {
    color: $blue--bold !important;
  }
}

.font-color--blue-dark {
  color: $blue--dark;

  &--important {
    color: $blue--dark !important;
  }
}

.font-color--confirm {
  color: $confirm;
}

.font-color--dark-jordan {
  color: $dark-jordan;
}

.font-color--dark-yellow {
  color: darken($dark-yellow, 10%);
}

.font-color--yellow-dark {
  color: $yellow--dark;

  &--important {
    color: $yellow--dark !important;
  }
}

.font-color--dark-silver {
  color: $dark-silver;
}

.font-color--inherit {
  color: inherit;
}

.font-color--green-dark {
  color: $green--dark;

  &--important {
    color: $green--dark !important;
  }
}

.font-color--grey--dark {
  color: $grey--dark;

  &--important {
    color: $grey--dark !important;
  }
}

.font-color--light-blue {
  color: $blue--primary;
}

.font-color--main-aqua {
  color: $aqua--bold;
}

.font-color--main-jordan {
  color: $main-jordan;

  &--important {
    color: $main-jordan !important;
  }
}

.font-color--main-green {
  color: $main-green;
}

.font-color--main-orange {
  color: $main-orange;
}

.font-color--red--bold {
  color: $red--bold;

  &--important {
    color: $red--bold !important;
  }
}

.font-color--red--dark {
  color: $red--dark;

  &--important {
    color: $red--dark !important;
  }
}

.font-color--main-silver {
  color: $main-silver;
}

.font-color--main-blue {
  color: $blue--primary;
}

.font-color--darkBlue {
  color: $blue--dark;
}

.font-color--negative {
  color: $negative;
}

.font-color--off-white {
  color: $blue--xx-light;
}

.font-color--primary {
  color: $primary;
}

.font-color--primary_force {
  color: $primary !important;
}

.font-color--greyXXXLight {
  color: $grey--xxx-light;
}

.font-color--greyXDark {
  color: $grey--x-dark;

  &.lighten5 {
    color: lighten($grey--x-dark, 5%);
  }
}

.font-color--steel {
  color: $grey--xx-dark;
}

.font-color--xero {
  color: $xero;
}

.font-color--white {
  color: $white;
}

.font-color--grey--dark {
  color: $grey--dark;

  &--important {
    color: $grey--dark !important;
  }
}

.font-color--grey--x-dark {
  color: $grey--x-dark;

  &--important {
    color: $grey--x-dark !important;
  }
}

.font-color--grey--xx-dark {
  color: $grey--xx-dark;

  &--important {
    color: $grey--xx-dark !important;
  }
}

// Font style
.font-style--italic {
  font-style: italic;
}

// Line height
.line-height--standard {
  line-height: $paragraph !important;
}

.line-height--sm {
  line-height: $line-height--sm;

  &--important {
    line-height: $line-height--sm !important;
  }
}

.line-height--15 {
  line-height: $line-height--15;

  &--important {
    line-height: $line-height--15 !important;
  }
}

.line-height--xm {
  line-height: $line-height--xm;

  &--important {
    line-height: $line-height--xm !important;
  }
}

.line-height--16 {
  line-height: $line-height--16;

  &--important {
    line-height: $line-height--16 !important;
  }
}

.line-height--m {
  line-height: $line-height--m;

  &--important {
    line-height: $line-height--m !important;
  }
}

.line-height--18 {
  line-height: $line-height--18;

  &--important {
    line-height: $line-height--18 !important;
  }
}

.line-height--large {
  line-height: $line-height--l;

  &--important {
    line-height: $line-height--l !important;
  }
}

.line-height--xl {
  line-height: $line-height--xl;

  &--important {
    line-height: $line-height--xl !important;
  }
}

.line-height--22 {
  line-height: $spacing--22;

  &--important {
    line-height: $spacing--22 !important;
  }
}

.line-height--25 {
  line-height: $spacing--25;

  &--important {
    line-height: $spacing--25 !important;
  }
}

.line-height--26 {
  line-height: $spacing--26;

  &--important {
    line-height: $spacing--26 !important;
  }
}

// Text decoration
.text-decoration--underline {
  text-decoration: underline;
}

.text-decoration--hover-underline:hover {
  text-decoration: underline;
}

// Text alignment
.text-align--center {
  text-align: center;
}

// Word break
.word-break--break-all {
  word-break: break-all;
}

.font--emoji {
  font-family: $emoji-font-family;
}

// Word wrap
.word-wrap--break-word {
  word-wrap: break-word;
}
