@import 'css/base/color.scss';
@import 'css/base/padding.scss';
@import 'css/base/typography.scss';
@import 'css/mixins/flexbox.scss';
@import 'css/mixins/mediaQuery.scss';


$left-icon-size: 14px;

.document-v2 {

  .document-form {
    @include flexbox();
    @include align-items(center);

    height: $form-height-xl;

    &--left {
      @include flexbox();
      @include align-items(center);

      width: 56%;
      padding-left: $padding--m;

      svg {
        margin-top: $padding--xs;
      }

      a {
        color: $black;
        width: calc(100% - #{$font--14});
        font-size: $font--14;
        line-height: $line-height--22;

        span {
          display: inline-block;
          padding-left: $padding--xm;
          vertical-align: middle;

          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;

          font-weight: $semibold;

          &:hover {
            color: $primary;
          }
        }
      }

      @include media($SCREEN:(_SMALL)) {
        width: 64%;
      }
    }

    &--date {

      span {
        font-size: $font--13;
      }
    }

    &--right {
      @include flexbox();
      @include justify-content(flex-end);

      padding-right: $padding--m;
    }
  }

  @media (max-width: 500px) {
    min-width: auto;

    > .document-form {
      &--left {
        width: 50%;

        .like-p {
          font-size: 12px;
        }
      }

      &--date {
        .like-p {
          font-size: 11px;
        }
      }

      &--right {
        .like-p {
          font-size: 10px;
        }
      }
    }
  }
}
