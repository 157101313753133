@import "css/mixins/flexbox.scss";
@import "css/base/margin.scss";
@import "css/base/color.scss";
@import "css/base/typography.scss";

.required-sso-login-form {
  .links-container {
    @include flexbox();
    @include flex-direction(column);
    @include justify-content(space-between);
    margin-top: $margin--large;

    .link {
      font-size: $font-sm;
      font-weight: $semibold;
      color: $grey--xx-dark;
      line-height: $line-height--24;
    }
  }
}
