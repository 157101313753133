@import 'css/base/color.scss';
@import 'css/base/spacing.scss';
@import 'css/base/transition.scss';
@import 'css/mixins/zIndex.scss';
@import 'css/variables/sizes.scss';


.external-receipt-payment-view-details {

  &__content {
    @include zIndex(BASE);

    max-height: 0;
    overflow-y: scroll;

    position: absolute;
    left: $spacing--8;
    right: $spacing--8;
    bottom: $spacing--8;
    top: #{$external-receipt-header-height + $spacing--8};

    background-color: $white;
    transition: $transition-accordion-timing ease max-height;


    &--expanded {
      // If we just unset the max-height property, the transition won't apply. We need to set a fixed value.
      max-height: 1000px;
    }

    & > div:first-child {
      & > .external-block-item {
        // We want to remove the top border of the first item in the content in order to avoid duplicated border
        border-top-color: transparent;
      }
    }
  }
}
