@import '../base/color.scss';
@import '../base/margin.scss';
@import '../base/padding.scss';
@import '../base/typography.scss';


@mixin bullet-list() {
  line-height: $paragraph;

  > li {
    font-size: $font-sm;
    padding-left: $padding--xm;
    color: $grey--x-dark;

    &:before {
      margin-right: $margin--xm;
      content: "• ";
    }
  }
};
