@import "css/base/typography.scss";


.external-receipt-single-item {
  // Needed because we display item details as an absolutely positioned element which we want to fit inside of entire
  // external receipt container, but not overflow it
  position: relative;
  overflow-y: hidden;
  // Force min-width on external receipt component
  width: 340px;
  max-width: 100%;

  // Overrides for text components to reflect new design guidelines
  span {
    line-height: $line-height--22;
    letter-spacing: 0;
  }
}
