@import 'css/mixins/colors.scss';


// TODO: verify if we'll want this in every place and add it
// inside the main file instead of sign-up v3 folder
// error toaster class
.css-1xebggx {
  background-color: $red--05;

  &::before {
    content: unset;
  }

  h4 {
    color: $red--dark;
    font-weight: 500;
  }

}
// success toaster class
.css-m00c4x {
  background-color: $green--light;

  &::before {
    content: unset;
  }

  h4 {
    color: $green--dark;
    font-weight: 500;
  }

}

// close button class
.css-1ulbkw1{
  background: white;
  border-radius: 50%;
  // we are using a style inline fill color inside toaster :(
  // TODO: check if we can do this in any other way and if we can use
  // styled-component toast in future
  svg {
    fill: $red--dark!important;
    width: 14px;
    height: 14px;
  }
}
