@import 'css/base/color.scss';
@import 'css/base/margin.scss';
@import 'css/base/padding.scss';
@import 'css/mixins/flexbox.scss';
@import 'css/variables/global.scss';
@import 'css/variables/swal.scss';


.swal-wrapper {
  &__close-button {

    &.btn-v2 {
      border: none;
      border-radius: 50%;
      padding: .313rem;
      
      &:hover {
        background-color: $blue--x-light;
      }
    }
  }

  &__heading {
    padding-top: $padding--large;

    &--title {
      overflow-wrap: anywhere;
    }

    &--image > img {
      height: 134px;
      margin-bottom: $margin--larger;
    }

    &--subtitle {
      padding-top: $padding--xm;
    }
  }

  &__content {
    padding: $padding--m 0;
  }

  &__footer {
    @include flexbox();
    @include flex-flow(row);
    @include align-items(center);
    @include justify-content(space-between);

    height: $footer-height;

    margin: 0 $margin--large*-1 $margin--large*-1 $margin--large*-1;
    padding: $padding--m $padding--large;

    border-top: $border--inset-content;
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;

    overflow: hidden;

    &--success {
      background-color: $aqua--light;
    }

    &--primary {
      background-color: lighten($blue--primary, 30%);
    }

    &--danger {
      background-color: $red--light;
    }

    &.footer-ghost {
      background-color: $white;
      border-top: $border--grey-x-light;
    }
  }
}
