@import 'css/base/color.scss';
@import 'css/base/padding.scss';
@import 'css/base/margin.scss';
@import 'css/base/typography.scss';
@import 'css/variables/global.scss';
@import 'css/variables/sizes.scss';
@import 'css/functions.scss';


$placeholder--bottom: 15px;
$placeholder--left: 59px;
$label-toggle-transition: transform 600ms cubic-bezier(0.075, 0.82, 0.165, 1);

@mixin caret($color) {
  $encoded-color: str-replace(#{$color}, '#');
  background-image: url("data:image/svg+xml;utf8,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%20xmlns:xlink='http://www.w3.org/1999/xlink'%20width='13'%20height='13'%20fill='%23#{$encoded-color}'%3E%3Cpath%20d='M12%206.5c0-.28-.22-.5-.5-.5h-7a.495.495%200%200%200-.37.83l3.5%204c.09.1.22.17.37.17s.28-.07.37-.17l3.5-4c.08-.09.13-.2.13-.33z'%20fill-rule='evenodd'/%3E%3C/svg%3E");
}

// when label is shown, push input and flag for 8px
$translate-top-value: $spacing--8;

// customize third-party react-phone-number-input styling
.PhoneInput {
  position: absolute;
  transition: $label-toggle-transition;
  &.showLabel {
    transform: translateY($translate-top-value);
  }

  .PhoneInputCountry {
    margin-right: $margin--sm;
  }

  // customize actual input element
  .intl-phone-input {
    border-bottom: none;
    font-size: $font-sm;
    height: $line-height--m;

    &::placeholder {
      display: block;
    }
  }

  // set flag border style
  .PhoneInputCountryIcon.PhoneInputCountryIcon--border {
    box-shadow: none;
    background: transparent;
    border: $border--inset-content;
  }

  // set caret style
  .PhoneInputCountrySelectArrow {
    height: $icon-size--13;
    width: $icon-size--13;
    margin: 0 0 $margin--xs $margin--xs;
    border: none;
    transform: none;

    @include caret($dark-silver);
  }

  // make the caret blue when hovering over flag
  .PhoneInputCountrySelect:hover + .PhoneInputCountryIcon + .PhoneInputCountrySelectArrow {
    @include caret($blue--primary);
  }

  // override caret's switch to third-party teal on select:focus
  .PhoneInputCountrySelect:focus + .PhoneInputCountryIcon + .PhoneInputCountrySelectArrow {
    color: inherit;
    @include caret($dark-silver);
  }

  ~.placeholder {
    // !important needed to override the value from Input.scss
    bottom: $placeholder--bottom !important;
    left: $placeholder--left;
    transition: $label-toggle-transition;
    &.showLabel {
      transform: translateY(8px);
    }
  }
}

label.intl-phone-input-label {
  color: $grey--x-dark;
  font-size: $font-xs;
  font-weight: $semibold;
  position: absolute;
  top: $spacing--8;
  display: none;

  // Label is not a direct sibling to the input, so we to apply classes to handle
  // what is normally done by pseudo-classes in forms.scss
  &.isFocused {
    color: $blue--bold;
  }

  &.showLabel {
    display: block;
  }
}

// disabled styles
.form-style.disabled {
  // wrapper for whole InternationalPhoneInput component
  & > .PhoneInput {

    // When disabled, the select displays some ugly, native dropdown icons over the flag
    .PhoneInputCountrySelect {
      // hide them
      display: none;
    }

    // match disabled input text color
    .intl-phone-input {
      color: $dark-jordan;
    }

    // make flag look disabled by lightening it
    .PhoneInputCountryIcon.PhoneInputCountryIcon--border {
      opacity: 0.5;
    }
  }
}
