@import 'css/base/color.scss';
@import 'css/base/typography.scss';
@import 'css/mixins/flexbox.scss';


.secondary-action-link {
  @include flexbox();
  color: $dark-jordan;
  font-size: $font-xs;
  font-weight: $bold;

  svg {
    align-self: center;
  }
}
