@import 'css/mixins/flexbox.scss';
@import 'css/mixins/includeMedia.scss';
@import 'css/variables/global.scss';
@import 'css/variables/modal.scss';

.external-receipt-payment-line-items-modal {
  border: none;

  .modal-body {
    max-height: 70vh;
    overflow-y: auto;

    @include media('<=tablet_small') {
      max-height: unset;
      height: 100vh;
    }

    &.has-footer {
      @include media('<=tablet_small') {
        height: calc(100vh - #{$nav--height} - #{$modal-footer-height});
      }
    }
  }


  .modal-footer {
    @include media('<=tablet_small') {
      position: absolute;
      bottom: 0;
      left: 0;
    }

    @include media('<=phone_large') {
      margin-bottom: 0;
    }
  }

  .modal-footer--container {
    .content--right {
      @include media('<=phone_large') {
        @include justify-content(flex-end);

        .modal-footer--button {
          width: auto;
          margin-bottom: 0;
        }
      }
    }
  }
}