@import 'css/base/color.scss';
@import 'css/base/padding.scss';
@import 'css/base/typography.scss';
@import 'css/mixins/flexbox.scss';
@import 'css/variables/global.scss';


.shaded-block {
  @include flexbox();
  @include flex-flow(row nowrap);
  @include align-items(flex-start);
  @include justify-content(flex-start);

  background-color: $white;
  border: $border--inset-content;
  border-radius: 4px;
  padding: $padding--xm $padding--m;

  &.padding--12 {
    padding: $spacing--12;
  }

  // Shade colors
  &.danger {
    background-color: $red--light;
  }

  &.info {
    background-color: $blue--x-light;
  }

  &.neutral {
    background-color: $blue--x-light;
  }

  &.schedule {
    background-color: $extra-light-purple;
  }

  &.success {
    background-color: $aqua--light;
  }

  &.warning {
    background-color: $extra-light-yellow;
  }
}
