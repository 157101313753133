@import 'css/base/block.scss';
@import 'css/base/color.scss';
@import 'css/base/margin.scss';
@import 'css/base/padding.scss';
@import 'css/base/typography.scss';
@import 'css/mixins/flexbox.scss';
@import 'css/mixins/includeMedia.scss';
@import 'css/variables/global.scss';


.add-partner-funding-account--options {

  .add-partner-funding-account--options--apply-pending {

    .item-status-box--container {
      padding: 0 $padding--sm;
    }

      .content--right {

      & > span {
        @include flexbox();

        span {
          @include align-self(center);
        }
      }
    }
  }

  .disclosure-body {

    .add-partner-funding-account--options--replace {

      .add-partner-account--options--control {
        background-color: $white;
        border-radius: $block-border-radius--sm;
      }
    }

    .add-partner-account--options--statuses {

      .item-status-box--container {
        padding-left: $padding--sm;
        padding-right: $padding--sm;
        margin-right: $margin--xm;

        &:first-child {
          margin-left: 0;
        }
      }
    }
  }


  .details-list {
    &.disclosure-body-transitions {
      background: $blue--xx-light;
    }

    .column {
      padding: 0 0 $spacing--12 0;

      &:last-child {
        padding: 0;
      }
    }
  }

  .checkbox--container {
    display: block;
  }
}
