@import 'css/base/color.scss';
@import 'css/base/margin.scss';
@import 'css/base/padding.scss';
@import 'css/base/sizes.scss';
@import 'css/base/typography.scss';
@import 'css/mixins/flexbox.scss';
@import 'css/mixins/includeMedia.scss';


.check-list {
  list-style-type: none;

  .check-list--item {
    @include flexbox();
    color: $dark-jordan;
    font-size: $font-regular;
    line-height: $paragraph;
    padding: $padding--xs 0;

    @include media(">=tablet_large") {
      padding: $padding--sm 0;
    }

    .check-list--item--bullet-wrapper {
      height: 100%;
      width: $icon-size--small;
      margin-right: $margin--xm;
    }

    .check-list--item--bullet {
      vertical-align: middle;
    }

    .check-list--list {
      margin-top: $margin--xm;
    }
  }
}
