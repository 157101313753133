@import 'css/base/sizes.scss';
@import 'css/base/spacing.scss';
@import 'css/base/table.scss';
@import 'css/mixins/flexbox.scss';
@import 'css/mixins/includeMedia.scss';
@import 'css/mixins/scrollbar.scss';
@import 'css/variables/global.scss';

$section-class-prefix: 'w-tablematic-section--';
$cell-field-class-prefix: 'w-tablematic-cell--';
$field-class-prefix: 'w-tablematic--';
$field-min-width: 205px;
// max-width is two-thirds of the row, and account for padding
$field-max-width: calc((100% / 3) * 2 - #{$spacing--8});

/**
  Handling horizontal scroll in tablematic:

  Since we don't know in advance how many fields the table must render,
  the table row needs to be scrollable. This is handled with .horizontal-scroll-group
  wrapper. Due to some overflow issues and in order for the Select Menus to be positioned correctly,
  in relation to their parent section, the parent section needs to have position: relative
  property.
*/
.horizontal-scroll-group--parent {
  position: relative;

  // This is needed in case there are multiple scrollable tables inside the same
  // .horizontal-scroll-group--parent wrapper. It ensures that Select Menu is positioned
  // relatively to the correct parent table.
  .platform-table--inline {
    position: relative;
    // overflow: hidden;
  }
}

.is-on-details-page {
  &.horizontal-scroll-group--parent {
    > div:first-child {
      gap: var(--spacing-8);
    }
  }

  .stack-on-mobile {
    flex-direction: row;
  }

  .platform-table--inline {
    overflow: hidden;
    border-radius: 4px;
  }

  .tablematic-table-header-container .table-header-cell {
    background-color: var(--color-grey05);
    border-right: none;
    .header-cell-text {
      color: var(--color-grey60);
    }
  }
}

.horizontal-scroll-group {
  @include forceScrollbars();

  // We only want scrollbars visible for the X direction if needed hence
  // overflow-x auto. Overflow-y should be set to hidden to prevent unwanted
  // ghost scroll effect (extra scrolling on an area with no content - for eg., an empty area under line
  // items in create item)
  overflow-y: hidden;
  overflow-x: auto;

  // This is needed for fixing the issue in Safari where scrollbar
  // is rendered on top of the select menu dropdown. Adding transform: translate3d
  // to the scrolling container is a workaround to fix this issue.
  // See https://warrenpay.atlassian.net/browse/FRON-2688
  transform: translate3d(0, 0, 0);

  border-top-left-radius: $table-border-radius;
  border-top-right-radius: $table-border-radius;
}

/**
  Field sizing in tablematic:

  It works by first defining a base min-width for a category of fields (min for block fields, min for table fields).
  This min size will be the lower bound; thus, size unit 1 is clamped to this value.
  The upper bound is 20, which is actually clamped to 100% of the width of its container, as it is the max.
  We then apply a flex-grow value to the field, equal to its size.

  The end effect is that we are creating a relationship between field sizes in a block or table row, and
  are never hard-setting any widths.

  Tables also get a flex-basis value, or the fields (uniformly) don't grow as quickly as they should.
 */

// section sizes are as a percentage, from 25% to 100%
@for $i from 25 through 101 {
  .#{$section-class-prefix}#{$i} {
    max-width: calc(#{$i} * 1%);
    width: calc(#{$i} * 1%);
  }
}

// field sizes are as an enum, from 1 to 20
@for $i from 1 through 21 {
  .#{$field-class-prefix}#{$i} {
    flex-basis: 20%;
    flex-grow: $i;
    min-width: $field-min-width;
  }
}

// field sizes of 1 are special in that they don't receive a flex-grow value, and are always locked
// to the min field size
.#{$field-class-prefix}1 {
  max-width: $dynamic-field-base;
  min-width: $dynamic-field-base;
}

// field sizes of 20 are special. the enum values are clamped, so size 20 is full-width.
.#{$field-class-prefix}20 {
  flex-basis: 100%;
  min-width: 100%;
  max-width: 100%;
}

// !important must be added here in order to override sizing that is coming from
// Table.scss .table-row--column rule sets

// Bishop: Added in flex-basis for older versions of chrome/edge on windows
// if you do not set the flex-basis it is like setting the width to unset on certain
// versions of chrome/edge on windows so by setting flex-basis you can basicly say
// this is the default width don't shrink it :)
.#{$cell-field-class-prefix}1 {
  min-width: 40px;
  flex-basis: 40px !important;
  flex-grow: 0 !important;

  // Content should be centered for width: 1
  justify-content: center;
}

.#{$cell-field-class-prefix}2 {
  min-width: 60px;
  flex-basis: 60px !important;
  flex-grow: 1 !important;
  
  // Content should be centered for width: 2
  justify-content: center;
}

.#{$cell-field-class-prefix}3 {
  min-width: 80px;
  flex-basis: 80px !important;
  flex-grow: 1 !important;
}

.#{$cell-field-class-prefix}4 {
  min-width: 120px;
  flex-basis: 120px !important;
  flex-grow: 1 !important;
}

.#{$cell-field-class-prefix}5 {
  min-width: 160px;
  flex-basis: 160px !important;
  flex-grow: 2 !important;
}

.#{$cell-field-class-prefix}6 {
  min-width: 240px;
  flex-basis: 240px !important;
  flex-grow: 2 !important;
}

.#{$cell-field-class-prefix}7 {
  min-width: 320px;
  flex-basis: 320px !important;
  flex-grow: 3 !important;
}

.#{$cell-field-class-prefix}8 {
  min-width: 480px;
  flex-basis: 480px !important;
  flex-grow: 4 !important;
}

.#{$cell-field-class-prefix}20 {
  flex-basis: 100% !important;
}

.tablematic-block-row {
  width: 100%;

  // the last section in a layout shouldn't dictate the spacing below it
  &:last-child {
    margin-bottom: 0;
  }

  // handle nested rows
  > :last-child {
    margin-right: 0 !important;
  }
}

.tablematic--tabbed-section {
  &:not(:first-child) {
    // a tabbed section that has other sections above it needs a bit of extra spacing for visual balance
    margin-top: $spacing--12;
  }

  .tab-section {
    &:not(.active) {
      height: 0;
      width: 0;
      overflow: hidden;
    }
  }

  .tab-switcher {
    margin-bottom: $spacing--24;

    .action-nav {
      background-color: transparent;
      // remove the border from the bottom of the tabs parent, because we
      // will have multiple lines of tabs possible if they don't all fit on one line,
      // and so each pseudo-row (i.e. wrapped flex children) will get a bottom border instead.
      border-bottom: none;
      box-shadow: none;
      padding: 0 0 0 0;

      .action-nav--main-content .action-nav-tabs--list {
        min-width: 100%;

        .tablematic--tab {
          // the tabs parent border is now on each child item, so we can fake
          // having actual "row" elements to attach bottom borders onto
          border-bottom: $action-nav--border;
          position: relative;

          &:not(:last-of-type) {
            // (here: where the fun starts)

            // background:
            // - we'll be rendering an unknown number of tabs, each of which has content of unknown length
            // - unlike with block fields, there is no "size" property available on tabs in the switcher
            // - currently, we are not equipped to handle the user's specific device resolution, font setup, etc

            // per the design spec, requirements are as follows:
            // - each tab should size exactly to fit its contents
            //   • no default min-width, and no default max-width, there can be only content-width
            //   • tabs should NOT be auto-fit (no stretching to fill the available container space)
            // - a bottom border should be present for each wrapped row of tabs
            //   • this border must span the entire available space (so, the row border needs to auto-fit, but not the tabs)
            //   • there is no "row" element to attach this border to, as tabs are just flex children
            // - needs to fully re-flow tabs as needed, since the tabs are obscuring important and/or required item data
            // - tabs alignment is flex-start

            // to meet these requirements, i did not find a workable solution using either grid or flexbox. so
            // instead, this uses ::after pseudo-elements. in theory, regardless of the quirks of a particular
            // browser, this should draw borders that are consistent.
            &::after {
              // this will fill the remaining container space if we need it to
              @include flex-basis(100%);

              // drawn to the bottom right of every tab, creating a bottom border for the next tab or filling empty space
              // at the end of the tab row (this creates the fake row elements)
              border-bottom: $action-nav--border;
              content: '';
              display: block;
              left: 100%;
              position: absolute;
              top: 100%;
              width: 100%;
            }
          }

          .tab {
            @include flex-col-center();

            height: $nav--inline-height;
            opacity: 1;
            margin-right: 0;
            padding-bottom: 0;
            padding-top: 0;
            top: 0;

            > span {
              padding: 0 $spacing--32;
              text-align: center;
            }

            .tab-text {
              font-weight: 400;
              color: $grey--x-dark;
            }

            &:hover,
            &:focus,
            &.selected {
              .tab-text {
                color: $black;
              }
            }

            svg {
              // we don't want the width of the tab to fluctuate when rendering error indicators, as it re-flows
              // tabs to the next line and back again. this is fixed by absolutely positioning the indicator,
              // and setting it to live at the edge of the tab's padding-right, with breathing room.
              position: absolute;
              right: $spacing--12;
              // 7px is an odd value (also literally), but with the icon size and padding amount,
              // this vertically centers it
              top: calc(50% - 7px);
            }

            &.selected {
              top: 0;

              &:after {
                bottom: 0;
                position: absolute;
                width: 100%;
              }
            }
          }
        }

        // important to make our fake tab rows work: after all the tabs are rendered, we add
        // one more block element to stretch over all remaining container space, and finish
        // the bottom border.
        .spacer-tab {
          @include flex-grow(1);
          border-bottom: $action-nav--border;
        }
      }
    }
  }

  &.is-on-details-page {
    .tab-switcher {
      margin-bottom: 0;
    }
  }

  .details-page-field-wrapper {
    &:not(.size-5) {
      // unset max-width when only one row (less than 4 fields in row)
      max-width: unset;
    }
  }
}

.details-page-field-wrapper {
  // 1/4 - gap
  min-width: 205px;
  max-width: calc(25% - 9px);

  @include media("<=phone_large") {
    min-width: 100%;
    max-width: 100%;
  }
}