@import '../../../global/css/base/block.scss';
@import '../../../global/css/base/color.scss';
@import '../../../global/css/base/margin.scss';
@import '../../../global/css/base/padding.scss';
@import '../../../global/css/base/transition.scss';
@import '../../../global/css/base/typography.scss';
@import '../../../global/css/mixins/flexbox.scss';
@import '../../../global/css/variables/global.scss';

.partner-payment-method-status {
  background-color: $white;
  padding: $padding--xm $padding--m;
  border-radius: $block-border-radius--xm;
  border: $border--blue-light;

  .partner-payment-method-status--btn-wrapper {
    @include flexbox();
    @include align-items(center);
    height: $form-height-sm;
  }

  .btn {
    span {
      transition: color $transition-color-timing;
    }

    svg {
      color: $grey--x-dark;
    }

    &:hover {
      span {
        color: $blue--bold;
      }
    }
  }

  &.enabled {
    .partner-payment-method-status--text-wrap {
      svg {
        color: $green--bold;
      }
    }
  }

  &.has-error {
    border-color: $red--bold;
    .partner-payment-method-status--text-wrap {
      span {
        div {
          color: $red--bold;
        }
      }
    }
  }
}

.partner-payment-method-status--text-wrap {
  min-height: 100%;

  svg {
    vertical-align: text-bottom;
    color: $yellow--bold;
  }

  p {
    color: $grey--x-dark;
    font-size: $font-xs;
    font-weight: $semibold;
    line-height: $paragraph;

    &.dark {
      color: $grey--x-dark;
    }
  }
}

.modal-body--content:not(.create-partnership--body) {
  .partner-payment-method-status {
    background-color: transparent;
    border: 0;
    padding: 0;
    margin-bottom: 0;
  }

  .partner-payment-method-status--btn-wrapper {
    display: none;
  }
}
