@import 'css/base/color.scss';
@import 'css/base/margin.scss';
@import 'css/base/spacing.scss';
@import 'css/base/typography.scss';
@import 'css/mixins/text.scss';
@import 'global/css/mixins/includeMedia.scss';
    

.partnership-company-form {
  .form-field--label {
    color: $grey--xx-dark;
    font-size: $font-m;
    margin-bottom: $margin--m-large;
    line-height: $line-height--24;
  }

  .checkbox--text-wrapper {
    .font-size--regular {
      // CheckboxTitle uses Text.Regular which doesn't support using 14px font
      font-size: $font-sm !important;
    }
  }

  .Select {
    .Select__input,
    .Select__placeholder,
    .Select__sinele-value {
      @include truncate();
      width: 100%;
    }
  }

  .radio--container {
    .radio--title div {
      span {
        @include truncate();
      }
    }
  }

  .input-container {
    .placeholder {
      @include truncate();
      width: calc(100% - #{$spacing--32});
    }
  }
}
