@import 'css/mixins/includeMedia.scss';


.setting-info-bar {
  width: 100%;

  .option-select-menu {
    width: 100%;

    @include media(">=phone_large") {
      max-width: 200px;
      width: unset;
    }
  }
}
