@import 'css/base/block.scss';
@import 'css/base/color.scss';
@import 'css/base/margin.scss';
@import 'css/base/padding.scss';
@import 'css/base/typography.scss';
@import 'css/mixins/flexbox.scss';
@import 'css/mixins/includeMedia.scss';
@import 'css/variables/global.scss';


.interaction-bar {
  background-color: $white;
  padding: $padding--xm $padding--m;
  border-radius: 4px;
  border: $border--blue-light;
  // ensure that all interaction bars have the same minimum height, regardless of their contents
  // example: ContactInteractionsList may have a button or now, which affects the height of the bar
  min-height: 54px;

  p {
    min-height: $form-height-sm;
    line-height: $form-height-sm;
  }

  .btn {
    margin-bottom: 0;

    &.button-right {
      margin-left: $margin--m;
    }

    @include media('<=phone_large') {

      &.button-right {
        margin-left: $margin--sm;
      }
    }
  }
}
