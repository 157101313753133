@import '../base/color.scss';
@import '../base/margin.scss';
@import '../mixins/boxShadow.scss';
@import '../mixins/includeMedia.scss';


/* Box shadow */
.box-shadow--regular {
  @include box-shadow(0 2px 3px var(--color-grey20));
}

/* Cursor */
.cursor {
  &--default {
    cursor: default;

    &--important {
      cursor: default !important;
    }
  }

  &--inherit {
    cursor: inherit;

    &--important {
      cursor: inherit !important;
    }
  }

  &--pointer {
    cursor: pointer;

    &--important {
      cursor: pointer !important;
    }
  }
}

/* Pointer Events */
.enable-pointer-events {
  pointer-events: all;
}

.disable-pointer-events {
  pointer-events: none;
}

/* Alignment */
.float-left {
  float: left;
}

.float-right {
  float: right;
}

/* Responsive */
.hide-on-phone {
  @include media("<=phone_large") {
    display: none;
  }
}

/* Hover */
.hover-primary:hover {
  color: $primary;

  .hover-primary {
    color: $primary;
  }
}

.hover-success:hover {
  color: $aqua--bold;

  .link {
    color: $aqua--bold;
  }
}

.hover--no-background:hover {
  background-color: transparent !important;
}

/* List styles ul/ol */
.list-style--lower-alpha {
  list-style: lower-alpha;
}

/* Opacity */
.opacity-standard {
  opacity: 0.5;
}


