@import '../base/color.scss';
@import '../mixins/avatarStyle.scss';


.avatar--container {
  position: relative;
}

img.avatar {
  position: absolute;
  background-color: $white;
}

.avatar {
  &.avatar--light {
    @include avatarStyle(var(--color-grey20));
  }

  &.avatar--dark {
    @include avatarStyle(lighten($blue--bold, 4%));
  }

  &.avatar--xxs {
    @include avatarSize(12px);

    .missing-avatar {
      font-size: 6px;
    }
  }

  &.avatar--approval {
    @include avatarSize(16px);

    .missing-avatar {
      font-size: 6px;
    }
  }

  &.avatar--modal {
    @include avatarSize(22px);

    .missing-avatar {
      font-size: 8px;
    }
  }

  &.avatar--payment-method {
    @include avatarSize(18px);

    .missing-avatar {
      font-size: 6px;
    }
  }

  &.avatar--sidebar {
    @include avatarSize(18px);

    .missing-avatar {
      font-size: 8px;
    }
  }

  &.avatar--nav {
    @include avatarSize(24px);
  }

  &.avatar--xs {
    @include avatarSize(30px);
  }

  &.avatar--sm {
      @include avatarSize(34px);
  }

  &.avatar--m {
    @include avatarSize(50px);
  }

  &.avatar--xl {
      @include avatarSize(72px);
  }

  &.initials-container {
    border: none;
  }
}
