@import '../../../global/css/base/block.scss';
@import '../../../global/css/base/color.scss';
@import '../../../global/css/base/margin.scss';
@import '../../../global/css/base/padding.scss';
@import '../../../global/css/base/transition.scss';
@import '../../../global/css/base/typography.scss';
@import '../../../global/css/mixins/includeMedia.scss';
@import '../../../global/css/mixins/scrollbar.scss';
@import '../../../global/css/variables/global.scss';


.partner-payment-method {
  border-radius: $block-border-radius--xm;
  width: 100%;

  .loading {
    background-color: transparent;
    border: $border--blue-light;
    border-top: 0;
    border-radius: 0 0 $block-border-radius--sm $block-border-radius--sm;
  }

  .partner-payment-method--accounts {
    background-color: $grey--xx-light;
    padding: $padding--m;
    border: $border--blue-light;
    border-top: 0;
    border-radius: 0 0 $block-border-radius--xm $block-border-radius--xm;
    max-height: 460px;
    overflow-y: auto;

    .bank-account-radio {
      margin-top: $margin--m;
      transition: border-color 0.2s;

      &:hover {
        border-color: $blue--bold;
      }

      &:first-child {
        margin-top: 0;
      }
    }

    .partner-payment-method--actions {
      margin-top: $margin--m;

      button {
        transition: color $transition-color-timing;
        color: $grey--x-dark;

        &:hover {
          color: $primary;
        }
      }
    }
  }

  &.has-accounts {

    .partner-payment-method-status {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  &.expanded {
    .partner-payment-method--accounts {
      @include forceScrollbars();
    }
  }
}

.modal-body--content:not(.create-partnership--body) {

  .partner-payment-method--accounts {
    padding: 0;
    border: 0;
  }
}

.partner-payment-method--modal-footer {

  .btn-wrapper--action {

    @include media("<=phone_large") {
      // override base action button styles which are already important-ed
      width: 100% !important;
    }
  }
}
