@import 'css/base/color.scss';
@import 'css/base/typography.scss';
@import 'css/base/spacing.scss';

.toggle-row-container {
  position: relative;
  border: $border--blue-light;
  border-radius: 6px;
  box-sizing: border-box;
  background-color: $white;
}

.toggle-row {
  padding: $spacing--12;
}
