@import '../../../global/css/base/color.scss';
@import '../../../global/css/mixins/flexbox.scss';
@import '../../../global/css/variables/global.scss';


.background {
  @include flexbox();
  @include align-items(center);
  @include justify-content(center);

  width: 100%;
  max-height: 100%;
  margin: 0 auto;

  > div {
    width: 88%;
    max-width: 1024px;
  }

  &.has-nav {
    min-height: calc(100vh - #{$nav--height});

    &.with-footer {
      min-height: calc(100vh - #{$nav--height} - #{$footer--height});
    }
  }

  &.has-loader {
    min-height: 100vh;
  }

  &.main {
    background-color: desaturate($blue--primary, 12%);
  }

  &.dark {
    background-color: $grey--x-dark;
  }

  &.light {
    background-color: $transparent;
  }
}
