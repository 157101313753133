@import '../base/color.scss';
@import '../base/typography.scss';

.tool-tip {
  background-color: $grey--xx-dark !important;
  border-radius: 4px !important;
  color: $blue--xx-light !important;
  font-family: $base-font-family;
  font-size: $font-xs !important;
  font-weight: $bold;
  min-width: 215px;
  opacity: 0.98 !important;
  padding: 8px 12px !important;
  text-align: center;

  &.place-left {
    &:after {
      border-left-color:  $grey--xx-dark !important;
    }
  }

  &.place-right {
    &:after {
      border-right-color: $grey--xx-dark !important;
    }
  }

  &.place-top {
    &:after {
      border-top-color: $grey--xx-dark !important;
    }
  }
}
