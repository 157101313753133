@import 'css/base/color.scss';
@import 'css/base/margin.scss';
@import 'css/base/padding.scss';
@import 'css/base/transition.scss';
@import 'css/base/typography.scss';
@import 'css/mixins/alignment.scss';
@import 'css/mixins/flexbox.scss';


/* component-specific variables */
$element-border: 1px solid var(--color-grey20);
$element-border--hidden: 1px solid transparent;
$widget-transition-short: max-height $transition-accordion-timing-short;
$widget-transition: max-height $transition-accordion-timing;

.entity-widget {
  border: $element-border;
  border-radius: 6px;
  overflow: hidden;
  max-width: 650px;

  &--full {
    max-width: 100%;
  }

  .entity-widget--section {
    border-bottom: $element-border;

    &.entity-widget--hint-section {
      background-color: $blue--x-light;
      padding: $padding--m;

      .hint {
        background-color: transparent;
        border-left: 0;
        box-shadow: none;
        padding: 0;
      }

      .entity-widget--hint-section--actions {
        padding: $padding--m $padding--large 0;
      }
    }

    &:last-child {
      border-bottom: 0;
    }
  }

  .entity-widget--header {
    background-color: $white;
    min-height: 56px;
    width: 100%;
    padding: $padding--m;
    text-align: left;
    // because we append a border to the bottom of the header when the border is open, if we don't include at least a
    // fake border that takes up the same space, the header appears to jump a little do to the 1px change in size
    border-bottom: $element-border--hidden;
    transition: border-bottom 0.02s;
    transition-delay: 0.18s;

    &.collapsible {
      cursor: pointer;

      &:hover {

        .entity-widget--header-title {
          color: $blue--primary;
        }
      }
    }
  }

  .entity-widget--header-titles {
    height: 100%;

    .entity-widget--header-title {
      font-size: $font-xs;
      transition: color 0.2s;
      color: $grey--xx-dark;
    }

    // When placed in a disclosure list, padding is applied to children.
    // Ensure there is no padding in the titles of an EntityWidgetHeader
    > * {
      padding: 0;
    }

    p {
      vertical-align: text-top;
    }

    svg {
      @include v-align-icon();
    }
  }

  .entity-widget--body {
    max-height: 0;
    overflow: hidden;
    transition: $widget-transition-short;
  }

  &.partner-company-connect-bank {
    margin-bottom: $margin--m;
  }

  &.open {

    .entity-widget--header {
      border-bottom: $element-border;
      transition: border-bottom 0.01s;
      transition-delay: 0s;
    }

    .entity-widget--body {
      // https://stackoverflow.com/questions/3311299/css-transition-not-working-for-percentage-height/3838803
      // https://github.com/routablehq/frontend/pull/3037/files/8dc27e34737066b1d8b6efd5e53f193f25935769#r648005046
      // We must set an absolute value for max-height (instead of 100%, for example), to get the transition to work.
      // TODO https://warrenpay.atlassian.net/browse/DEV-1511: Use programmatic solution
      max-height: 5000px;
      transition: $widget-transition;
    }
  }
}

/*
 * A workaround for vertically aligning the radio button with the bank details
 * The bug is only present on BankAccountRadio -> full (on Storybook)
 * and this is because bank details occupy only 1 line of space
 * So we ONLY select the .entity-widget--header-title which is NOT a child of .partner-payment-method--accounts
 * which applies to three cases: BankAccountRadio -> full / address / with body
 * We add margin-top: -5px; so it looks the same on address / with body
 */
div:not(.partner-payment-method--accounts) {
  > .bank-account-radio {
    .entity-widget--header-titles {
      @include flexbox();
      @include flex-flow(column);
      @include align-items(flex-start);

      .entity-widget--header-title {
        margin-top: 5px;
      }

      .bank-account--address {
        margin-top: -5px;
      }
    }
  }
}
