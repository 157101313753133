@import '../base/color.scss';


@mixin box-shadow($values) {
  // offset-x | offset-y | blur-radius | spread-radius | color
  box-shadow: $values;
}

@mixin box-shadow--white-background() {
  @include box-shadow(0 4px 8px var(--color-grey20));
}

@mixin box-shadow--extra-light() {
  @include box-shadow(1px 1px 1px transparentize($blue--x-light, 0.5));
}

@mixin box-shadow--light() {
  @include box-shadow(0 2px 6px $main-silver);
}

@mixin box-shadow--ghost() {
  @include box-shadow(0 1px 3px var(--color-grey20));
}

@mixin box-shadow--dark() {
  @include box-shadow(0 2px 6px rgba(77, 92, 113, 0.3));
}
