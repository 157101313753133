.ocr-shared-overlay {
  background: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .ocr-circle {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100px;
    height: 100px;
    border-radius: 100%;
    animation: ocr-spin 1.4s infinite linear;
    background: conic-gradient(
      from 0deg at 50% 50%,
      rgba(60, 101, 241, 0.1) 0%,
      rgba(60, 101, 241, 0.9) 100%,
      rgb(60, 101, 241) 100%
    );
  }

  .ocr-inner-circle {
    content: ' ';
    display: flex;
    justify-content: center;
    align-items: center;
    top: 3px;
    left: 3px;
    width: 64px;
    height: 64px;
    border-radius: 100%;
    background: #ffffff;
    animation: spin 1.4s infinite linear reverse;
  }

  @keyframes ocr-spin {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
}

.ocr-shared-container {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  align-items: center;

  &[hidden] {
    display: none;
    overflow: hidden;
    width: 0px;
    height: 0px;
    z-index: -1;
  }
}
