@import 'css/base/margin.scss';
@import 'css/base/spacing.scss';
@import 'css/mixins/flexbox.scss';


@mixin invoiceListTextWrapper() {
  min-height: initial;
  width: 100%;
}

.invoice-list-text {
  &.grid {
    @include invoiceListTextWrapper();
  }
}

.invoice-link--button {
  @include flex(0.2);
  margin: 0 0 0 $margin--sm !important; // important needed to override .btn margin
  pointer-events: all;

  &:hover {
    text-decoration: none;
  }
}
