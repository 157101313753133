@import 'css/base/color.scss';
@import 'css/base/margin.scss';
@import 'css/base/padding.scss';
@import 'css/base/sizes.scss';
@import 'css/base/typography.scss';
@import 'css/mixins/flexbox.scss';
@import 'css/mixins/includeMedia.scss';


.base-option {

  .base-option--content {
    align-self: center;
    max-width: calc(100% - 48px);

    .base-text {
      @include inline-flex-col-center();
      // tagged options should always be using `rect` for now
      height: $tag-rect-height;

      span {
        line-height: initial;
      }
    }
  }

  &.tagged-option {

    .base-option--content {

      @include media("<tablet") {
        max-width: 100%;
        width: 100%;
      }
    }

    .tag {
      @include align-self(center);
    }
  }

  .base-option--label {
    font-size: $font-xs;
    font-weight: $bold;
    margin-right: $margin--xm;
    min-width: 0;
  }
}

.verbose-option {

  .verbose-option--label {
    font-size: $font-xs;
    min-width: 0;

    & > span {
      display: inline-block;
      max-width: 100%;
      padding-right: $padding--xs;
    }
  }
}
