@import 'css/base/block.scss';
@import 'css/base/color.scss';
@import 'css/base/margin.scss';
@import 'css/base/padding.scss';
@import 'css/base/transition.scss';
@import 'css/base/typography.scss';
@import 'css/mixins/flexbox.scss';
@import 'css/variables/global.scss';


.disclosure-list.transaction-details {
  padding-bottom: 0;
  max-width: 100%;

  .disclosure-header {
    border-bottom: 0 solid $white;
    margin-bottom: 0;
    transition: $transition-border-btm-color, $transition-margin-btm;
  }

  .heading-title {
    color: $grey--x-dark;
  }

  .transaction-details--header-inner {
    @include flexbox();

    svg {
      @include align-self(center);
    }
  }

  .transaction-details--fees {
    margin-top: $margin--sm;

    .relation_transaction-fee {
      margin-bottom: $margin--sm;
    }

    .relation_transaction-fee:last-child {
      margin-bottom: 0;
    }
  }
}
