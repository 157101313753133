@import 'css/base/color.scss';
@import 'css/base/typography.scss';


.status-badge--icon {
  vertical-align: text-top;

  &.active {
    color: $aqua--bold;

    &.pending,
    &.pending_verification {
      color: darken($dark-yellow, 10%);
    }
  }

  &.disabled {
    color: $red--bold;
  }

  &.deleted {
    color: $main-jordan;
  }
}
