@import "css/base/sizes.scss";
@import "css/base/spacing.scss";
@import "css/base/table.scss";
@import "css/base/typography.scss";
@import "css/mixins/borders.scss";
@import "css/mixins/flexbox.scss";
@import "css/mixins/includeMedia.scss";
@import "css/mixins/scrollbar.scss";
@import "css/mixins/text.scss";
@import "css/variables/global.scss";

$border--radius: 8px;
$border--default: $table-border--outer;
$border--highlighted: 1px solid $blue--bold;
$bulk-actions-column--width: 225px;

$checkbox-column--width: 44px;
$companies-action-column--width: 137px;
$companies-date-column--width: 184px;
$status-column--width: 175px;

$header--height: 40px;

$compact-table-cell-height: 30px;
$load-more-button--height: 44px;
$load-more--number-size-compact: 12px;

$select--min-height: 58px;
$select--control--min-height: $select--min-height - 2px;

.table-v2 {
    border: $border--default;
    border-collapse: collapse;
    border-radius: $border--radius;
    border-spacing: 0;
    display: grid;
    width: 100%;

    &.border-bottom--none {
        @include borderNone(bottom);
        @include borderRadii(0, bottom-left, bottom-right);
    }

    &.border-top--none {
        @include borderNone(top);
        @include borderRadii(0, top-left, top-right);
    }

    // If part of the <Table /> element, this unsets our border for tables styling
    .table-pagination {
        border-right: unset;
        border-bottom: unset;
        border-left: unset;
    }

    thead {
        @include user-select();

        tr {
            @include flexbox();
            border-top-left-radius: $border--radius;
            border-top-right-radius: $border--radius;

            th {
                @include flexbox();
                @include justify-content(space-between);
                @include align-items(center);
                flex: 1;

                background-color: $blue--xx-light;
                border-right: $border--default;
                height: $header--height;

                &:last-child {
                    border-right: transparent;
                }

                > div {
                    padding: $spacing--12;
                }

                .table-header--text {
                    padding-right: $spacing--3;
                }

                &:first-child {
                    border-top-left-radius: $border--radius;
                }

                &:last-child {
                    border-top-right-radius: $border--radius;
                }

                &.focused {
                    background-color: $blue--x-light;
                }

                .right-indicator {
                    @include flexbox();
                    padding-left: $spacing--3;

                    > svg:hover {
                        fill: $blue--bold !important;
                    }
                }
            }
        }
    }

    tbody {
        tr {
            @include flexbox();

            &:not(:last-child) {
                td {
                    border-bottom: none;
                }
            }

            &:last-child {
                td {
                    border-bottom: $border--default;
                }
            }

            &:hover:not(.disable-hover) {
                td {
                    background-color: $table-body-row--background-color--hover;
                }
            }

            td {
                @include flexbox();
                background: $white;
                border: $border--default;
                border-left: transparent;
                flex: 1;
                min-height: $form-height-xl;

                &:last-child {
                    border-right: transparent;
                }

                > div {
                    @include flexbox();
                    @include align-items(center);
                    @include justify-content(space-between);
                    width: 100%;

                    padding: $spacing--12;
                    border: 1px solid $transparent;
                    box-sizing: border-box;

                    &:focus-within {
                        border: $border--highlighted !important;
                    }

                    .input-container {
                        box-sizing: border-box;
                        // -12px so it fits into cell
                        margin: -$spacing--12;
                        padding: $spacing--12;
                    }

                    .empty {
                        background: $blue--x-light;
                        // has to be -13px specifically = cell padding + 1px
                        margin: -#{$spacing--12 + 1px};
                        // 2 x padding + 2 x border (1px)
                        height: calc(100% + #{$spacing--24} + #{2 * 1px});
                        width: calc(100% + #{$spacing--24} + #{2 * 1px});
                    }
                }

                &.input-cell {
                    > div {
                        padding: 0;

                        > div {
                            width: 100%;
                        }

                        .Select,
                        .input-container {
                            border: none !important;
                            max-width: 100%;
                            margin: 0;
                        }

                        // Select styling
                        .Select {
                            min-height: $select--min-height;
                            height: unset;

                            .Select__control {
                                min-height: $select--control--min-height;
                                height: unset;

                                .Select__value-container {
                                    height: unset;
                                    min-height: 24px;
                                    transform: translateY(2px);

                                    .Select__input,
                                    .Select__placeholder,
                                    .Select__single-value {
                                        font-size: $font-xs;
                                    }

                                    .Select__placeholder {
                                        position: relative;
                                    }

                                    .Select__single-value {
                                        position: relative;
                                        overflow: unset;
                                        text-overflow: unset;
                                        white-space: unset;
                                    }

                                    .Select__input {
                                        position: absolute;
                                        transform: unset;
                                    }
                                }
                            }
                        }
                    }
                }

                .left-indicator {
                    @include flexbox();
                    @include align-items(center);
                    padding-right: $spacing--8;
                }

                .right-indicator {
                    @include flexbox();
                    @include align-items(center);
                    padding-left: $spacing--8;
                }

                &.highlighted {
                    > div {
                        border: $border--highlighted;
                    }
                }

                &.warning {
                    background-color: $ylw--light;
                }

                &.error {
                    background-color: $red--light;
                }

                &.disabled {
                    background-color: $blue--x-light;
                    pointer-events: none;

                    svg {
                        pointer-events: all;
                    }
                }
            }

            &.highlighted {
                td {
                    border-top: $border--highlighted;
                    border-bottom: $border--highlighted;

                    &:first-child {
                        border-left: $border--highlighted;
                    }

                    &:last-child {
                        border-right: $border--highlighted;
                    }
                }

                + tr {
                    td {
                        border-top: none;
                    }
                }
            }

            &.error {
                td {
                    background-color: $table-body-row--background-color--error;
                }
            }

            &.warning {
                td {
                    background-color: $table-body-row--background-color--warn;
                }
            }

            &.unread {
                td {
                    background-color: $table-body-row--background-color--unread;
                }
            }

            &:last-child {
                td {
                    border-bottom: none;

                    &:first-child {
                        border-bottom-left-radius: $border--radius;

                        > div {
                            border-bottom-left-radius: $border--radius;
                        }
                    }

                    &:last-child {
                        border-bottom-right-radius: $border--radius;

                        > div {
                            border-bottom-right-radius: $border--radius;
                        }
                    }
                }
            }

            &.disabled {
                td {
                    background-color: $blue--x-light;
                    pointer-events: none;

                    svg {
                        pointer-events: all;
                    }
                }
            }
        }
    }

    &.disabled {
        tbody {
            > div {
                pointer-events: none;
            }

            tr {
                td {
                    background-color: $grey--xx-light;
                }
            }
        }
    }

    &.has-children {
        tbody {
            tr {
                &:last-child {
                    td {
                        border-radius: 0;
                    }
                }
            }
        }
    }

    &.compact {
        thead {
            tr {
                th {
                    height: $compact-table-cell-height;
                }
            }
        }

        tbody {
            tr {
                td {
                    min-height: $compact-table-cell-height;
                    height: $compact-table-cell-height;
                    overflow: hidden;
                    white-space: nowrap;
                    width: 180px;
                }

                .add-line-item--button {
                    padding: $spacing--2;
                    line-height: $line-height--sm;
                }
            }
        }
    }

    // children
    .add-line-item--row {
        td {
            @include flexbox();
            min-height: $form-height-xl;

            > div {
                padding: $spacing--6;
            }
        }

        .add-line-item--button {
            @include justify-content(flex-start);
            font-weight: $bold;
            line-height: $line-height--xm;
            padding: $spacing--12;
            width: 100%;
        }
    }
}

svg.tooltip-icon {
    transition: 0.2s all;

    &:hover {
        fill: $blue--bold !important;
    }
}

.bulk-actions-column {
    width: auto;
    max-width: $bulk-actions-column--width;
}

.checkbox-column {
    max-width: $checkbox-column--width;
    width: $checkbox-column--width;
}

.checkbox-column .table-header--text {
    padding-right: $spacing--12 !important;
}

.companies-action-column {
    min-width: $companies-action-column--width !important;
    width: $companies-action-column--width;
}

.companies-date-column {
    min-width: $companies-date-column--width !important;
    width: $companies-date-column--width;
}

.status-column {
    min-width: $status-column--width !important;
    max-width: $status-column--width;
    width: $status-column--width;
}

th.checkbox-column {
    justify-content: center !important;
}

td.checkbox-column {
    > div {
        justify-content: center !important;

        .overflow--hidden {
            overflow: auto;
        }
    }
}

/* WIDTH CLASSES */
$width-unit: 48px;

@mixin cell-width($size) {
    flex-basis: #{$size * $width-unit} !important;
    flex-grow: $size !important;
    min-width: #{$size * $width-unit};
}

@for $i from 1 through 10 {
    .cell-width--#{$i} {
        @include cell-width($i);

        &.static {
            max-width: #{$i * $width-unit};
        }
    }
}

td {
    // [DEV-1770] @defart : Prevents expansion of parent row when text in ellipses is of huge length
    .table-cell-width-stabilization {
        width: 0;
        min-width: 100%;
    }
    // [DEV-12627] @nivoset: If it contains a tooltip, we need to undo the 0px width so that it has width to render.
    [aria-describedby="trigger"] {
        width: 100%;
    }
}

.table-v2-footer--load-more {
    @include flexbox();
    border-bottom-left-radius: $border--radius;
    border-bottom-right-radius: $border--radius;

    td {
        @include flexbox();
        background: $white;
        border-color: transparent;
        border-top: $border--default;
        border-bottom-left-radius: $border--radius;
        border-bottom-right-radius: $border--radius;
        flex: 1;

        > div {
            width: 100%;
            padding: $spacing--8 !important;
        }
    }
    .load-more--button {
        @include flexbox();
        @include justify-content(center);
        @include align-items(center);

        background-color: $blue--x-light;
        border-radius: $spacing--8;
        border: none;
        color: $blue--bold;
        cursor: pointer;

        font-size: $font--14;
        font-weight: $bold;

        transition: all 0.2s;
        text-align: center;
        height: $load-more-button--height;
        width: 100%;

        .number-chip {
            margin-left: $spacing--8;

            &--compact {
                height: $load-more--number-size-compact;
                width: $load-more--number-size-compact;
            }
        }

        .sk-three-bounce {
            margin-left: 0;
            color: $blue--bold !important;
        }

        > span {
            color: $blue--bold;
        }

        &:hover {
            background-color: $blue--bold;

            > span:not(.load-more--number) {
                color: $white;
            }
        }

        &--compact {
            height: 22px;
            font-size: $font-xs;
        }
    }
}

.cell-disabled {
    cursor: default !important;
    color: $grey--xx-dark !important;
    background-color: $grey--xx-light !important;

    & * {
        cursor: default !important;
        color: $grey--xx-dark !important;
    }

    .missing-avatar {
        color: white !important;
    }
}

.attachments-cell-disabled {
    cursor: default !important;
    background-color: $grey--xx-light !important;
}
