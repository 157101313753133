@import '../../global/css/variables/global.scss';
@import '../../global/css/base/color.scss';
@import '../../global/css/mixins/flexbox.scss';
@import '../../global/css/base/padding.scss';


.sandbox {
  @include flexbox();
  @include align-items(center);

  background: $light-orange;
  border: 1px solid $main-orange;
  position: fixed;
  bottom: 18px;
  left: 50%;
  transform: translateX(-50%);
  padding: $padding--m 16px;
  border-radius: 100px;
  box-shadow: 0 2px 6px $main-silver;
}
