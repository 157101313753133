@import 'css/base/color.scss';
@import 'css/base/margin.scss';
@import 'css/base/padding.scss';
@import 'css/base/typography.scss';
@import 'css/mixins/includeMedia.scss';


.protected-warn {

  .protected-warn--title {
    color: $white;
    text-align: center;
    font-size: 28px;
    font-weight: $regular;
    font-family: $marketing-font-family;
  }

  .generic-card--warn {
    margin: $margin--large-aligner auto 0;
    padding: $padding--x-large $padding--large;
    max-width: calc(100% - #{$padding--large});

    @include media(">=tablet_small") {
      max-width: 426px;
    }
  }

  .protected-route--icon {
    height: 130px;
    width: 200px;
    margin: 0 auto;
  }

  .protected-warn--message {
    font-family: $alt-font-family;
    text-align: center;
    margin-top: $margin--m;
    margin-bottom: $margin--m;
    width: 100%;
    display: block;
  }

  .protected-warn--link {
    color: $primary;
    text-align: center;
    display: inline-block;
    font-size: $font-regular;
    margin-top: $margin--m-large;

    & + span {
      align-self: flex-end;
    }
  }

  .protected-warn--buttons-container {
    margin-top: $margin--large;
    width: 100%;
  }

  .protected-warn--button {
    margin: 0 auto;
    width: 100%;

    @include media(">=tablet_small") {
      max-width: 320px;
    }
  }

  .branded--contents {
    .grid {
      margin: 0 auto;
    }

    @include media(">=tablet_large") {
      .grid {
        max-width: 500px;
      }
    }
  }

  .action-wrapper {
    color: $grey--x-dark;
    padding: $padding--xm $padding--m;
    border-radius: 4px;

    &:hover {
      color: $blue--primary;
      background-color: $blue--x-light;
      text-decoration: none !important;
    }
  }
}

