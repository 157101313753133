@import 'css/base/color.scss';
@import 'css/base/margin.scss';
@import 'css/base/padding.scss';
@import 'css/base/typography.scss';


.mailing-address-disclosure-list {

  .disclosure-body {

    & .bank-account--address-line {
      color: $grey--xx-dark;
      font-size: $font-xs;
      letter-spacing: $letter-spacing--xs;
      line-height: $line-height--m;
    }
  }
}
