@import 'css/base/color.scss';
@import 'css/base/margin.scss';
@import 'css/base/typography.scss';
@import 'css/mixins/includeMedia.scss';


.verify-funding-account-form {

  p {
    letter-spacing: $letter-spacing--xs;
    line-height: $line-height--l;
  }

  .form-control {

    .w-m {

      &:nth-child(even) {
        .verify-input {
          margin-left: $margin--xm;
        }
      }

      &:nth-child(odd) {
        .verify-input {
          margin-right: $margin--xm;
        }
      }

      @include media('<=phone_large') {
        &:nth-child(even),
        &:nth-child(odd){
          .verify-input {
            margin-left: 0;
            margin-right: 0;
          }
        }

        &:not(:last-child) {
          .verify-input {
            margin-bottom: $margin--xm;
          }
        }
      }
    }
  }
}
