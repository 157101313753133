@import 'css/base/color';
@import 'css/mixins/zIndex';

.add-payment-method-dropdown-button {
  &.dd-menu-right {
    .dd-menu-items {
      // We need this in order to avoid issues in segment component
      @include zIndex('BASE');
      // We need to override this in order to make the dropdown menu
      // wide enough to not have multiple lines of texts
      left: unset;
      background-color: $white;
    }
  }
}