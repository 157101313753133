@import 'css/base/padding.scss';
@import 'css/mixins/flexbox.scss';
@import 'css/mixins/includeMedia.scss';


.details-list {
  .column {
    max-width: 100%;
    padding: $padding--m $padding--m 0;
  }

  .column:first-child {
    padding-top: 0;
  }

  &.column-left-padding--remove {
    // the rendering on the Company page and on the Settings / biz rep / verification pages
    // are not exactly the same. We need this until we update the component everywhere
    .column {
      padding-left: 0;
    }
  }

  @include media(">=tablet") {
    &.tablet--halves {
      .column:nth-child(2) {
        padding-top: 0;
      }
    }
  }
}
