/*
 * @fileOverview Grid, based on SilverCSS
 * https://zeph.co/grid
 */

@import '../base/padding.scss';
@import '../mixins/flexbox.scss';
@import '../mixins/includeMedia.scss';


$prefixes: (
  'all_media',
  'phone',
  'phone_large',
  'tablet_small',
  'tablet',
  'tablet_large',
  'laptop',
  'laptop_large',
  'monitor',
);

@mixin columnSizes($prefix: null) {
  $classPrefix: if($prefix, '#{$prefix}--', '');
  $important: if($prefix, !important, null);

  &.#{$classPrefix}stack-all {
    .column {
      @include flex(0 0 100%);
    }
  }

  &.#{$classPrefix}halves {
    .column {
      @include flex(0 0 50% $important);
    }
  }

  &.#{$classPrefix}thirds {
    .column  {
      @include flex(0 0 calc(100% / 3) $important);
    }
  }

  &.#{$classPrefix}fourths {
    .column {
      @include flex(0 0 25% $important);
    }
  }

  &.#{$classPrefix}fifths {
    .column {
      @include flex(0 0 20% $important);
    }
  }

  &.#{$classPrefix}sixths {
    .column {
      @include flex(0 0 calc(100% / 6) $important);
    }
  }

  &.#{$classPrefix}eighths {
    .column {
      @include flex(0 0 12.5% $important);
    }
  }

  &.#{$classPrefix}tenths {
    .column {
      @include flex(0 0 10% $important);
    }
  }
}

@mixin namespacedCols($prefix) {
  @include media(">=#{$prefix}") {
    @include columnSizes($prefix);
  }
}

@mixin namespacedUtilities($prefix) {
  $classPrefix: '#{$prefix}--';

  @include media(">=#{$prefix}") {
    @include namespacedCols($prefix);

    /* flex-wrap */
    &.#{$classPrefix}flex-wrap--wrap {
      @include flex-wrap(wrap);

      .column {
        @include flex(0 0 100%);
      }

      @include columnSizes();
    }

    &.#{$classPrefix}flex-wrap--no-wrap {
      @include flex-wrap(nowrap);

      .column {
        @include flex(1);

        &.bigger {
          @include flex(2.5);
        }
      }

      // when applying "bigger" class to a column and using a multiple of 3 columns,
      // the aesthetic makes more sense if "bigger" is 2/3 (or 2/6) of the container
      &.#{$classPrefix}thirds,
      &.#{$classPrefix}sixths {

        .column {

          &.bigger {
            @include flex(2);
          }
        }
      }

      @include columnSizes();


      &:has(.tablematic-table) {
        height: 100%;
      }
    }

    &.#{$classPrefix}flex-wrap--wrap-reverse {
      @include flex-wrap(wrap-reverse);
    }

    /* flex-direction */
    &.#{$classPrefix}flex-direction--column {
      @include flex-direction(column);
    }

    &.#{$classPrefix}flex-direction--row {
      @include flex-direction(row);
    }

    /* justify-content */
    &.#{$classPrefix}justify-content--flex-start {
      @include justify-content(flex-start);
    }

    &.#{$classPrefix}justify-content--flex-end {
      @include justify-content(flex-end);
    }

    &.#{$classPrefix}justify-content--center {
      @include justify-content(center);
    }

    &.#{$classPrefix}justify-content--space-between {
      @include justify-content(space-between);
    }

    &.#{$classPrefix}justify-content--space-around {
      @include justify-content(space-around);
    }

    &.#{$classPrefix}justify-content--space-evenly {
      @include justify-content(space-evenly);
    }

    /* align-items */
    &.#{$classPrefix}align-items--baseline {
      @include align-items(baseline);
    }

    &.#{$classPrefix}align-items--center {
      @include align-items(center);
    }

    &.#{$classPrefix}align-items--flex-start {
      @include align-items(flex-start);
    }

    &.#{$classPrefix}align-items--flex-end {
      @include align-items(flex-end);
    }

    &.#{$classPrefix}align-items--stretch {
      @include align-items(stretch);
    }

    /* align-content */
    &.#{$classPrefix}align-content--flex-start {
      @include align-content(flex-start);
    }

    &.#{$classPrefix}align-content--flex-end {
      @include align-content(flex-end);
    }

    &.#{$classPrefix}align-content--center {
      @include align-content(center);
    }

    &.#{$classPrefix}align-content--space-between {
      @include align-content(space-between);
    }

    &.#{$classPrefix}align-content--space-around {
      @include align-content(space-around);
    }

    &.#{$classPrefix}align-content--stretch {
      @include align-content(stretch);
    }

    &.#{$classPrefix}stack--true:not(.#{$classPrefix}flex-wrap--no-wrap) {
      .column {
        @include flex(0 0 100%);
      }
    }
  }
}

.grid {
  @include flexbox();
  @include flex-wrap(wrap);

  .column {
    &.spaced--xs {
      padding: $padding--xs;
    }

    &.spaced--sm {
      padding: $padding--sm;
    }

    &.spaced--xm {
      padding: $padding--xm;
    }

    &.spaced--m {
      padding: $padding--m;
    }

    &.spaced--l {
      padding: $padding--large;
    }

    &.spaced--xl {
      padding: $padding--x-large;
    }
  }

  &.col {
    @include flex-direction(column);
  }

  @include columnSizes();

  @each $prefix in $prefixes {
    @include namespacedUtilities($prefix);
  }
}
