@import 'css/base/color.scss';
@import 'css/base/margin.scss';
@import 'css/base/padding.scss';
@import 'css/base/typography.scss';
@import 'css/mixins/flexbox.scss';
@import 'css/mixins/mediaQuery.scss';
@import 'css/mixins/text.scss';
@import 'css/variables/global.scss';


.guide-wrapper {
  height: calc(100vh - #{$nav--height});
  padding: 48px 6%;
  padding-bottom: 100px;
  overflow-y: scroll;

  > h3 {
    color: $grey--xxx-dark;
    font-size: $font-x;
    font-weight: $regular;
    margin-bottom: $margin--sm;
    margin-top: $margin--sm;
  }

  .subTitle {
    font-size: 1rem;
  }

  @include media($SCREEN:(TABLET_LARGE)) {
    padding: 50px 8%;
    padding-bottom: 100px;
  }

  @include media($SCREEN:(LAPTOP)) {
    padding: 50px 10%;
    padding-bottom: 100px;
  }

  @include media($SCREEN:(LAPTOP_LARGE)) {
    padding: 50px 14%;
    padding-bottom: 100px;
  }
}

.guide-section-list {
  width: 100%;
  margin-top: $margin--large;

  border: $border--blue-light;
  border-radius: 6px;
  box-shadow: 0 2px 4px 0 $blue--x-light;

  &.highlighted {
    .guide-section-list--header {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  .guide-section-list--header {
    @include flexbox();
    @include align-items(center);
    @include flex-flow(row);
    width: 100%;
    padding: 16px 20px;
    cursor: pointer;
    @include user-select();

    background-color: $white;
    border-radius: 6px;
    border-bottom: $border--blue-light;
    box-shadow: 0 1px 0 0 $blue--x-light;

    @include media($SCREEN:(_SMALL)) {
      padding: 20px;

      > h3 {
        font-size: 15px;
      }
    }

    .header-icon {
      margin-right: $margin--m;
    }

    .chevron-div {
      margin-left: auto;
      @include flexbox();
      @include justify-content(center);
      @include align-items(center);
    }
  }

  .guide-section-list--body {
    padding: $padding--m;

    @include media($SCREEN:(_SMALL)) {
      padding: $padding--large;
    }
  }
}

.guide-support-link {
  @include flexbox();
  @include align-items(center);
  @include flex-flow(row)
}
