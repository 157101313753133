@import 'css/base/color.scss';
@import 'css/base/margin.scss';
@import 'css/base/typography.scss';


.heading-title {
   font-weight: bold;
   font-size: $font-regular;
   color: $grey--x-dark;
   display: inline-block;

   &:not(.has-description) {
      margin-bottom: 0 !important;
   }

   &.has-description {
      margin-bottom: $margin--sm;
   }

   &.font-xs {
      font-size: $font-xs;
   }
}
