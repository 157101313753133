@import 'css/base/color.scss';
@import 'css/base/padding.scss';
@import 'css/base/spacing.scss';
@import 'css/base/typography.scss';
@import 'css/mixins/flexbox.scss';
@import 'css/variables/global.scss';


.dashboard-sidebar-strip {
  @include flexbox();
  @include align-items(center);
  @include justify-content(space-between);

  order: 8;
  width: 100%;
  background-color: $blue--bold;
  position: relative;

  /*
    This is for new tooltip on sidebar, content squished,
    and i could not identify the random span with no class to target to make 100% width
  */
  & > span {
    width: 100%
  }

  .dashboard-sidebar-strip-overlay {
    background-color: transparent;

    &:hover {
      background-color: rgba(13, 25, 67, 0.15);
      transition: background-color 0.2s;
    }
  }
}

.toggle-button {
  padding: 7px;
  color: white;
  border-radius: 4px;

  &:hover {
    background-color: rgba(0, 0, 0, 0.15) !important;
  }
}
