@import 'css/base/block.scss';
@import 'css/base/color.scss';
@import 'css/base/margin.scss';
@import 'css/base/sizes.scss';
@import 'css/mixins/transitions.scss';


@mixin typedButtonStyles($btnClass) {
  .#{$btnClass} {
    /* nonstandard padding to match the arrow icon padding */
    padding-left: 16px;

    .#{$btnClass}--content {

      &.has-icon {
        margin-left: $margin--xm;
      }
    }
  }
}

.help-docs-link-btn {
  .btn {
    transition: color 0.2s;
  }

  &:hover {
    text-decoration: none;

    .btn {
      color: $primary;
    }
  }
}

@include typedButtonStyles(btn--contrast);

@include typedButtonStyles(btn--primary);

@include typedButtonStyles(btn--ghost);

.close-btn {
  @include transitionBgColor();
  border-radius: $block-border-radius--sm;

  &:hover {
    background-color: $blue--x-light;
  }
}
