@import 'css/base/color.scss';
@import 'css/base/sizes.scss';


textarea:-webkit-autofill {
  -webkit-text-fill-color: $grey--x-dark;
  -webkit-box-shadow:0 0 0 50px $white inset;
}

.autosized-container {
  min-height: $input-container-height;
  height: unset;

  textarea {
    &:placeholder-shown {
      // important to override styles by react-autosize-textarea
      height: $spacing--24 !important;
    }

    &:not(:placeholder-shown) {
      + .placeholder {
        display: none;
      }
    }
  }

  .placeholder {
    top: 17px !important;
  }
}

// Mozilla only
@-moz-document url-prefix() {
  .input-container textarea {
    // Workaround for Firefox bug https://bugzilla.mozilla.org/show_bug.cgi?id=33654
    // Summary: TEXTAREA incorrectly applying ROWS= and COLS=
    overflow-x: auto;
  }
}
