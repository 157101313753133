@import 'css/base/color.scss';
@import 'css/base/padding.scss';
@import 'css/base/margin.scss';
@import 'css/base/sizes.scss';
@import 'css/base/typography.scss';
@import 'css/mixins/flexbox.scss';
@import 'css/mixins/includeMedia.scss';
@import 'css/variables/global.scss';
@import 'css/variables/sizes.scss';


.toolbar {
  @include flexbox();
  @include justify-content(space-between);
  width: 100%;
  max-width: 100%;
  height: $nav--height;
  background-color: $white;
  border-bottom: 1px solid $blue--x-light;

  .toolbar--left {
    @include flexbox();
    @include justify-content(flex-start);
    height: $nav--height;
    line-height: $nav--height;
  }

  .toolbar--right {
    @include flexbox();
    @include justify-content(flex-end);
    height: $nav--height;
    line-height: $nav--height;

    .toolbar--button {
      margin-left: $margin--xm;
    }

  }

  .toolbar--control {
    cursor: pointer;
  }

  .toolbar--header {
    color: $dark-jordan;
    font-size: $font-regular;
    font-weight: $bold;
  }

  .toolbar--subheader {
    color: $dark-jordan;
    font-size: $font-xxs;
  }

  .toolbar--dropdown-option {
    line-height: normal;
    user-select: none;

    .link--title {
      font-weight: $bold;
    }

    .toolbar--nav-menu-option-selected {
      @include flex-col-center();
      color: $blue--primary;
      margin-left: $margin--large;
    }
  }

  .toolbar--dropdown-option {

    .link--title {
      font-weight: $bold;
      line-height: normal;
    }

    .link--description {
      line-height: normal;
    }
  }

  .toolbar--button-wrapper,
  .toolbar--ledger-button-wrapper {
    @include flex-col-center();
    height: $nav--height;
  }

  .toolbar--button {
    @include flexbox();
    padding: $padding--xm;
    color: $grey--xx-dark;
    font-weight: $semibold;
    font-size: $font-regular;
    border-radius: 4px;
    line-height: 21px;
    letter-spacing: $letter-spacing--xs;
    transition: background-color 0.2s;
    user-select: none;
    position: relative;
    height: $toolbar-button-size;

    .toolbar--button-icon {
      margin-right: $margin--xm;
    }

    .toolbar--button-icon {
      min-width: $spacing--14;
      align-self: center;
    }

    &:hover {
      background-color: $blue--x-light;

      &.primary {
        color: $blue--primary;

        .toolbar--button-icon {
          fill: $blue--primary !important;
        }
      }

      &.danger {
        background-color: $red--light;
        color: $red--bold;

        .toolbar--button-icon {
          fill: $red--bold !important;
        }
      }
    }
  }

  .toolbar--button {
    font-weight: $bold;
  }

  .toolbar--ledger-button-wrapper {
    .refresh-button {
      &.fetching {
        .refresh-button--icon {
          animation: fetchingSpin 1s ease-in-out;
        }
      }
    }
  }

  .toolbar--divider {
    height: $nav--height;
    padding: $padding--m $padding--xm*2;

    .toolbar--divider-inner {
      width: 1px;
      height: 100%;
      background-color: var(--color-grey20);
    }
  }

  .action-nav {
    box-shadow: none;
  }
}

.toolbar--nav {
  list-style: none;
  cursor: pointer;
  user-select: none;

  &::after {
    content: '';
    display: block;
    margin-top: -4px;
    border-bottom: $nav-border-height solid $blue--primary;
  }

  .toolbar--toggle-text {
    @include flex-col-center();
    font-weight: $bold;
    color: $grey--x-dark;
    width: 300px;
    height: $nav--height;
    line-height: normal;

    .toolbar--toggle-text-inner {
      display: inline-flex;
      @include justify-content(space-between);
    }
  }

  .toolbar--toggle-icon {
    color: $main-jordan;
    vertical-align: text-top;
  }
}

.toolbar--nav-menu-option {
  @include flexbox();
  @include justify-content(space-between);
  height: $nav--height;
  padding: 0;
  user-select: none;
  width: 380px;

  .toolbar--nav-option-text {
    @include flex-col-center();
  }

  &.selected {

    .toolbar--nav-option-text {

      span:first-child {
        color: $blue--primary;
      }
    }
  }

  .toolbar--nav-menu-option-selected {
    @include flex-col-center();
    min-height: 100%;
    margin-left: $margin--large;
    color: $blue--primary;
  }
}

@include media("<=tablet_large") {
  .toolbar {
    .toolbar--left {
      width: 65%;
    }

    .toolbar--right {
      width: 35%;
    }

    .toolbar--button {
      .toolbar--button-contents {
        display: none;
      }
    }

    .toolbar--ledger-status {
      display: none;
    }
  }

  .toolbar--nav {
    max-width: 100%;

    .toolbar--toggle-text {
      max-width: 100%;
      width: 240px;
    }
  }
}

@include media("<=tablet_small") {
  .toolbar {

    .toolbar--left {
      width: 89%;
      padding-right: $spacing--24;
    }

    .toolbar--right {
      width: 11%;
    }

    .ledger-status-divider,
    .toolbar--ledger-button-wrapper {
      display: none;
    }
  }
}

@include media("<=phone_large") {
  .toolbar {
    @include justify-content(space-between);

    .toolbar--right {
      .toolbar--button {
        margin-left: 0;
      }
    }

    .toolbar--ledger-button-wrapper {
      display: none;
    }
  }

  .toolbar--nav {
    margin-right: $margin--m;

    .toolbar--toggle-text {
      width: 160px !important;
    }

    .toolbar--toggle-icon {
      align-self: flex-end;
    }
  }

  .toolbar--nav-menu-option {
    width: 300px;
  }
}

@include media("<=phone") {
  .toolbar {
    @include justify-content(flex-start);
  }
}

@keyframes fetchingSpin {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(359deg);
  }
}
