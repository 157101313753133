@import '../../global/css/base/color.scss';

.logo-container {
  img {
    width: 112px;
  }

  &:hover {
    opacity: 0.7;
    text-decoration: none;
  }
}
