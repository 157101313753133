@import 'css/base/block.scss';
@import 'css/base/block.scss';
@import 'css/base/color.scss';
@import 'css/base/margin.scss';
@import 'css/base/padding.scss';
@import 'css/base/spacing.scss';
@import 'css/base/typography.scss';
@import 'css/mixins/flexbox.scss';
@import 'css/mixins/includeMedia.scss';


$bulk-actions-table--segment--body--width: 968px;
$bulk-import-table--segment--body--width: 1078px;

$segment-header--height__medium: 73px;

.segment {
  background: $white;
  border: $content-block-border;
  border-radius: $block-border-radius--m;
  box-sizing: border-box;
  width: 100%;
  &.large {
    > .segment--header,
    > .segment--body {
      padding: $padding--large;
    }
  }

  .segment--body--no-padding {
    padding: 0 !important;
  }

  .segment--header {
    @include flex-direction(column);
    padding: $padding--m-large;

    &.has-children {
      border-bottom: 1px solid var(--color-grey20);
    }

    &.hide-border {
      padding-bottom: 0;
      border-bottom: none;
    }

    .segment--title {
      font-weight: $semibold;
      color: $blue--dark;
      line-height: $line-height--22;
      font-size: $font-m;
      display: flex;
      align-items: center;
    }

    .segment--subtitle {
      font-weight: $regular;
      color: $grey--x-dark;
      line-height: $line-height--18;
      font-size: $font-regular;
    }

    .segment--header-buttons {
      button.btn-v2.btn--small {
        height: 32px;
      }

      button:not(:last-child) {
        margin-right: $margin--m;
      }
    }

    .chevron-icon {
      margin-top: $spacing--3;
      transition: transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;

      &.is-open {
        -webkit-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
        transform: rotate(90deg)
      }
    }
  }

  &.bulk-actions-table--segment {
    .segment--subtitle {
      max-width: #{$bulk-actions-table--segment--body--width - $spacing--24};
    }
  }

  .segment--body {
    border-bottom-right-radius: $spacing--8;
    border-bottom-left-radius: $spacing--8;
    padding: $padding--m-large;
    position: relative;
    width: 100%;

    &.bulk-import-table--segment--body {
      max-width: $bulk-import-table--segment--body--width;
      padding: $offset-scroll-segment-padding;
    }

    &.bulk-import-table--segment--table {
      overflow: hidden;
      padding: 0px;

      .tabs>div[role=tablist] {
        padding-left: 24px;
      }
    }

    &.has-footer {
      padding-bottom: 0;
    }
  }
}

.segment--footer {
  background: $blue--xx-light;
  border-bottom-left-radius: $block-border-radius--m;
  border-bottom-right-radius: $block-border-radius--m;
  border-top: 1px solid var(--color-grey20);
  padding-top: $spacing--16;
  padding-bottom: $spacing--16;
  padding-left: $spacing--16;

  .checkbox--text-wrapper {
    > .base-text {
      color: $grey--xx-dark !important;
      font-size: $font-sm !important;
    }
    > svg {
      fill: $grey--x-dark !important;
    }
  }
}

.segment--error {
  border: $content-block-border-error;
}

.segment--footer-error {
  background: $red--light;
  border-top: 1px solid $red--bold;
  position: relative;

  &--text {
    position: absolute;
    right: $spacing--16;
    top: 50%;
    transform: translateY(-50%);
  }
}

.segment--label {
  border-radius: $block-border-radius--large;
  display: inline-block;
  height: 28px;
  padding: $spacing--6 $spacing--8;
  pointer-events: none;
  p {
    font-size: $font-xs;
    font-weight: $bold;
    margin-bottom: $margin--xm;
  }
}
