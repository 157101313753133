@import "css/base/block.scss";
@import "css/base/color.scss";
@import "css/base/margin.scss";
@import "css/base/padding.scss";
@import "css/base/spacing.scss";
@import "css/base/transition.scss";
@import "css/base/typography.scss";
@import "css/mixins/flexbox";
@import "css/mixins/text";


$payment-method--detail-item-value-flag-height: 16px;
$payment-method--manage-action-button-pading: 9px;

.payment_method {
  background: $white;
  border: 1px solid var(--color-grey20);
  border-radius: $block-border-radius--sm;
  position: relative;
  // Hide overflowing PaymentMethodStatus background in corners of card
  overflow: hidden;

  &__header-container {
    margin-left: $margin--m-large;
    margin-right: $margin--m-large;
    // relative to allow carret to be positioned abosolute
    position: relative;

    .payment_method--manage-style & {
      @include flexbox();
      @include flex-direction(row);
      @include align-items(center);

      &__radio {
        @include align-self(stretch);

        border-right: 1px solid var(--color-grey20);
        margin-right: $margin--m-large;
        padding-right: $padding--m-large;
      }

      &__content {
        @include flexbox();
        @include flex(1);
        @include justify-content(space-between);
        @include flex-direction(row);
        @include align-items(center);

        cursor: pointer;

        margin-top: $margin--m;
        margin-bottom: $margin--m;
      }
    }

    .payment_method--view-style & {
      margin-top: $margin--m-large;
      margin-bottom: $margin--m;
    }
  }

  &__title-subtitle-wrapper {
    // Allow title & subtitle to truncate
    @include truncate();
  }

  &__funding-icon {
    color: $grey--xx-dark;
    margin-right: $margin--m-large;

    .payment_method__titles-icon-container--disabled &, .payment_method--input-disabled & {
      color: $grey--dark;
    }

    .payment_method--view-style & {
      margin-bottom: $margin--m;
    }
  }

  &__title {
    @include truncate();

    color: $black;
    line-height: $line-height--22;
    font-size: $font--14;
    font-weight: $semibold;

    .payment_method__titles-icon-container--disabled &, .payment_method--input-disabled & {
      color: $grey--x-dark;
    }
  }

  &__subtitle-wrapper {
    height: $line-height--18;
  }

  &__subtitle {
    @include truncate();

    position: absolute;
    width: 50%;
    color: $grey--xx-dark;
    line-height: $line-height--18;
    font-size: $font--12;
    font-weight: $regular;

    .payment_method__titles-icon-container--disabled &, .payment_method--input-disabled & {
      color: $grey--dark;
    }
  }

  &__titles-icon-container {
    @include flexbox();
    @include truncate();

    .payment_method--view-style & {
      @include flex-direction(column);
    }

    .payment_method--manage-style & {
      @include align-items(center);
      @include flex-direction(row);
      // flex-grow pushes PaymentMethodStatus and carret to the right
      @include flex-grow(1);
    }
  }

  &__chevron {
    // Push PaymentMethodStatus to the left
    margin-left: $margin--xm;

    .payment_method--view-style & {
      position: absolute;
      top: 0;
      right: 0;
    }
  }

  &__status {
    &-container {
      @include flexbox();
      @include flex-direction(row);
      @include align-items(center);

      padding: $padding--xm $padding--m-large;
      // prevent shrinking when inside flexbox container
      white-space: nowrap;

      .payment_method--view-style & {
        @include justify-content(flex-end);
      }

      .payment_method--manage-style & {
        border-radius: $block-border-radius--sm;
        padding: $padding--xm $padding--m;
      }

      .payment_method__titles-icon-container--disabled &, .payment_method--input-disabled & {
        opacity: 50%;
      }

      &--active {
        color: $black;
        background: $white;
      }

      &--deleted {
        color: $grey--dark;
        background: $grey--xx-light;
      }

      &--disabled {
        color: $grey--dark;
        background: $grey--xx-light;
      }

      &--error {
        color: $red--dark;
        background: $red--light;
      }

      &--primary {
        color: $green--dark;
        background: $green--light;
      }

      &--warning {
        color: $yellow--dark;
        background: $yellow--light;
      }
    }

    &-label {
      margin-left: $margin--xm;
      font-size: $font--12;
      font-weight: $semibold;
      line-height: $line-height--18;

      .payment_method--manage-style & {
        line-height: $line-height--17;
        margin-left: $spacing--6;
      }
    }
  }

  &__expandable {
    max-height: 0;
    overflow: hidden;
    transition: max-height $transition-accordion-timing ease-in-out;

    &.expanded {
      max-height: 5000px;
    }
  }

  &__detail-item {
    &-container {
      @include flexbox();
      @include flex-direction(row);
      @include align-items(center);
      border-top: 1px solid var(--color-grey20);
      padding: $padding--m $padding--m-large;
      // To allow actions be absolute
      position: relative;
    }

    &-label-value-container {
      // Wrap label and value text nodes and share the space with the action icons
      @include flex(1);
    }

    &-label {
      color: $grey--xx-dark;
      font-size: $font--12;
      line-height: $line-height--16;
    }

    &-value {
      color: $black;
      font-size: $font--14;
      line-height: $line-height--20;

      &-flag {
        height: $payment-method--detail-item-value-flag-height;
        margin-right: $margin--xm;
      }
    }

    &-actions {
      @include flexbox();
      @include flex-direction(row);
      @include align-items(center);

      height: 100%;
    }

    &-action-icon {
      margin-left: $margin--m;
      cursor: pointer;

      svg:hover {
        fill: $grey--x-dark !important;
      }

      &:first-child {
        margin-left: $margin--xm;
      }
    }
  }

  &__manage-actions-container {
    @include flexbox();
    @include flex-direction(row);
    @include align-items(center);
    @include justify-content(flex-end);

    border-top: 1px solid var(--color-grey20);
    padding: $padding--m $padding--m-large $padding--m-large;
  }

  &__manage-action-button {
    &:not(:first-child) {
      margin-left: $margin--m-large;
    }

    &.btn-v2 {
      padding: $payment-method--manage-action-button-pading;
    }
  }
}
