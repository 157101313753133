@import 'css/base/margin.scss';
@import 'css/base/padding.scss';
@import 'css/base/gradient.scss';
@import 'css/base/typography.scss';
@import 'css/mixins/colors.scss';
@import 'css/mixins/flexbox.scss';
@import 'css/mixins/includeMedia.scss';
@import 'css/mixins/scrollbar.scss';


$stepper--height: 76px;
$stepper--height-mobile: 48px;
$stepper-fader--width: 80px;


.stepper--wrapper {
  @include flexbox();
  @include hideScrollbar();

  &:not(.stepper--wrapper__vertical) {
    @include justify-content(space-between);
    @include align-items(center);
    height: $stepper--height;
    width: 100%;

    @include media($mobileOnly) {
      height: $stepper--height-mobile;
    }
  }

  background-color: $white;

  overflow-x: scroll;

  .stepper--side {
    padding: 0 $padding--large;

    @include media($mobileOnly) {
      display: none;
    }
  }

  .stepper--main {
    @include flexbox();

    @include media($mobileOnly) {
      padding-left: $padding--sm;
    }

    .stepper--step {
      @include flexbox();
      margin-right: $margin--m-large;

      @include media($mobileOnly) {
        margin-right: $margin--xm;
      }

      .stepper--button {
        white-space: nowrap;
        transition: all 0s;
        cursor: default;
        color: $grey--dark;
      }
    }
  }

  &__vertical {
    .stepper--main {
      @include flex-direction(column);
      padding: $spacing--16;

      &:not(:last-child) {
        margin-bottom: $spacing--16;
      }

      .stepper--step {
        @include flex-direction(column);

        .stepper--button {
          font-size: $font-sm;
          line-height: $line-height--xl;
          pointer-events: none;

          &:disabled {
            opacity: 1;

            svg {
              fill: currentColor !important;
            }
          }
        }

        &--substeps {
          @include flexbox();
          @include flex-direction(column);

          padding-left: $spacing--12;

          .stepper--button {
            font-weight: $semibold;
          }
        }
      }
    }
  }
}

.stepper--fader--wrapper {
  position: relative;

  .stepper--fader {
    position: absolute;
    height: 100%;
    width: $stepper-fader--width;
    top: 0;
    right: 0;
    background: $transparent-to-white;
  }
}
