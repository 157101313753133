@import 'css/base/block.scss';
@import 'css/base/color.scss';
@import 'css/base/margin.scss';
@import 'css/base/padding.scss';
@import 'css/base/spacing.scss';
@import 'css/base/transition.scss';
@import 'css/base/typography.scss';
@import 'css/mixins/flexbox.scss';
@import 'css/mixins/includeMedia.scss';
@import 'css/variables/global.scss';


$disclosure-header-height-thin: $margin--larger;

$disclosure-list-transition-delay: 0.2s;
$disclosure-body-transition-in: max-height $transition-accordion-timing ease-out, padding-top $transition-accordion-timing, padding-bottom $transition-accordion-timing;
$disclosure-body-transition-out: max-height $transition-accordion-timing ease-in, padding-top $transition-accordion-timing, padding-bottom $transition-accordion-timing;
$disclosure-body-transition-delay: 0s, $disclosure-list-transition-delay, $disclosure-list-transition-delay;
$control-transition: transform $transition-rotate-90-timing ease-in-out;
$disclosure-list-transition: padding $transition-accordion-timing ease-in-out;

/* ==================== */
/* disclosure list base */
/* ==================== */

.disclosure-list {
  box-shadow: none;
  padding: 0;

  &.disclosure-list-transitions {
    transition: $disclosure-list-transition;
    transition-delay: $disclosure-list-transition-delay;
  }

  > .disclosure-header {
    border-top-right-radius: $block-border-radius--xm;
    border-top-left-radius: $block-border-radius--xm;

    p,
    svg,
    .heading-title {
      transition: color 0.1s;
    }

    svg {
      @include align-self(center);
      color: $main-jordan;
    }

    .heading-title {
      color: $grey--x-dark;
    }

    > button {
      @include flexbox();
      width: 100%;
      height: 100%;
      cursor: pointer;
      padding: $padding--m;

      > .heading-title {
        color: $grey--x-dark;
      }

      > .disclosure-control {
        vertical-align: middle;
        color: $main-jordan;
        transition: $control-transition;
        margin-left: $margin--sm;
        position: relative;
        top: 1px
      }
    }

    &:hover {
      p,
      .disclosure-control,
      .heading-title {
        color: $primary;
      }
    }
  }

  > .disclosure-body {
    max-height: 0;
    overflow: hidden;

    &.disclosure-body-transitions {
      transition: $disclosure-body-transition-in;
    }

    > .column {
      max-width: 100%;
    }

    &.entity-widget.view-bank-account--widget {
      > button {
        padding: $padding--m;
      }
    }
  }

  &.inset-body {

    > .disclosure-body {
      padding: 0;

      &.disclosure-body-transitions {
        transition: max-height $transition-accordion-timing, padding 0s;
        transition-delay: 0s, $transition-accordion-timing;
      }
    }
  }

  &.open {
    padding: 0 0 $padding--xm;

    &.disclosure-list-transitions {
      transition: none;
    }

    > .disclosure-body {
      max-height: initial;

      &.disclosure-body-transitions {
        max-height: 1000px;
        transition: $disclosure-body-transition-out;
      }
    }

    > .disclosure-header {

      > button {

        > .disclosure-control {
          transform: rotate(-180deg);
        }
      }
    }

    &.inset-body {

      > .disclosure-body {
        padding: $padding--m-large;

        &.disclosure-body-transitions {
          transition: max-height $transition-accordion-timing, padding 0s;
          transition-delay: 0s, 0s;
        }
      }
    }
  }

  &.no-header {
    padding-top: $padding--m;
  }
}

/* ==================== */
/* heading block disclosure list */
/* ==================== */

.heading-block-disclosure {
  background-color: $white;
  border-radius: $block-border-radius--xm;
  border: $border--blue-light;
  overflow: hidden;

  .control input:checked ~ .control-indicator.primary {
    background-color: $aqua--bold;
    border: $border--aqua-bold;
  }

  .disclosure-body {
    border-radius: 0 0 $block-border-radius--xm $block-border-radius--xm;
    padding: 0 $padding--m;

    &.address-settings {
      margin-top: $margin--m;
    }

    .item-status-box--container {
      display: inline-flex;
      margin-left: $margin--sm;
      margin-right: $margin--sm;
      box-shadow: none;

      .status {
        color: $grey--x-dark;
      }
    }
  }

  &.thin-heading {
    .disclosure-header {
      height: $disclosure-header-height-thin;

      > button {
        padding: $padding--xs $padding--m !important;
      }
    }
  }

  &.open {
    overflow: visible;
    padding-bottom: 0;

    .disclosure-body {
      border-top: $border--inset-content;
      padding: $padding--m $padding--m $padding--form-control-l;
      overflow: visible;
    }
  }
}
