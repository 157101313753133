@import 'css/base/block.scss';
@import 'css/base/color.scss';
@import 'css/base/margin.scss';
@import 'css/base/padding.scss';
@import 'css/base/typography.scss';
@import 'css/mixins/alignment.scss';
@import 'css/mixins/flexbox.scss';
@import 'css/mixins/includeMedia.scss';
@import 'css/mixins/borders.scss';
@import 'css/mixins/scrollbar.scss';
@import 'css/variables/buttons.scss';
@import 'css/variables/global.scss';
@import 'css/variables/iconButton.scss';
@import 'css/variables/modal.scss';
@import 'css/variables/width.scss';

$border-radius--modal-footer: 5px;

.ReactModalPortal > div {
  opacity: 0;
}

.ReactModalPortal .ReactModal__Overlay {
  position: absolute;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 300ms ease-in-out;

  &--after-open {
    opacity: 1;
  }

  &--before-close {
    opacity: 0;
  }

  @include media('<=tablet_small') {
    overflow-y: auto;
    display: block;

    .ReactModal__Content {
      width: 100%;
      border-radius: 0 !important;
    }
  }

  &.FullPageModal__Standalone {
    transition: none;
  }
}

.ReactModal__Content--after-open {
  position: static !important; // override inline style from react-modal
  width: $modal-width;
  padding: 0 !important;
  border: 4px solid var(--color-grey20);
  border-radius: $modal-border-radius !important;
  max-height: 100vh;

  .modal-header {
    position: static;
    top: 0;
    display: table;
    width: 100%;
    padding: $modal-padding--vertical $modal-padding--horizontal-small;
    vertical-align: middle;
    border-radius: $modal-border-radius $modal-border-radius 0 0;
    z-index: 1;

    background-color: $white;
    border-bottom: 1px solid var(--color-grey20);

    h3 {
      display: table-cell;
      vertical-align: middle;

      color: $grey--x-dark;
    }

    a {
      display: table-cell;

      color: $dark-jordan;
      font-size: 8.94px;
      background-color: $blue--x-light;

      &.modal-header--close {
        width: 32px;
        height: 32px;
        vertical-align: middle;
        text-align: center;

        border-radius: 24px;

        span {
          position: relative;
          top: 1px;

          font-weight: $bold;
        }
      }

      &:hover {
        background-color: var(--color-grey20);
      }
    }

    @include media('<=tablet_small') {
      border-radius: 0;
      width: 100%;
    }
  }

  .modal-body {
    padding: 28px 20px;
    background-color: $blue--x-light;

    @include iOSSmoothScrolling();

    h3 {
      font-weight: $bold;
    }

    &.has-footer {
      padding: 0;

      .modal-body--content {
        padding: 28px 20px;

        &--white {
          background-color: $white;
        }

        &--blue--x-light {
          background-color: $blue--x-light;
        }
      }
    }
  }

  .modal-footer {
    position: sticky;
    padding: $modal-padding--vertical $modal-padding--horizontal-small;
    width: 100%;
    border-radius: 0 0 $modal-border-radius $modal-border-radius;
    background-color: $blue--x-light;
    border-top: $content-block-border;
    bottom: 0;

    .modal-footer--button {
      display: inline-block;

      @include media('<=phone_large') {
        &:first-child button {
          width: 100%;
          margin: 0;
        }

        &:not(:only-child) {
          margin-bottom: $margin--m;
        }
      }
    }

    @include media('<=tablet_small') {
      position: static;
      width: 100%;
    }

    @include media('<=phone_large') {
      @include flex-direction(column);
      border-radius: 0;
      margin-bottom: $modal-footer-height;
    }
  }
}

.FullPageModal__Content {
  opacity: 0;

  &--no-header {
    .modal-body {
      height: 100vh !important;
    }
  }
}

.FullPageModal__Content--before-close {
  opacity: 0;
}

.FullPageModal__Content--after-open {
  width: 100vw;
  height: 100vh;
  margin: auto;
  padding: 0 !important;
  border-radius: 0 !important;
  opacity: 1;
  transition: opacity 300ms ease-in-out;

  .modal-header {
    @include flexbox();
    @include align-items(center);
    @include justify-content(space-between);
    width: 100%;
    padding: 0 $spacing--24;
    height: $nav--height;

    background-color: $white;
    border-bottom: 1px solid var(--color-grey20);

    .modal-header--alert {
      @include flexbox();
      @include align-items(center);
      pointer-events: none;

      .alert-content {
        @include flexbox();
        @include align-items(center);

        &.error {
          span {
            padding-right: 10px;
          }
        }

        &.note {
          span {
            color: $grey--x-dark;
            font-size: $font-xs;

            &.note-wrapper {
              margin-left: 30px;
            }
          }
        }
      }
    }
  }

  .modal-body {
    height: calc(100vh - #{$nav--height});
    background-color: $white;
    overflow-y: auto;

    @include iOSSmoothScrolling();

    .modal-body--content {
      height: 100%;
      min-width: 100%;
      overflow-y: auto;

      .form {
        padding-bottom: $padding--x-large;
      }
    }

    .modal-body--medium {
      margin: auto;
      max-width: $content-width--medium;
      min-width: $content-width--small;
      padding: $padding--x-large 0;
      width: 100%;
      height: 100%;

      & + .modal-footer {
        & > .modal-footer--container {
          max-width: $content-width--medium;
          min-width: $content-width--small;
        }
      }
    }

    .modal-body--full {
      margin: auto;
      max-width: $content-width--full;
      min-width: $content-width--medium;
      padding: $padding--x-large 0;
      width: 100%;
      height: 100%;

      & + .modal-footer {
        & > .modal-footer--container {
          max-width: $content-width--full;
          min-width: $content-width--medium;
        }
      }

      @include media("<#{$content-width--medium}") {
        // if the viewport width is less than the medium size, restrict to 100% instead
        max-width: 100%;
        min-width: 100%;

        & + .modal-footer {
          & > .modal-footer--container {
            max-width: 100%;
            min-width: 100%;
          }
        }

        .content-width--small {
          max-width: 100%;
        }
      }
    }

    &.has-footer {
      height: calc(100vh - #{$nav--height} - #{$modal-footer-height});
      margin: 0;
    }

    // center align content
    > div {
      margin: 0 auto;
    }
  }

  .modal-footer {
    position: absolute;
    bottom: 0;
    padding: $modal-padding--vertical var(--spacing-7);
    width: 100%;
    border-radius: 0;
    background-color: $blue--x-light;
    border-top: $content-block-border;

    &.static-footer {
      position: static;
    }

    .modal-footer--button {
      display: inline-block;
    }
  }

  &.external {
    @include flexbox();

    .modal-header {
      position: fixed;
      z-index: 2;
    }

    .modal-body {
      width: 100%;
      height: 100vh;
    }
  }

  &.representative-info-modal {
    .modal-body {
      background-color: $white;
    }
  }
}

.full-modal--tabs {
  [role=tab] {
    height: 61px !important;
  }
}

@mixin modal-footer--button($side) {
  @include media('<=phone_large') {
    @include justify-content(center);

    .modal-footer--button {
      width: 100%;

      & > .btn-wrapper--action {
        width: 100% !important; // !important needed to override element style
      }

      &:first-child button {
        width: 100%;
        margin: 0;
      }

      // Side order is reversed on mobile
      @if ($side == 'right') {
        margin-bottom: $margin--m;
      } @else if ($side == 'left') {
        &:not(:last-child) {
          margin-bottom: $margin--m;
        }
      }
    }
  }
}

.modal-footer {
  @include flexbox();
  @include flex-flow(row nowrap);
  @include justify-content(center);

  &.ledger-footer {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: $modal-padding--vertical $padding--xm;
    width: 100%;
    border-radius: 0;
    background-color: $blue--x-light;
    border-top: $content-block-border;
    @include borderRadii($border-radius--modal-footer, 'bottom-left', 'bottom-right');

    .modal-footer--container {
      @include justify-content(flex-end);
    }
  }

  &.old-version-footer {
    .modal-footer--container {
      @include justify-content(flex-end);
    }
  }
}

.modal-footer--container {
  @include flexbox();
  @include flex-flow(row nowrap);
  @include justify-content(space-between);
  width: 100%;

  @include media('<=phone_large') {
    @include flex-flow(column nowrap);
    // Reverse the direction to have the primary action button on top
    @include flex-direction(column-reverse);
  }

  .content--left {
    @include flexbox();
    @include flex-flow(row nowrap);
    @include align-items(center);
    @include justify-content(flex-start);
    @include modal-footer--button(left);
  }

  .content--right {
    @include flexbox();
    @include flex-flow(row nowrap);
    @include align-items(center);
    @include justify-content(flex-end);
    @include modal-footer--button(right);
  }
}

@include media('<=tablet') {
  // Prevent parent content from scrolling when a modal is open
  body.ReactModal__Body--open {
    overflow: hidden;
    position: fixed;
  }
}
