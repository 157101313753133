@import './flexbox.scss';
@import '../base/color.scss';
@import '../base/typography.scss';


@mixin avatarStyle($avatar-border-color) {
  @include flexbox();
  @include align-items(center);
  @include justify-content(center);

  border-radius: 50%;
  border: 1px solid $avatar-border-color;

  .missing-avatar {
    color: $white;
    display: table-cell;
    font-size: 11px;
    font-weight: $bold;
    font-family: $base-font-family;
    text-align: center;
    vertical-align: middle;
  }
}

@mixin avatarSize($avatar-diameter) {
  height: $avatar-diameter;
  width: $avatar-diameter;
}
