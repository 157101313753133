@import 'css/base/block.scss';
@import 'css/base/color.scss';
@import 'css/base/spacing.scss';
@import 'css/mixins/flexbox.scss';


$border-radius: $block-border-radius--sm;
$box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);

$no-amount--width: 60px;
$no-amount--border-top: 1px solid $dark-jordan;

$funding-provider-memo--width: 60%;
$funding-provider-memo--border: 1px solid var(--color-grey20);


.funding-provider-memo-bank-statement {
  box-shadow: $box-shadow;
  border-radius: $border-radius;
  padding-bottom: $spacing--24;

  &--wrapper {
    padding: $spacing--24;
    border-bottom: 1px solid var(--color-grey20);
    background-color: $white;
  }

  &--bottom-container {
    background-color: $blue--xx-light;
    padding: $spacing--16 $spacing--24 $spacing--24 $spacing--24;
  }

  &__header-row {
    background-color: $grey--x-light;
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;
    padding: $spacing--8 0;
  }

  .column:nth-child(2) {
    @include flexbox();
    @include justify-content(flex-end);
  }

  .grid {
    padding-left: $spacing--24;
    padding-right: $spacing--24;
  }

  .no-amount {
    border-top: $no-amount--border-top;
    margin-left: $spacing--2;
    width: $no-amount--width;
  }

  .funding-provider-memo--wrapper {
    width: $funding-provider-memo--width;

    .funding-provider-memo--underline {
      border-top: $funding-provider-memo--border;
      transition: border-color 0.2s;
      width: 100%;

      &.is-focused {
        border-color: $blue--bold;
      }
    }
  }
}
