@import "css/base/color.scss";
@import "css/variables/global.scss";
@import "css/base/padding.scss";


.external-block-item {
  padding: $padding--m-large;
  border: $border--grey-x-light;

  &--is-dark {
    background-color: $grey--xx-light;
  }

  &:not(:first-child) {
    // To avoid double border on attached block items
    border-top-color: transparent;
  }
}
