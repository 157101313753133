@import '../../../global/css/base/color.scss';
@import '../../../global/css/base/padding.scss';
@import '../../../global/css/mixins/flexbox.scss';


.check-address-wrapper {
  @include flexbox();
  @include flex-flow(column);
  @include align-items(flex-start);
  @include justify-content(flex-start);
  padding: $padding--large;

  .check-address {
    @include flexbox();
    @include flex-flow(row);
    @include align-items(flex-start);
    @include justify-content(flex-start);
  }
}
