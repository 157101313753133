@import 'css/base/margin.scss';
@import 'css/base/typography.scss';
@import 'css/mixins/boxShadow.scss';
@import 'css/mixins/flexbox.scss';
@import 'css/variables/global.scss';


.static-phone-number {
  @include flexbox();
  @include align-items(center);

  & .static-phone-number--flag {
    border: $border--inset-content;
    box-sizing: content-box;
    background: transparent;
    height: $font-m;
    margin-right: $margin--xm;
  }
}
