@import '../variables/global.scss';


@mixin fancy-marketing-background-image() {
  // Takes in two values for our image and gradient overlay
  background-image:
    url('#{$s3-path}/images/_global/background/hero_background.svg'),
    linear-gradient(213.03deg, #2c84ff 0%, #975bff 100%);
  background-attachment: fixed;
  background-position: -1275px 25px, center;
  background-repeat: repeat-x;
}
