@import '../../../global/css/base/color.scss';
@import '../../../global/css/base/typography.scss';
@import '../../../global/css/mixins/flexbox.scss';
@import '../../../global/css/variables/global.scss';


footer.external-footer {
	position: relative;
	z-index: 2;
	width: 100% !important;
	height: $footer--height;
	border-top: $border--inset-content;
	padding: 18px 6%;
	background-color: $blue--x-light;

	a,
	p {
		color: $main-jordan;
		font-size: $font-regular;
	}

	a {
		font-size: $font-regular;
		font-weight: $regular;
	}

	span {
		position: relative;
		margin-right: 10px;
		color: $main-jordan;
	}

	p {
		color: $main-jordan;
		margin-bottom: 0;
	}

	.secure {
		display: inline-block;
		line-height: 20px;

		span,
		p {
			display: inline-block;
			vertical-align: middle;
		}

		.icon-ic-shield {
			font-size: 20px;
		}
	}

	.legal {
		float: right;
		line-height: 20px;

		a {
			margin-right: 20px;

			&:last-child {
				margin-right: 0;
			}
		}
	}
}

@media (max-width: 580px) {
	body footer.external-footer {
		text-align: center;
		padding: 28px 0 32px;
		height: auto;

		a {
			border-bottom: 1px solid #b7c2e2; // slighty lighter than main-jordan

			&:hover {
				text-decoration: none;
				border-color: $main-jordan;
			}
		}

		.secure {
			margin-bottom: 16px;
		}

		.legal {
			float: none;
		}
	}
}
