@import 'css/variables/global';
@import 'css/base/color';
@import 'css/base/typography';


.success-hint{
  background-color: $green--light !important;
  font-size: $font-m;
  font-weight: $regular;
  line-height: $line-height--24;
}
