@import 'css/base/padding.scss';
@import 'css/mixins/includeMedia.scss';


.company-address-section {
  width: 50%;
  padding-right: $padding--xm;

  @include media('<=tablet') {
    width: 100%;
    padding-right: 0;
  }
}
