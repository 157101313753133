@import 'css/base/color.scss';
@import 'css/base/typography.scss';


.hint {
  // Global overrides for micro-deposits hints
  &--micro-deposits {
    &.info {
      background-color: $yellow--light !important;
    }

    svg {
      fill: $yellow--medium !important;
    }

    span.base-text {
      line-height: $line-height--xl;

      a {
        color: $blue--bold !important;
      }
    }
  }
}
