@import 'css/base/margin';
@import 'css/mixins/flexbox';
@import 'css/variables/sizes';

$payment-method--detail-item-value-flag-height: 24px;

.external-receipt-payment-header {
  &__price {
    @include inline-flex();
    @include align-items(center);

    font-size: 32px !important;
    line-height: 36px !important;

    &__flag {
      height: $payment-method--detail-item-value-flag-height;
      margin-left: $margin--m;
    }
  }
}
