@import 'css/base/color.scss';
@import 'css/base/spacing.scss';
@import 'css/base/typography.scss';
@import 'css/mixins/flexbox.scss';


.panel{

  &.totals-panel {
    @include flexbox();
    @include flex-direction(column);
    @include justify-content(space-between);

    background-color: $panel-background;
    box-shadow: none;
    overflow: hidden;
    margin-left: $spacing--12;
    margin-top: 0 !important;
    padding-top: $spacing--8;

    &:first-child {
      margin-left: 0;
    }

    p {
      color: $grey--x-dark;
      font-size: $font-xs;
      font-weight: $bold;
      margin-bottom: 0;
    }

    .totals-row {
      @include flexbox();
      @include justify-content(space-between);

      height: 30px;
      padding-left: $spacing--16;
      padding-right: $spacing--16;
      width: 100%;

      .left, .right {
        @include flex-col-center();
      }
    }

    .total {
      background-color: $grey--x-dark;
      margin-top: $spacing--8;
      padding-bottom: $spacing--8;
      padding-top: $spacing--8;

      .totals-row {

        p {
          color: $white;
        }
      }
    }

    &.standalone-total {
      padding-top: 0;

      .total {
        margin-top: 0;
      }
    }
  }
}
