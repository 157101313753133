@import "css/base/color.scss";

.login-with-sso-btn {
  .sso-btn--logo {
    height: 18px;
    width: 18px;
    margin-right: 9px;
  }
  .sso-btn--right-icon {
    margin-left: auto;
  }
}
