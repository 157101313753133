@import 'css/base/block.scss';
@import 'css/base/color.scss';
@import 'css/base/spacing.scss';
@import 'css/base/transition.scss';
@import 'css/mixins/flexbox.scss';
@import 'css/variables/sizes.scss';


@mixin input-icon-position($right) {
  position: absolute;
  right: $right;
  // vertical center icon of unknown height
  top: 50%;
  transform: translateY(-50%);
}

.input-icon-wrapper {
  @include flex-col-center();
  @include align-items(center);

  background-color: $white;
  border-radius: $block-border-radius--xm;
  height: 100%;
  // remove pointer events, as icons shouldn't block focusing the input
  pointer-events: none;
  // the text-covering backdrop on the wrapper should be
  // the size of the input icons, plus the breathing room
  // on either side
  width: calc(#{$icon-size--14} + #{$spacing--24});

  &.right {
    @include input-icon-position(0);
  }

  &.tooltip {
    height: fit-content;
    padding: .5rem;
    pointer-events: all;
  }

  svg {
    animation: fadeInIcon $transition-timing--sm $easing-color-cub-bez;
  }
}

.input-icon {
  &.right {
    @include input-icon-position($spacing--12);
  }
}

@keyframes fadeInIcon {
  0% { opacity: 0 }
  100% { opacity: 1 }
}
